import type { UntitledIconData } from '@faceup/icons'

export const ucFormItemSelect: UntitledIconData = {
  prefix: 'custom',
  name: 'ucFormItemSelect',
  viewBox: '0 0 20 20',
  paths: [
    {
      shape: 'M7 9L10 12L13 9',
      stroke: '#8296A2',
      strokeWidth: '1.4',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  rects: [
    {
      x: '0.785',
      y: '0.785',
      width: '18.43',
      height: '18.43',
      rx: '7.215',
      stroke: '#CED6DB',
      strokeWidth: '1.57',
    },
  ],
}
