import { gql, useQuery } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulPlus } from '@faceup/icons/ulPlus'
import { UpsellCard, upsellDisabled, useUpsell } from '@faceup/institution'
import { Button } from '@faceup/ui'
import { Skeleton, notification } from '@faceup/ui-base'
import { Box, Flex, Group } from '@mantine/core'
import { useEffect, useMemo, useState } from 'react'
import LanguageDropdown from '../../../Components/LanguageDropdown'
import { sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import {
  ChannelType,
  type InstitutionCustomizationPagesViewQuery,
  type InstitutionCustomizationPagesViewQueryVariables,
  type Language,
} from '../../../__generated__/globalTypes'
import type { InstitutionCustomizationChildrenViewProps } from '../InstitutionCustomizationView'
import { CreatePageForm } from './InstitutionCustomizationOverviewView/Components/CreatePageForm'
import EditPageForm, {
  EditPageFormFragments,
} from './InstitutionCustomizationOverviewView/Components/EditPageForm'
import {
  PagesList,
  PagesListFragments,
} from './InstitutionCustomizationOverviewView/Components/PagesList'

const messages = defineMessages({
  pages: 'Administration.customization.tab.pages',
  buttonAddNewPage: 'Administration.customization.pages.button.addNewPage',
})

const query = {
  InstitutionCustomizationPagesViewQuery: gql`
    query InstitutionCustomizationPagesViewQuery(
      $language: Language
      $reportSourceId: ReportSourceGlobalId!
    ) {
      reportSource(reportSourceId: $reportSourceId) {
        id
        languages
        defaultLanguage
        sourceType

        pages(language: $language) {
          ... on Page {
            id
          }
          ... on ChannelPage {
            id
          }
        }

        ...PagesList_reportSource
        ...EditPageForm_reportSource
      }
    }

    ${PagesListFragments.PagesList_reportSource}
    ${EditPageFormFragments.EditPageForm_reportSource}
  `,
}

const InstitutionCustomizationPagesView = ({
  formId,
}: InstitutionCustomizationChildrenViewProps) => {
  const [createdNewPageId, setCreatedNewPageId] = useState<string>()
  // undefined - waiting to load data, null - new page
  const [activePageId, setActivePageId] = useState<string | null>()
  const [selectedLanguage, setSelectedLanguage] = useState<Language>()
  const { formatMessage } = useIntl()
  const upsell = useUpsell()

  const { data, loading } = useQuery<
    InstitutionCustomizationPagesViewQuery,
    InstitutionCustomizationPagesViewQueryVariables
  >(query.InstitutionCustomizationPagesViewQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      language: selectedLanguage,
      reportSourceId: formId,
    },
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const pages = useMemo(() => data?.reportSource?.pages ?? [], [data?.reportSource?.pages])

  const isSurvey = data?.reportSource?.sourceType === ChannelType.Survey

  useEffect(() => {
    if (activePageId !== undefined && activePageId !== null) {
      const doesActualPageIdExist = pages?.some(page => page?.id === activePageId)
      if (!doesActualPageIdExist) {
        setActivePageId(undefined)
      }
    }
    if (activePageId === undefined && pages && pages.length > 0) {
      if (isSurvey) {
        setActivePageId(pages[1]?.id)
      } else {
        setActivePageId(pages[0]?.id)
      }
    }
  }, [activePageId, isSurvey, pages])

  /**
   * We need to lazy load the created page after it was created.
   * It's not possible to do ASAP because of refetching the data.
   */
  useEffect(() => {
    if (pages.some(page => page.id === createdNewPageId)) {
      setActivePageId(createdNewPageId)
      setCreatedNewPageId(undefined)
    }
  }, [pages, createdNewPageId])

  const reportSource = data?.reportSource

  useEffect(() => {
    if (!selectedLanguage && reportSource?.defaultLanguage) {
      setSelectedLanguage(reportSource.defaultLanguage)
    }
  }, [reportSource?.defaultLanguage, selectedLanguage])

  if (loading || selectedLanguage === undefined) {
    return <Skeleton />
  }

  if (!reportSource) {
    return null
  }

  const canManipulateWithPages = reportSource.sourceType !== ChannelType.Survey
  const activePage =
    pages.find(page => page.id === activePageId) ?? (activePageId === null ? null : undefined)

  return (
    <UpsellCard
      upsell={canManipulateWithPages ? upsell.pages : upsellDisabled}
      title={<FormattedMessage {...messages.pages} />}
      loading={loading}
      data-onboarding='customization-pages'
      extra={
        canManipulateWithPages &&
        selectedLanguage && (
          <LanguageDropdown
            selectedLanguage={selectedLanguage}
            onClickLanguage={language => setSelectedLanguage(language)}
            allowedLanguages={reportSource.languages ?? undefined}
          />
        )
      }
    >
      <Flex key={selectedLanguage} justify='space-between' gap='var(--ant-padding-xl)'>
        <Box
          sx={{
            maxWidth: 260,
            minWidth: 260,
          }}
        >
          <PagesList
            reportSource={reportSource}
            pages={pages}
            language={selectedLanguage}
            activePage={activePage}
            setActivePageId={setActivePageId}
            isNewPageActive={activePageId === null}
          />
          {canManipulateWithPages && (
            <Group position='right'>
              <Button
                variant='secondary'
                iconBefore={<UntitledIcon icon={ulPlus} />}
                onClick={() => setActivePageId(null)}
              >
                <FormattedMessage {...messages.buttonAddNewPage} />
              </Button>
            </Group>
          )}
        </Box>
        <Box
          sx={{
            flex: '1 0',
            marginTop: '10px',
          }}
        >
          {activePage === null && (
            <CreatePageForm
              reportSourceId={reportSource.id}
              language={selectedLanguage}
              onPageCreated={setCreatedNewPageId}
            />
          )}
          {activePage !== null && activePage !== undefined && (
            <EditPageForm
              reportSource={reportSource}
              language={selectedLanguage}
              page={activePage}
            />
          )}
        </Box>
      </Flex>
    </UpsellCard>
  )
}

export default InstitutionCustomizationPagesView
