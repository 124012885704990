export const breakpoints = {
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1400,
}

export const mq = {
  // show on breakpoint to ♾️
  smUp: `@media (min-width: ${breakpoints.sm}px)`,
  mdUp: `@media (min-width: ${breakpoints.md}px)`,
  lgUp: `@media (min-width: ${breakpoints.lg}px)`,
  xlUp: `@media (min-width: ${breakpoints.xl}px)`,
}
