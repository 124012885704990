import { useEffect, useState } from 'react'
import { useGlobalInfo } from '../locales'

export enum ContactSalesAlertState {
  Open = 'Open',
  ClosedOnce = 'ClosedOnce',
  ClosedPermanently = 'ClosedPermanently',
}

type State = {
  isDashboardReportPasswordAlertOpen?: boolean | null
  contactSalesAlertState: ContactSalesAlertState
  wasRedactionOpened?: boolean | null
  reportsWithOpenTranscript?: string[]
}

export const usePersistentUserStorage = () => {
  const { viewerId } = useGlobalInfo()
  const userStorageKey = `${viewerId}_config`

  const [isDashboardReportPasswordAlertOpen, setIsDashboardReportPasswordAlertOpen] = useState(true)
  const [contactSalesAlertState, setContactSalesAlertState] = useState<ContactSalesAlertState>(
    () => {
      const getStoredState = () => localStorage.getItem(userStorageKey)
      const defaults = JSON.parse(getStoredState() ?? '{}') as State
      return defaults.contactSalesAlertState ?? ContactSalesAlertState.Open
    }
  )
  const [wasRedactionOpened, setWasRedactionOpened] = useState(false)

  const [reportsWithOpenTranscript, setReportsWithOpenTranscript] = useState<string[]>([])

  useEffect(() => {
    const getStoredState = () => localStorage.getItem(userStorageKey)
    const defaults = JSON.parse(getStoredState() ?? '{}') as State

    if (defaults.isDashboardReportPasswordAlertOpen) {
      setIsDashboardReportPasswordAlertOpen(defaults.isDashboardReportPasswordAlertOpen)
    }

    if (defaults.contactSalesAlertState) {
      setContactSalesAlertState(defaults.contactSalesAlertState)
    }
    if (defaults.wasRedactionOpened) {
      setWasRedactionOpened(defaults.wasRedactionOpened)
    }

    if (defaults.reportsWithOpenTranscript) {
      setReportsWithOpenTranscript(defaults.reportsWithOpenTranscript)
    }
  }, [userStorageKey])

  useEffect(() => {
    const getCurrentState = () =>
      JSON.stringify({
        isDashboardReportPasswordAlertOpen,
        contactSalesAlertState,
        wasRedactionOpened,
        reportsWithOpenTranscript,
      })
    // viewerId might be undefined
    if (viewerId) {
      localStorage.setItem(userStorageKey, getCurrentState())
    }
  }, [
    isDashboardReportPasswordAlertOpen,
    userStorageKey,
    viewerId,
    contactSalesAlertState,
    wasRedactionOpened,
    reportsWithOpenTranscript,
  ])

  return {
    isDashboardReportPasswordAlertOpen,
    setIsDashboardReportPasswordAlertOpen,
    contactSalesAlertState,
    setContactSalesAlertState,
    wasRedactionOpened,
    setWasRedactionOpened,
    reportsWithOpenTranscript,
    setReportsWithOpenTranscript,
  }
}
