import { gql } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulAlertCircle } from '@faceup/icons/ulAlertCircle'
import { Modal, Text } from '@faceup/ui'
import { Avatar, Stack } from '@mantine/core'
import { FormattedMessage, defineMessages, useIntl } from '../TypedIntl'
import {
  ContactIssue,
  type SubscriptionFutureModal_institution,
} from '../__generated__/globalTypes'
import useSubscriptionDetail, { SubscriptionDetailFragments } from '../hooks/useSubscriptionDetail'
import { ContactSalesButton } from './ContactSalesButton'

const messages = defineMessages({
  heading: 'Administration.SubscriptionFutureModal.heading',
  description: 'Administration.SubscriptionFutureModal.description',
})

export const SubscriptionFutureModalFragments = {
  SubscriptionFutureModal_institution: gql`
    fragment SubscriptionFutureModal_institution on Company {
      id
      billing {
        id
        subscriptionStart
      }

      ...SubscriptionDetail_company
    }

    ${SubscriptionDetailFragments.SubscriptionDetail_company}
  `,
}

type SubscriptionFutureModalProps = {
  institution: SubscriptionFutureModal_institution
}

export const SubscriptionFutureModal = ({ institution }: SubscriptionFutureModalProps) => {
  const { formatDate } = useIntl()
  const getSubscription = useSubscriptionDetail()
  const subscription = getSubscription(institution)

  return (
    <Modal opened={subscription.state === 'future'}>
      <Stack align='center' spacing='1.5rem'>
        <Avatar color='orange'>
          <UntitledIcon icon={ulAlertCircle} />
        </Avatar>
        <Stack align='center' spacing='0.5rem'>
          <Text weight='bold'>
            <FormattedMessage
              {...messages.heading}
              values={{
                date: formatDate(institution.billing?.subscriptionStart ?? ''),
              }}
            />
          </Text>
          <Text color='subtleText'>
            <FormattedMessage {...messages.description} />
          </Text>
        </Stack>
        <ContactSalesButton contactIssue={ContactIssue.ContactLicenceStartInFuture} />
      </Stack>
    </Modal>
  )
}
