import { gql } from '@apollo/client'
import { ulEdit05 } from '@faceup/icons/ulEdit05'
import type { RouteCallback } from '@faceup/router'
import { ContentLayout } from '@faceup/ui'
import { useDisclosure } from '@mantine/hooks'
import type { ComponentProps } from 'react'
import type { SurveyContentHeader_reportSource } from '../../../__generated__/globalTypes'
import { EditReportSourceNameModal } from '../../InstitutionCustomization/EditReportSourceNameModal'
import { SurveyNavigation, SurveyNavigationFragments } from './SurveyNavigation'

export const SurveyContentHeaderFragments = {
  SurveyContentHeader_reportSource: gql`
    fragment SurveyContentHeader_reportSource on ReportSource {
      id
      name
      ...SurveyNavigationFragment_reportSource
    }
    ${SurveyNavigationFragments.SurveyNavigationFragment_reportSource}
  `,
}

type Props = {
  survey: SurveyContentHeader_reportSource
  surveyNavigationType: ComponentProps<typeof SurveyNavigation>['variant']
  arrowGoBackTo: RouteCallback
}

export const SurveyContentHeader = ({ survey, arrowGoBackTo, surveyNavigationType }: Props) => {
  const [isEditNameModalOpen, { toggle: toggleEditNameModal }] = useDisclosure()

  if (!survey) {
    return null
  }

  return (
    <>
      <ContentLayout.Header
        title={survey.name}
        arrowGoBackTo={arrowGoBackTo}
        action={{
          icon: ulEdit05,
          onAction: toggleEditNameModal,
        }}
        secondaryContent={<SurveyNavigation variant={surveyNavigationType} data={survey} />}
      />

      <EditReportSourceNameModal
        id={survey.id}
        name={survey.name}
        editModalOpen={isEditNameModalOpen}
        setEditModalOpen={toggleEditNameModal}
      />
    </>
  )
}
