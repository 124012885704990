import type { UntitledIconData } from '@faceup/icons'

export const ucFormItemDate: UntitledIconData = {
  prefix: 'custom',
  name: 'ucFormItemDate',
  viewBox: '0 0 20 20',
  paths: [
    {
      shape:
        'M14.5 9H5.5M12 5V7M8 5V7M7.9 15H12.1C12.9401 15 13.3601 15 13.681 14.8365C13.9632 14.6927 14.1927 14.4632 14.3365 14.181C14.5 13.8601 14.5 13.4401 14.5 12.6V8.4C14.5 7.55992 14.5 7.13988 14.3365 6.81901C14.1927 6.53677 13.9632 6.3073 13.681 6.16349C13.3601 6 12.9401 6 12.1 6H7.9C7.05992 6 6.63988 6 6.31901 6.16349C6.03677 6.3073 5.8073 6.53677 5.66349 6.81901C5.5 7.13988 5.5 7.55992 5.5 8.4V12.6C5.5 13.4401 5.5 13.8601 5.66349 14.181C5.8073 14.4632 6.03677 14.6927 6.31901 14.8365C6.63988 15 7.05992 15 7.9 15Z',
      stroke: '#8296A2',
      strokeWidth: '1.4',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  rects: [
    {
      x: '0.785',
      y: '0.785',
      width: '18.43',
      height: '18.43',
      rx: '7.215',
      stroke: '#CED6DB',
      strokeWidth: '1.57',
    },
  ],
}
