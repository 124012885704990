import { TokenType } from '@faceup/utils'
import {
  changePassword,
  createRecoveryKey,
  createSystemRecoveryKey,
  getCurrentEncryptionVersion,
  savePersonalKeys,
} from '..'

export type ProcessMigrationFromSSOPayload = {
  password: string
  publicKey: string
  privateKey: string
  systemPublicKey: string
}

export const processMigrationFromSSO = async ({
  password,
  privateKey,
  publicKey,
  systemPublicKey,
}: ProcessMigrationFromSSOPayload) => {
  const passwordPayload = await changePassword(password ?? '', {
    publicKey,
    privateKey,
  })

  if (typeof passwordPayload === 'string') {
    return passwordPayload
  }

  const newRecoveryPayload = await createRecoveryKey(
    passwordPayload.privateKeyEncrypted,
    passwordPayload.nonce,
    passwordPayload.passwordKey
  )

  if (typeof newRecoveryPayload === 'string') {
    return newRecoveryPayload
  }

  const systemRecoveryPayload = await createSystemRecoveryKey(
    newRecoveryPayload.recoveryKeyPlain,
    systemPublicKey
  )

  if (typeof systemRecoveryPayload === 'string') {
    return systemRecoveryPayload
  }

  const savePayload = await savePersonalKeys({
    publicKey,
    privateKey: passwordPayload.privateKeyEncrypted,
    nonce: passwordPayload.nonce,
    passwordKey: passwordPayload.passwordKey,
    rememberMe: false,
    version: getCurrentEncryptionVersion(),
  })

  if (typeof savePayload === 'string') {
    return savePayload
  }

  const mutationInput = {
    tokenType: TokenType.MigrateFromSSO as const,
    name: null,
    passwordPrehash: passwordPayload.password,
    nonce: passwordPayload.nonce,
    salt: passwordPayload.salt,
    privateKeyEncrypted: passwordPayload.privateKeyEncrypted,
    publicKey,
    recoveryKeyEncrypted: newRecoveryPayload.recoveryKeyEncrypted,
    recoveryKeyEncryptedNonce: newRecoveryPayload.recoveryKeyEncryptedNonce,
    privateKeyRecovery: newRecoveryPayload.privateKeyRecovery,
    privateKeyRecoveryNonce: newRecoveryPayload.privateKeyRecoveryNonce,
    recoveryKeySystemEncrypted: systemRecoveryPayload.systemRecoveryKey,
  }

  return [mutationInput, newRecoveryPayload.recoveryKeyPlain] as const
}
