import { gql, useLazyQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Form } from '@faceup/ui'
import {
  Empty,
  Input,
  SearchOutlined,
  Select,
  Spin,
  notification,
  useMessage,
} from '@faceup/ui-base'
import { RegistrationReason } from '@faceup/utils'
import { useEffect, useState } from 'react'
import CountrySelect from '../../../../Components/CountrySelect'
import { registrationReasonMessages, sharedMessages } from '../../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../../TypedIntl'
import type {
  Country,
  SearchPublicSchoolsQuery,
  SearchPublicSchoolsQueryVariables,
} from '../../../../__generated__/globalTypes'
import type { FormValue } from '../../../../types'
import { StepHeading } from './StepHeading'

const messages = defineMessages({
  title: 'Administration.registration.fillInfo.step3.title',
  description: 'Administration.registration.fillInfo.step3.description',
  institutionName: 'Administration.registration.fillInfo.institutionName',
  selectedRegisteredSchoolError:
    'Administration.registration.fillInfo.selectedRegisteredSchoolError',
  schoolNotFound: 'Shared.global.schoolNotFound',
  address: 'Administration.label.address',
  registrationReason: 'Administration.registration.reason',
  country: 'Administration.services.country',
  countryHint: 'Administration.registration.fillInfo.country.hint',
})

const query = {
  SearchPublicSchools: gql`
    query SearchPublicSchoolsQuery($name: GraphQLString) {
      searchPublicSchools(name: $name, showTestingSchool: false) {
        country
        regionId
        institutionId
        address
        name
        isRegistered
        isTesting
        hasActivePassword
        defaultFormId
      }
    }
  `,
}

type Step3Props = {
  isNntbSchool: boolean
  institutionName: FormValue
  setInstitutionName: (value: FormValue) => void
  institutionId: string
  setInstitutionId: (value: string) => void
  address: FormValue
  setAddress: (value: FormValue) => void
  country: FormValue<Country | null>
  setCountry: (value: FormValue<Country | null>) => void
  registrationReason: FormValue<RegistrationReason | null>
  setRegistrationReason: (value: FormValue<RegistrationReason | null>) => void
}

export const Step3 = (props: Step3Props) => {
  const {
    isNntbSchool,
    institutionName,
    setInstitutionName,
    setInstitutionId,
    address,
    setAddress,
    country,
    setCountry,
    registrationReason,
    setRegistrationReason,
  } = props
  const message = useMessage()
  const { formatMessage } = useIntl()
  const [nameInput, setNameInput] = useState({ value: '', error: false })

  const [searchSchools, { loading: schoolsLoading, data, refetch, called }] = useLazyQuery<
    SearchPublicSchoolsQuery,
    SearchPublicSchoolsQueryVariables
  >(query.SearchPublicSchools, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    variables: {
      name: nameInput.value,
    },
  })

  useEffect(() => {
    if (called) {
      refetch()
    } else if (nameInput.value.trim() !== '') {
      searchSchools()
    }
  }, [nameInput.value, called, refetch, searchSchools])

  const filteredSchools = data?.searchPublicSchools ?? []

  return (
    <>
      <StepHeading
        title={<FormattedMessage {...messages.title} />}
        description={<FormattedMessage {...messages.description} />}
      />
      <Form.Item
        label={<FormattedMessage {...messages.institutionName} />}
        withAsterisk
        errorMessage={
          institutionName.error && <FormattedMessage {...sharedMessages.invalidInputError} />
        }
      >
        {isNntbSchool ? (
          <Select<string>
            value={institutionName.value}
            showSearch
            style={{ display: 'flex' }}
            filterOption={false}
            suffixIcon={null}
            allowClear
            showAction={['focus']}
            onSearch={value => {
              setInstitutionName({ value, error: false })
              setNameInput({ value, error: false })
            }}
            onClear={() => {
              setInstitutionName({ value: '', error: false })
              setNameInput({ value: '', error: false })
              setAddress({ value: '', error: false })
              setCountry({ value: null, error: false })
            }}
            onSelect={(id: string) => {
              const school = filteredSchools.find(school => school?.institutionId === id)

              if (!school) {
                return
              }

              if (school?.isRegistered) {
                message.error(formatMessage(messages.selectedRegisteredSchoolError))

                return
              }

              setInstitutionId(school?.institutionId ?? '')
              setInstitutionName({ value: school?.name ?? '', error: false })
              setAddress({ value: school?.address ?? '', error: false })
              setCountry({ value: school?.country ?? null, error: false })
            }}
            placeholder={<SearchOutlined />}
            notFoundContent={
              schoolsLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Spin size='small' />
                </div>
              ) : institutionName.value === '' ? null : (
                <Empty
                  imageStyle={{ height: 60 }}
                  description={<FormattedMessage {...messages.schoolNotFound} />}
                />
              )
            }
          >
            {filteredSchools.map(school => (
              <Select.Option key={school?.institutionId ?? ''} value={school?.institutionId ?? ''}>
                {school?.name ?? ''}
                <AddressText>{school?.address ?? ''}</AddressText>
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Input
            autoComplete='organization'
            value={institutionName.value}
            onChange={({ target: { value } }) => setInstitutionName({ value, error: false })}
            data-cy='FillInfoContent-institutionName'
          />
        )}
      </Form.Item>

      {isNntbSchool ? (
        <Form.Item label={<FormattedMessage {...messages.address} />}>
          <Input disabled value={address.value} />
        </Form.Item>
      ) : (
        <Form.Item
          label={<FormattedMessage {...messages.country} />}
          hint={<FormattedMessage {...messages.countryHint} />}
          withAsterisk
          errorMessage={
            country.error && <FormattedMessage {...sharedMessages.invalidAddressError} />
          }
        >
          <CountrySelect
            value={country.value}
            onChange={(value: Country) => setCountry({ value, error: false })}
            data-cy='FillInfoContent-country'
            style={{ width: '100%' }}
          />
        </Form.Item>
      )}
      <Form.Item label={<FormattedMessage {...messages.registrationReason} />}>
        <Select<RegistrationReason>
          data-cy='FillInfoContent-registrationReason'
          value={registrationReason.value}
          allowClear
          onChange={value => setRegistrationReason({ value, error: false })}
          options={Object.values(RegistrationReason).map(reason => ({
            value: reason,
            label: <FormattedMessage {...registrationReasonMessages[reason]} />,
          }))}
        />
      </Form.Item>
    </>
  )
}

const AddressText = styled.div`
  font-size: 12px;
  color: rgba(80, 80, 80, 0.8);
`
