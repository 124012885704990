import { TokenType } from '@faceup/utils'
import { type ProcessCreatePasswordPayload, processCreatePassword } from './processCreatePassword'
import {
  type ProcessForgottenPasswordPayload,
  processForgottenPassword,
} from './processForgottenPassword'
import {
  type ProcessMigrationFromSSOPayload,
  processMigrationFromSSO,
} from './processMigrationFromSSO'
import { type ProcessMigrationToSSOPayload, processMigrationToSSO } from './processMigrationToSSO'

type ProcessCredentialsChangePayload =
  | ({
      token: TokenType.CreatePassword
    } & ProcessCreatePasswordPayload)
  | ({ token: TokenType.ForgottenPassword } & ProcessForgottenPasswordPayload)
  | ({ token: TokenType.MigrateFromSSO } & ProcessMigrationFromSSOPayload)
  | ({ token: TokenType.MigrateToSSO } & ProcessMigrationToSSOPayload)

export const processCredentialsChange = (payload: ProcessCredentialsChangePayload) => {
  switch (payload.token) {
    case TokenType.CreatePassword:
      return processCreatePassword(payload)
    case TokenType.ForgottenPassword:
      return processForgottenPassword(payload)
    case TokenType.MigrateFromSSO:
      return processMigrationFromSSO(payload)
    case TokenType.MigrateToSSO:
      return processMigrationToSSO(payload)
  }
}

export { processCreatePassword } from './processCreatePassword'
export { processMigrationFromE2EE } from './processMigrationFromE2EE'
export { processVersionMigration } from './processVersionMigration'
