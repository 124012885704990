import { ulPieChart01 } from '@faceup/icons/ulPieChart01'
import { NoDataOverlay as UiNoDataOverlay } from '@faceup/ui'
import type { ComponentProps } from 'react'
import { FormattedMessage, defineMessages } from '../TypedIntl'

const messages = defineMessages({
  description: 'Administration.noDataOverlay.description',
})

type NoDataOverlayProps = Pick<ComponentProps<typeof UiNoDataOverlay>, 'visible'>
const NoDataOverlay = (props: NoDataOverlayProps) => (
  <UiNoDataOverlay
    {...props}
    icon={ulPieChart01}
    label={<FormattedMessage {...messages.description} />}
  />
)

export default NoDataOverlay
