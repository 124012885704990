import { downloadFile } from '@faceup/utils'
import { pdf } from '@react-pdf/renderer'
import type { ReactElement } from 'react'

const PDFDownloader = async (fileName: string, content: ReactElement) => {
  const blob = await pdf(content).toBlob()

  downloadFile(blob, `${fileName}.pdf`)
}

export default PDFDownloader
