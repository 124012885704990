import { gql, useQuery } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulCopy03 } from '@faceup/icons/ulCopy03'
import { useMotherId } from '@faceup/institution'
import { useNavigate } from '@faceup/router'
import { Button, Table, createColumns, useReactTable } from '@faceup/ui'
import { Flex, Tag, notification, useMessage } from '@faceup/ui-base'
import { ChannelStatus } from '@faceup/utils'
import moment from 'moment-timezone'
import { useMemo, useState } from 'react'
import MoreOptions from '../../../Components/MoreOptions'
import { channelStatusMessages, sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import type {
  SurveyOptionsMenu_reportSource,
  SurveysTableQuery,
  SurveysTableQueryVariables,
} from '../../../__generated__/globalTypes'
import { ucClipboard } from '../../../customIcons/ucClipboard'
import useConfigForProject from '../../../hooks/useConfigForProject'
import { CreateSurveyModal } from './CreateSurveyModal'
import { SurveyOptionsMenu, SurveyOptionsMenuFragments } from './SurveyOptionsMenu'

type Survey = NonNullable<NonNullable<SurveysTableQuery['surveys']>['items']>[number]

const messages = defineMessages({
  nameLabel: 'Administration.surveys.table.name',
  numberOfAnswers: 'Administration.surveys.table.numberOfAnswers',
  shortLink: 'Administration.surveys.table.shortLink',
  createdAt: 'Administration.surveys.table.createdAt',
  status: 'Administration.surveys.table.status',
  viewDetails: 'Administration.surveys.options.viewDetails',
  duplicate: 'Administration.surveys.options.duplicate',
  deactivate: 'Administration.surveys.options.deactivate',
  activate: 'Administration.surveys.options.activate',
  delete: 'Administration.surveys.options.delete',
  confirmDelete: 'Administration.surveys.confirmDelete',
  downloadData: 'Administration.surveys.detail.downloadData',
  changeLanguage: 'Administration.surveys.detail.changeLanguage',
  emptyLabel: 'Administration.surveys.table.emptyLabel',
  emptyDescription: 'Administration.surveys.table.emptyDescription',
  createSurvey: 'Administration.surveys.createSurvey',
})

export const surveysTableQuery = gql`
  query SurveysTableQuery(
    $motherId: CompanyGlobalId!
    $name: GraphQLString
    $page: Int!
    $rowsPerPage: Int!
  ) {
    surveys(motherId: $motherId, name: $name, page: $page, rowsPerPage: $rowsPerPage) {
      totalCount
      items {
        id
        name
        shortLink
        isEnabled
        status
        createdByManager {
          id
          name
        }
        createdAt
        ...SurveyOptionsMenu_reportSource
        config {
          ... on SurveyChannelConfiguration {
            id
            submissionsCount
          }
        }
      }
    }
  }
  ${SurveyOptionsMenuFragments.SurveyOptionsMenu_reportSource}
`

export const SurveyTable = ({ name }: { name: string }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { getMotherId } = useMotherId()
  const { generateSurveyUrl } = useConfigForProject()
  const message = useMessage()

  const { data, loading } = useQuery<SurveysTableQuery, SurveysTableQueryVariables>(
    surveysTableQuery,
    {
      variables: { motherId: getMotherId(), name, page: 0, rowsPerPage: 1000 },
      fetchPolicy: 'cache-and-network',
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
    }
  )

  const { formatMessage } = useIntl()

  const columns = createColumns<Survey>({
    data: [
      {
        id: 'name',
        header: formatMessage(messages.nameLabel),
        size: 100,
        cell: ({ cell }) => cell.row.original?.name,
      },
      {
        id: 'numberOfAnswers',
        header: formatMessage(messages.numberOfAnswers),
        size: 50,
        cell: ({ cell }) => {
          const config = cell.row.original?.config
          return config?.__typename === 'SurveyChannelConfiguration' ? config?.submissionsCount : ''
        },
      },
      {
        id: 'shortLink',
        header: formatMessage(messages.shortLink),
        size: 120,
        cell: ({ cell }) => {
          const shortLink = cell.row.original?.shortLink ?? ''
          const url = generateSurveyUrl(shortLink)

          return (
            <Flex gap='small' align='center'>
              {url}
              <Button
                variant='text'
                color='dark'
                onClick={async event => {
                  event.stopPropagation()
                  await navigator.clipboard.writeText(url)
                  message.success(formatMessage(sharedMessages.copied))
                }}
              >
                <UntitledIcon icon={ulCopy03} />
              </Button>
            </Flex>
          )
        },
      },
      {
        id: 'createdAt',
        header: formatMessage(messages.createdAt),
        size: 100,
        cell: ({ cell }) =>
          `${moment(cell.row.original?.createdAt).format('D. M. YYYY')}, ${
            cell.row.original?.createdByManager?.name
          }`,
      },
      {
        id: 'status',
        header: formatMessage(messages.status),
        size: 20,
        cell: ({ cell }) => {
          const status = cell.row.original?.status
          const statusColors: Record<ChannelStatus, 'red' | 'green'> = {
            [ChannelStatus.Enabled]: 'green',
            [ChannelStatus.Disabled]: 'red',
          }
          return status ? (
            <Tag color={statusColors[status]}>
              <FormattedMessage {...channelStatusMessages[status]} />
            </Tag>
          ) : (
            ''
          )
        },
      },
    ],
    actions: [
      {
        id: 'options',
        size: 16,
        cell: props => props.cell.row.original && <CellOptions survey={props.cell.row.original} />,
      },
    ],
  })

  const surveys = useMemo(() => data?.surveys?.items ?? [], [data?.surveys?.items])
  const table = useReactTable({ columns, data: surveys })

  const navigate = useNavigate()

  return (
    <>
      <Table
        table={table}
        loading={loading}
        onRowClick={survey => survey && navigate(routes => routes.surveyDetail({ id: survey.id }))}
        noData={{
          icon: ucClipboard,
          label: <FormattedMessage {...messages.emptyLabel} />,
          description: <FormattedMessage {...messages.emptyDescription} />,
          button: {
            label: <FormattedMessage {...messages.createSurvey} />,
            onClick: () => setIsModalOpen(true),
          },
        }}
      />
      <CreateSurveyModal isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  )
}

const CellOptions = ({ survey }: { survey: SurveyOptionsMenu_reportSource }) => (
  <MoreOptions
    content={
      <SurveyOptionsMenu
        survey={survey}
        optionsToDisplay={['viewDetails', 'duplicate', 'deactivate', 'delete']}
      />
    }
  />
)
