import { gql, useMutation } from '@apollo/client'
import { ModalForm, TextInput, useForm, yup } from '@faceup/form'
import { useMotherId } from '@faceup/institution'
import { useNavigate } from '@faceup/router'
import { notification } from '@faceup/ui-base'
import { sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import type { CreateSurvey, CreateSurveyVariables } from '../../../__generated__/globalTypes'

const messages = defineMessages({
  modalTitle: 'Administration.surveys.modal.title',
  nameLabel: 'Administration.surveys.modal.nameLabel',
})

const mutation = gql`
  mutation CreateSurvey($input: CreateSurveyInput!) {
    createSurvey(input: $input) {
      survey {
        id
      }
    }
  }
`

type CreateSurveyModalProps = {
  isModalOpen: boolean
  onClose: () => void
}

export const CreateSurveyModal = ({ isModalOpen, onClose }: CreateSurveyModalProps) => {
  const navigate = useNavigate()
  const schema = yup.object({
    name: yup.string().required(),
  })

  const form = useForm({ schema, afterSubmit: 'resetValues' })

  const { formatMessage } = useIntl()

  const { getMotherId } = useMotherId()

  const [createSurvey] = useMutation<CreateSurvey, CreateSurveyVariables>(mutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: data => {
      onClose()
      navigate(routes => routes.surveyDetail({ id: data.createSurvey?.survey?.id ?? '' }))
    },
    refetchQueries: ['SurveysTableQuery'],
  })

  return (
    <ModalForm
      form={form}
      onClose={onClose}
      onSubmit={async values => {
        const result = await createSurvey({
          variables: { input: { ...values, motherId: getMotherId() } },
        })

        if (result.errors) {
          return false
        }
        return true
      }}
      opened={isModalOpen}
      submitButtonText='add'
      title={<FormattedMessage {...messages.modalTitle} />}
    >
      <TextInput
        name='name'
        control={form.control}
        label={<FormattedMessage {...messages.nameLabel} />}
        data-cy='survey-name-input'
      />
    </ModalForm>
  )
}
