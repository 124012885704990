import type { UntitledIconData } from '../../UntitledIconData'

const ulSlashCircle01: UntitledIconData = {
  prefix: 'ul',
  name: 'slash-circle-01',
  paths: [
    {
      shape:
        'M4.93 4.93L19.07 19.07M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z',
    },
  ],
}

export default ulSlashCircle01
