import {
  type ColumnDefTemplate,
  type GroupColumnDef,
  type CellContext as TRTCellContext,
  createColumnHelper as createTRTColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable as useTRTReactTable,
} from '@tanstack/react-table'
import type { RowData, TableOptions } from '@tanstack/table-core'
import { useMemo } from 'react'
type Column<TData extends RowData> = {
  id: string
  size: number
  header: string
  // biome-ignore lint/suspicious/noExplicitAny:
  cell: ColumnDefTemplate<CellContext<TData, any>>
}
export const createColumns = <TData extends RowData>(columns: {
  data: Column<TData>[]
  actions: Omit<Column<TData>, 'header'>[]
}) => {
  const columnHelper = createColumnHelper<TData>()
  const dataColumns = columns.data.map(column =>
    columnHelper.display({
      id: column.id,
      size: column.size + 16 * 2,
      header: column.header,
      cell: column.cell,
    })
  )
  const actionColumns = columns.actions.map(column =>
    columnHelper.display({
      id: column.id,
      size: column.size + 16,
      cell: column.cell,
    })
  )
  return [
    columnHelper.group({ id: 'data', columns: dataColumns }),
    columnHelper.group({ id: 'actions', columns: actionColumns }),
    // biome-ignore lint/suspicious/noExplicitAny:
  ] as [GroupColumnDef<TData, any>, GroupColumnDef<TData, any>]
}

export const useReactTable = <TData extends RowData>(
  // biome-ignore lint/suspicious/noExplicitAny:
  options: { columns: [GroupColumnDef<TData, any>, GroupColumnDef<TData, any>] } & Pick<
    TableOptions<TData>,
    'data'
  >
) => {
  const memoizedOptions: TableOptions<TData> = useMemo(
    () => ({
      columns: options.columns,
      data: options.data,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      enableColumnPinning: true,
      initialState: {
        columnPinning: { right: options.columns[1].columns?.map(column => column.id) },
      },
    }),
    [options]
  ) as TableOptions<TData>
  return useTRTReactTable(memoizedOptions)
}

export const createColumnHelper = createTRTColumnHelper
export type CellContext<TData extends RowData, TValue = unknown> = TRTCellContext<TData, TValue>
