import { Box, Flex, LoadingOverlay, Pagination, Stack } from '@mantine/core'
import { type Table as TypeTable, flexRender } from '@tanstack/react-table'
import type { RowData } from '@tanstack/table-core'
import { type ComponentProps, useContext } from 'react'
import { Paper, Text } from '../CoreComponents'
import { NoDataOverlay } from '../NoDataOverlay'
import { UiContext } from '../UiProvider/UiProvider'
import { transitionDuration } from '../constants'
import { type Color, useThemeColors } from '../hooks'

type TableProps<TData extends RowData> = {
  table: TypeTable<TData>
  noData?: Omit<ComponentProps<typeof NoDataOverlay>, 'visible'>
  rowStripColor?: (row: TData) => Color | null
  loading?: boolean
  onRowClick?: (row: TData) => void
  'data-cy'?: string
  'data-onboarding'?: string
}

export const Table = <TData extends RowData>(props: TableProps<TData>) => {
  const {
    table,
    rowStripColor,
    onRowClick,
    loading = false,
    'data-cy': dataCy,
    'data-onboarding': dataOnboarding,
    noData,
  } = props
  const { getColorFromTheme } = useThemeColors()
  const { table: translations } = useContext(UiContext)

  const groups = {
    data: table.getHeaderGroups()[0]?.headers[0]?.subHeaders.map(({ id }) => id) ?? [],
    actions: table.getHeaderGroups()[0]?.headers[1]?.subHeaders.map(({ id }) => id) ?? [],
  }

  const headerGroup = table.getHeaderGroups()[1]
  const isNoDataVisible = table.getPrePaginationRowModel().rows.length === 0

  return (
    <Stack data-cy={dataCy} data-onboarding={dataOnboarding} style={{ width: '100%' }}>
      <Paper
        outline='dark'
        sx={{
          position: 'relative',
        }}
      >
        <Box
          component='table'
          sx={{
            minWidth: '100%',
            maxWidth: 'fit-content',
            maxHeight: '100%',
            overflowY: 'auto',
            overflowX: 'auto',
            tableLayout: 'fixed',
            borderCollapse: 'collapse',
            display: 'block',
            margin: '0 auto',
            whiteSpace: 'nowrap',
            position: 'relative',
            thead: {
              backgroundColor: getColorFromTheme('dark.10'),
              minWidth: '100%',
              maxWidth: 'fit-content',
            },
            'tbody>tr': {
              borderBottom: `1px solid ${getColorFromTheme('dark.20')}`,
              '& td': {
                transition: `background-color ${transitionDuration}`,
                backgroundColor: getColorFromTheme('white'),
                // FF bugfix https://bugzilla.mozilla.org/show_bug.cgi?id=688556#c25
                backgroundClip: 'padding-box',
                '&:first-of-type': {
                  position: 'relative',
                },
                '&:first-of-type::before': {
                  content: '""',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  borderRadius: '0 2px 2px 0',
                },
              },
              '&:hover td': {
                backgroundColor: getColorFromTheme('dark.4'),
              },
              '&:last-of-type': {
                borderBottom: 'none',
              },
            },
            'td, th': {
              padding: '16px',
              '&.column-action': {
                paddingLeft: '0',
              },
            },
            th: {
              textAlign: 'left',
              '&::before': {
                content: '""',
                border: `1px solid ${getColorFromTheme('dark.20')}`,
                borderRadius: '24px',
                height: '100%',
                position: 'relative',
                right: '8px',
              },
              '&:first-of-type::before': {
                border: 'none',
              },
            },
          }}
        >
          {headerGroup && (
            <thead>
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const isDataColumn = groups.data.includes(header.id)
                  const isActionColumn = groups.actions.includes(header.id)
                  const right = table
                    .getRightLeafHeaders()
                    .slice(header.column.getPinnedIndex() + 1)
                    .reduce((acc, col) => acc + col.getSize(), 0)
                  return (
                    <Box
                      key={header.id}
                      component='th'
                      colSpan={header.colSpan}
                      className={`${isActionColumn && 'column-action'}`}
                      sx={{
                        minWidth: isDataColumn ? `${header.getSize()}px` : undefined,
                        width: isDataColumn
                          ? `${100 / groups.data.length}%`
                          : `${header.getSize()}px`,
                        maxWidth: isDataColumn ? `${header.getSize() * 2}px` : undefined,
                        position: isActionColumn ? 'sticky' : undefined,
                        right: isActionColumn ? right : undefined,
                        color: getColorFromTheme('dark.100'),
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </Box>
                  )
                })}
              </tr>
            </thead>
          )}
          <tbody>
            {table.getRowModel().rows.map(row => {
              const stripColor = rowStripColor?.(row.original) ?? null
              return (
                <Box
                  component='tr'
                  key={row.id}
                  sx={{
                    fontWeight: stripColor ? 600 : undefined,
                    '& td:first-of-type::before': {
                      borderLeft: stripColor
                        ? `4px solid ${getColorFromTheme(stripColor)}`
                        : undefined,
                    },
                  }}
                >
                  {row.getVisibleCells().map(cell => {
                    const isActionColumn = groups.actions.includes(cell.column.id)
                    const isDataColumn = groups.data.includes(cell.column.id)
                    const right = table
                      .getRightLeafHeaders()
                      .slice(cell.column.getPinnedIndex() + 1)
                      .reduce((acc, col) => acc + col.getSize(), 0)
                    return (
                      <Box
                        key={cell.id}
                        component='td'
                        className={`${isActionColumn && 'column-action'}`}
                        onClick={() => {
                          if (onRowClick && isDataColumn) {
                            onRowClick(cell.row.original)
                          }
                        }}
                        sx={{
                          position: isActionColumn ? 'sticky' : undefined,
                          right: isActionColumn ? right : undefined,
                          cursor: onRowClick && isDataColumn ? 'pointer' : undefined,
                          minWidth: isDataColumn ? `${cell.column.getSize()}px` : undefined,
                          width: isDataColumn
                            ? `${100 / groups.data.length}%`
                            : `${cell.column.getSize()}px`,
                          maxWidth: isDataColumn ? `${cell.column.getSize() * 2}px` : undefined,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Box>
                    )
                  })}
                </Box>
              )
            })}
          </tbody>
          {/*
          TODO: Uncomment at some point?
          <tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.footer,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
          </tfoot>
          */}
        </Box>
        <LoadingOverlay visible={loading} zIndex={101} />
        {noData && isNoDataVisible && (
          <Box
            sx={{
              position: 'relative',
              minHeight: noData.button ? '250px' : '150px',
              height: '100%',
            }}
          >
            <NoDataOverlay visible {...noData} />
          </Box>
        )}
      </Paper>
      {table.getPageCount() > 1 && (
        <Flex justify='space-between'>
          <Text color='dark.60' variant='title' size='small'>
            {translations?.shown ?? 'Shown'} {table.getPaginationRowModel().rows.length}/
            {table.getPrePaginationRowModel().rows.length}
          </Text>
          <Pagination
            total={table.getPageCount()}
            value={table.getState().pagination.pageIndex + 1}
            onChange={value => {
              const page = value - 1
              table.setPageIndex(page)
            }}
          />
        </Flex>
      )}
    </Stack>
  )
}
