import type { UntitledIconData } from '@faceup/icons'

export const ucReportingChannels: UntitledIconData = {
  prefix: 'custom',
  name: 'ucReportingChannels',
  viewBox: '0 0 19 19',
  paths: [
    {
      shape: 'M1.5 0.999999L8.5 7L18.5 7M18.5 7L13.5 11.3333M18.5 7L13.5 2.33333',
      stroke: 'currentColor',
      strokeWidth: '1.67',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
    {
      shape: 'M1.5 13L5.5 10',
      stroke: 'currentColor',
      strokeWidth: '1.67',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
}
