import type { UntitledIconData } from '../../UntitledIconData'

const ulDatabase02: UntitledIconData = {
  prefix: 'ul',
  name: 'database-02',
  paths: [
    {
      shape:
        'M21 5C21 6.65685 16.9706 8 12 8C7.02944 8 3 6.65685 3 5M21 5C21 3.34315 16.9706 2 12 2C7.02944 2 3 3.34315 3 5M21 5V19C21 20.66 17 22 12 22C7 22 3 20.66 3 19V5M21 9.72021C21 11.3802 17 12.7202 12 12.7202C7 12.7202 3 11.3802 3 9.72021M21 14.44C21 16.1 17 17.44 12 17.44C7 17.44 3 16.1 3 14.44',
    },
  ],
}

export default ulDatabase02
