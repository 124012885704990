import JSZip from 'jszip'
import writeXlsxFile, { type CommonOptions, type Schema } from 'write-excel-file'

export const arrayToCsv = (data: string[][]) =>
  data
    .map(row =>
      row
        .map(v => (v === null ? '' : String(v)))
        .map(v => v.replaceAll('"', '""'))
        .map(v => `"${v}"`)
        .join(',')
    )
    .join('\r\n')

export type ZipFileName = `${string}.zip`
export const zipFiles = async (files: File[], fileName: ZipFileName) => {
  const zip = new JSZip()
  files.forEach(file => zip.file(file.name, file))
  return new File([await zip.generateAsync({ type: 'blob' })], fileName)
}

export type ExcelSchema<E> = Schema<E>
export type XlsxFileName = `${string}.xlsx`
export const xlxsFiles = async <E>(
  objects: E[] | E[][],
  options: CommonOptions & {
    schema: Schema<E> | Schema<E>[]
    fileName: string
    sheet?: string
    sheets?: string[]
  },
  fileName: XlsxFileName
) => {
  // @ts-expect-error Fix typings
  const xlxsBlob = (await writeXlsxFile<E>(objects, options)) as unknown as Blob

  return new File([xlxsBlob], fileName)
}
