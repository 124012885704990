import type {
  ChannelType,
  Institution,
  ReportCreationOrigin,
  ReportJustification,
  ReportSourceType,
} from '@faceup/utils'
import mixpanel from 'mixpanel-browser'
import { useCallback } from 'react'
import type { ReportPriority, ReportStatusType } from '../__generated__/globalTypes'
import { useMotherId } from '../hooks/useMotherId'
import { useGlobalInfo } from '../locales'

/*
  Suffixes for event tracking:
  - Created
  - Changed
  - Opened
*/

// https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md
const ALLOW_TRACKING = import.meta.env.VITE_ENVIRONMENT === 'production'

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN ?? '', {
  debug: !ALLOW_TRACKING,
  persistence: 'localStorage',
  api_host: 'https://api-eu.mixpanel.com',
})

// this function is the only function, which we need to be outside of the hook, because it is used in the `CustomApolloProvider.tsx`
export const reset = () => mixpanel.reset()

const useAnalytics = () => {
  const {
    viewerId,
    legacyId,
    institution,
    realInstitution,
    motherInstitutionName,
    employees,
    subscriptionStatus,
    plan,
  } = useGlobalInfo()
  const { getMotherIdWithNull } = useMotherId()
  const motherIdWithNull = getMotherIdWithNull()

  const trackPageView = () => {
    if (realInstitution === null || realInstitution === true) {
      mixpanel.track_pageview()
    }
  }

  const identify = (name: string, email: string) => {
    if (!realInstitution) {
      return
    }
    if (viewerId) {
      // preserve data continuity for old ids
      mixpanel.identify(legacyId ?? viewerId)
      mixpanel.people.set({
        $name: name,
        $email: email,
      })
    }
    if (motherIdWithNull && motherInstitutionName) {
      mixpanel.add_group('organization', motherIdWithNull)
      mixpanel.get_group('organization', motherIdWithNull).set({
        $name: motherInstitutionName,
        plan: plan?.toLowerCase(),
        type: institution?.toLowerCase(),
        status: subscriptionStatus?.toLowerCase(),
        size: employees,
      })
    }
  }

  // settings
  const trackMemberCreated = () => mixpanel.track('Member created')
  const trackMemberInvitationCreated = () => mixpanel.track('Member invitation created')
  const trackOrganizationUnitCreated = (level: number) =>
    mixpanel.track('Organization unit created', { level })
  const trackOrganizationFromPartnerCreated = (type: Institution) =>
    mixpanel.track('Organization from partner created', { type: type.toLowerCase() })

  // sign up/login tracking
  const trackLogin = () => mixpanel.track('Login')
  const trackSignUpProgress = (step: number, type: 'purchase' | 'trial') =>
    mixpanel.track('Sign Up progress', { step, type: type.toLowerCase() })
  const trackSignUp = (institution: Institution | 'Partner', type: 'purchase' | 'trial') =>
    mixpanel.track('Sign Up', {
      organization: institution.toLowerCase(),
      type: type.toLowerCase(),
    })

  // report tracking
  const trackMessageOpened = () => mixpanel.track('Message opened')
  const trackMessageCreated = () => mixpanel.track('Message created')
  const trackInternalCommentCreated = () => mixpanel.track('Internal comment created')
  const trackReportOpened = (source: ReportSourceType, created: ReportCreationOrigin) =>
    mixpanel.track('Report opened', {
      source: source.toLowerCase(),
      created: created.toLowerCase(),
    })
  const trackReportCreated = () => mixpanel.track('Report created')
  const trackReportAssignedMemberChanged = () => mixpanel.track('Report assigned member changed')
  const trackReportPriorityChanged = (priority: ReportPriority) =>
    mixpanel.track('Report priority changed', { priority: priority.toLowerCase() })
  const trackReportLabelChanged = () => mixpanel.track('Report label changed')
  const trackReportJustificationChanged = (justification: ReportJustification) =>
    mixpanel.track('Report justification changed', { justification: justification.toLowerCase() })
  const trackReportDeadlineChanged = () => mixpanel.track('Report deadline changed')
  const trackReportStatusChanged = (status: ReportStatusType) =>
    mixpanel.track('Report status changed', { status: status.toLowerCase() })
  const trackReportRedaction = (status: 'started' | 'completed') =>
    mixpanel.track('Report redaction', { status })

  // channel tracking
  const trackChannelCreated = (type: ChannelType) =>
    mixpanel.track('Channel created', { type: type.toLowerCase() })

  // chargebee
  const trackPurchaseCompleted = (plan: string) => mixpanel.track('Purchase completed', { plan })

  const analytics = {
    // biome-ignore lint/correctness/useExhaustiveDependencies(realInstitution):
    trackPageView: useCallback(trackPageView, [realInstitution]),
    // biome-ignore lint/correctness/useExhaustiveDependencies(employees):
    // biome-ignore lint/correctness/useExhaustiveDependencies(institution):
    // biome-ignore lint/correctness/useExhaustiveDependencies(motherIdWithNull):
    // biome-ignore lint/correctness/useExhaustiveDependencies(motherInstitutionName):
    // biome-ignore lint/correctness/useExhaustiveDependencies(plan):
    // biome-ignore lint/correctness/useExhaustiveDependencies(realInstitution):
    // biome-ignore lint/correctness/useExhaustiveDependencies(subscriptionStatus):
    // biome-ignore lint/correctness/useExhaustiveDependencies(viewerId):
    identify: useCallback(identify, [
      employees,
      institution,
      motherIdWithNull,
      motherInstitutionName,
      plan,
      realInstitution,
      subscriptionStatus,
      viewerId,
    ]),
    reset: useCallback(reset, []),
    trackMemberCreated: useCallback(trackMemberCreated, []),
    trackMemberInvitationCreated: useCallback(trackMemberInvitationCreated, []),
    trackOrganizationUnitCreated: useCallback(trackOrganizationUnitCreated, []),
    trackOrganizationFromPartnerCreated: useCallback(trackOrganizationFromPartnerCreated, []),
    trackLogin: useCallback(trackLogin, []),
    trackSignUpProgress: useCallback(trackSignUpProgress, []),
    trackSignUp: useCallback(trackSignUp, []),
    trackMessageOpened: useCallback(trackMessageOpened, []),
    trackMessageCreated: useCallback(trackMessageCreated, []),
    trackInternalCommentCreated: useCallback(trackInternalCommentCreated, []),
    trackReportOpened: useCallback(trackReportOpened, []),
    trackReportCreated: useCallback(trackReportCreated, []),
    trackReportAssignedMemberChanged: useCallback(trackReportAssignedMemberChanged, []),
    trackReportPriorityChanged: useCallback(trackReportPriorityChanged, []),
    trackReportLabelChanged: useCallback(trackReportLabelChanged, []),
    trackReportJustificationChanged: useCallback(trackReportJustificationChanged, []),
    trackReportDeadlineChanged: useCallback(trackReportDeadlineChanged, []),
    trackReportStatusChanged: useCallback(trackReportStatusChanged, []),
    trackReportRedaction: useCallback(trackReportRedaction, []),
    trackChannelCreated: useCallback(trackChannelCreated, []),
    trackPurchaseCompleted: useCallback(trackPurchaseCompleted, []),
  }

  if (ALLOW_TRACKING) {
    return analytics
  }

  const logger = (name: string, ...args: unknown[]) => console.info(`[Analytics]: ${name}`, args)

  const mockAnalytics = Object.keys(analytics).reduce(
    (acc, key) => ({
      ...acc,
      // biome-ignore lint/correctness/useHookAtTopLevel:
      [key]: useCallback((...args: unknown[]) => logger(key, args), [key]),
    }),
    {} as Record<keyof typeof analytics, () => void>
  )

  return mockAnalytics
}

export default useAnalytics
