import { UntitledIcon } from '@faceup/icons'
import { ulPlus } from '@faceup/icons/ulPlus'
import { ulTrash03 } from '@faceup/icons/ulTrash03'
import { Upload } from '@faceup/ui-base'
import { ALLOWED_IMAGE_MIME_TYPE_EXTENSION_MAP } from '@faceup/utils'
import { Box } from '@mantine/core'
import { useContext } from 'react'
import { Text } from '../CoreComponents'
import { UiContext } from '../UiProvider/UiProvider'
import { useThemeColors } from '../hooks'

type DropzoneImageProps = {
  setLogo: (file: File) => void
  onRemove: () => void
  shownLogo: string | null
  loading: boolean
  width?: string
  height?: string
}

export const DropzoneImage = ({
  setLogo,
  shownLogo,
  loading,
  onRemove,
  width,
  height,
}: DropzoneImageProps) => {
  const { dropzoneImage } = useContext(UiContext)
  const { getColorFromTheme } = useThemeColors()

  return (
    <Box
      sx={{
        '.ant-upload.ant-upload-select-picture-card, .ant-upload-list-picture-card-container': {
          width,
          height,
          borderRadius: '6px',
          overflow: 'hidden',
          backgroundColor: 'transparent',
        },
        '.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item':
          {
            padding: '5px',
          },
        '.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select, .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container':
          {
            width,
          },
      }}
    >
      <Upload
        accept={Object.keys(ALLOWED_IMAGE_MIME_TYPE_EXTENSION_MAP).join(',')}
        maxCount={1}
        listType='picture-card'
        fileList={
          shownLogo ? [{ uid: 'uploaded', name: 'image.png', status: 'done', url: shownLogo }] : []
        }
        onRemove={onRemove}
        onChange={info => {
          if (info.file.originFileObj) {
            setLogo(info.file.originFileObj)
          }
        }}
        showUploadList={{
          removeIcon: (
            <UntitledIcon
              icon={ulTrash03}
              color={getColorFromTheme('white')}
              style={{ cursor: 'pointer' }}
            />
          ),
          showPreviewIcon: false,
        }}
      >
        {!shownLogo && (
          <button
            style={{
              border: 0,
              background: 'none',
              color: getColorFromTheme('primary.100'),
              cursor: 'pointer',
            }}
            type='button'
            disabled={loading}
          >
            <UntitledIcon icon={ulPlus} />
            <div style={{ marginTop: 8 }}>
              <Text size='small' weight='bold'>
                {dropzoneImage?.upload ?? 'Upload'}
              </Text>
            </div>
          </button>
        )}
      </Upload>
    </Box>
  )
}
