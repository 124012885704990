import styled from '@emotion/styled'
import { Link } from '@faceup/router'
import { Space } from '@faceup/ui-base'
import { DEFAULT_LANGUAGE } from '@faceup/utils'
import { Box } from '@mantine/core'
import { useContext } from 'react'
import { LanguageContext } from '../Contexts/LanguageContext'
import { ThemeContext } from '../ThemeContext'
import { FormattedMessage, defineMessages } from '../TypedIntl'
import logoFu from '../assets/logo-fu.svg'
import logoNntb from '../assets/logo-nntb.svg'
import { mq } from '../constants'
import { ThemeType } from '../locales'
import LanguageDropdown from './LanguageDropdown'
import RegisterLink from './RegisterLink'

const messages = defineMessages({
  signIn: 'Administration.login.signIn',
})

export type PublicHeaderProps = {
  showLinkTo?: 'signIn' | 'register' | 'none'
  isLanguageDropdownShown?: boolean
}

const PublicHeader = ({
  showLinkTo = 'register',
  isLanguageDropdownShown = true,
}: PublicHeaderProps) => {
  const { language, changeLanguage } = useContext(LanguageContext)
  const { project } = useContext(ThemeContext)
  const webUrl = project === ThemeType.NNTB ? 'https://www.nntb.cz' : 'https://www.faceup.com'

  return (
    <HeaderWrapper>
      <InnerHeader>
        <a href={webUrl}>
          <Logo src={project === ThemeType.NNTB ? logoNntb : logoFu} alt={`Logo ${project}`} />
        </a>
        <HeaderGroup data-cy='login-header'>
          {isLanguageDropdownShown && (
            <LanguageDropdown
              selectedLanguage={language ?? DEFAULT_LANGUAGE}
              onClickLanguage={changeLanguage}
              areLanguagesNative
              styles={{
                targetText: {
                  tt: 'uppercase',
                  fw: 600,
                  c: 'prussianBlue.8',
                },
              }}
            />
          )}
          <HideOnMobile>
            <Box
              sx={theme => ({
                color: theme.colors.prussianBlue[8],
                a: {
                  color: theme.colors.primary[7],
                },
              })}
            >
              {showLinkTo === 'signIn' && (
                <Space>
                  <FormattedMessage
                    {...messages.signIn}
                    values={{
                      login: login => <Link to={routes => routes.home()}>{login}</Link>,
                    }}
                  />
                </Space>
              )}
              {showLinkTo === 'register' && <RegisterLink />}
            </Box>
          </HideOnMobile>
        </HeaderGroup>
      </InnerHeader>
    </HeaderWrapper>
  )
}

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
`

export const InnerHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  text-transform: uppercase;
  color: #38576b;
  font-weight: 600;
  margin: 32px 32px;
`

export const HeaderGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
`

const HideOnMobile = styled.div`
  display: none;

  ${mq.smUp} {
    display: block;
  }
`

export const Logo = styled.img`
  max-width: 170px;
  max-height: 44px;
  width: auto;
`

export default PublicHeader
