import { gql, useMutation } from '@apollo/client'
import { notification } from '@faceup/ui-base'
import type { Language } from '@faceup/utils'
import { sharedMessages } from '../../../../../Shared/translations'
import { useIntl } from '../../../../../TypedIntl'
import type {
  CreatePageMutation,
  CreatePageMutationVariables,
} from '../../../../../__generated__/globalTypes'
import { useMotherId } from '../../../../../hooks/useMotherId'
import PageFormAbstract from './PageFormAbstract'
import { PagesListFragments } from './PagesList'

type CreatePageFormProps = {
  reportSourceId: string
  language: Language
  onPageCreated: (pageId: string) => void
}

const mutations = {
  CreatePageMutation: gql`
    mutation CreatePageMutation($input: CreatePageInput!, $language: Language!) {
      createPage(input: $input) {
        reportSource {
          id

          ...PagesList_reportSource
        }
      }
    }
    ${PagesListFragments.PagesList_reportSource}
  `,
}

export const CreatePageForm = ({
  reportSourceId,
  language,
  onPageCreated,
}: CreatePageFormProps) => {
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()

  const [createPage] = useMutation<CreatePageMutation, CreatePageMutationVariables>(
    mutations.CreatePageMutation,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
    }
  )

  return (
    <PageFormAbstract
      isTitleAutofocused
      buttonVariant='create'
      values={{ icon: 'home', title: '', content: '' }}
      type='reportForm'
      onSubmit={async ({ title, content, icon }) => {
        const result = await createPage({
          variables: {
            input: {
              reportSourceId,
              icon,
              title,
              content,
              language,
              motherId: getMotherId(),
            },
            language,
          },
        })
        const pages = result.data?.createPage?.reportSource?.pages ?? []
        const newlyAddedPage = pages[pages.length - 1]
        if (newlyAddedPage) {
          onPageCreated(newlyAddedPage.id)
        }
        return !result.errors
      }}
    />
  )
}
