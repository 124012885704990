import type { UntitledIconData } from '@faceup/icons'

export const ucFormItemEmail: UntitledIconData = {
  prefix: 'custom',
  name: 'ucFormItemEmail',
  viewBox: '0 0 20 20',
  paths: [
    {
      shape:
        'M5 7.5L9.08246 10.3577C9.41304 10.5891 9.57834 10.7048 9.75813 10.7497C9.91695 10.7892 10.0831 10.7892 10.2419 10.7497C10.4217 10.7048 10.587 10.5891 10.9175 10.3577L15 7.5M7.4 14H12.6C13.4401 14 13.8601 14 14.181 13.8365C14.4632 13.6927 14.6927 13.4632 14.8365 13.181C15 12.8601 15 12.4401 15 11.6V8.4C15 7.55992 15 7.13988 14.8365 6.81901C14.6927 6.53677 14.4632 6.3073 14.181 6.16349C13.8601 6 13.4401 6 12.6 6H7.4C6.55992 6 6.13988 6 5.81901 6.16349C5.53677 6.3073 5.3073 6.53677 5.16349 6.81901C5 7.13988 5 7.55992 5 8.4V11.6C5 12.4401 5 12.8601 5.16349 13.181C5.3073 13.4632 5.53677 13.6927 5.81901 13.8365C6.13988 14 6.55992 14 7.4 14Z',
      stroke: '#8296A2',
      strokeWidth: '1.4',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  rects: [
    {
      x: '0.785',
      y: '0.785',
      width: '18.43',
      height: '18.43',
      rx: '7.215',
      stroke: '#CED6DB',
      strokeWidth: '1.57',
    },
  ],
}
