import { ContentLayout } from '@faceup/ui'
import { AnswersChart } from './SurveysView/components'

const Typography = () => (
  <ContentLayout header={<ContentLayout.Header title='Typography' />}>
    <AnswersChart
      answersCount={60}
      label='Graph name'
      statistics={[
        { label: 'Lorem', count: 10 },
        { label: 'Ipsum', count: 20 },
        { label: 'Dolor', count: 30 },
      ]}
    />
  </ContentLayout>
)

export default Typography
