import { ModalForm, Select, useForm, yup } from '@faceup/form'
import { useMotherId } from '@faceup/institution'
import { sharedMessages } from '@faceup/localization'
import type { ModalProps } from '@faceup/ui'
import { FormattedMessage, defineMessages } from '../../../TypedIntl'
import { useSurveyExport } from '../hooks/useSurveyExport'

type Props = Required<Pick<ModalProps, 'opened' | 'onClose'>> & { reportSourceId: string }

const formats = ['csv', 'xlsx'] as const

const messages = defineMessages({
  format: 'Administration.surveys.export.format',
  title: 'Administration.surveys.export.title',
})

export const SurveyExportModal = ({ reportSourceId, onClose, ...modalProps }: Props) => {
  const { getMotherId } = useMotherId()
  const { exportSurveyToCsv, exportSurveyToXlsx } = useSurveyExport()
  const motherId = getMotherId()

  const schema = yup.object({
    format: yup.mixed().oneOf(formats).required(),
  })

  const form = useForm({
    schema,
    afterSubmit: 'resetValues',
  })

  return (
    <ModalForm
      {...modalProps}
      width={520}
      title={<FormattedMessage {...messages.title} />}
      form={form}
      customSubmitButtonText={<FormattedMessage {...sharedMessages.download} />}
      isSuccessMessageDisplayed={false}
      onSubmit={values => {
        const exportFile = values.format === 'csv' ? exportSurveyToCsv : exportSurveyToXlsx

        exportFile(motherId, reportSourceId)
        onClose()

        return true
      }}
      onClose={onClose}
    >
      <Select
        label={<FormattedMessage {...messages.format} />}
        name='format'
        options={formats.map(format => ({
          label: format.toUpperCase(),
          value: format,
        }))}
        control={form.control}
      />
    </ModalForm>
  )
}
