import { UntitledIcon } from '@faceup/icons'
import { Modal, type ModalProps, Text } from '@faceup/ui'
import { Avatar, Paper, SimpleGrid, Stack, Title, UnstyledButton } from '@mantine/core'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import type { ContactVariant } from '../ContactSalesModal'

const messages = defineMessages({
  title: 'Administration.contactSalesModal.overview.title',
  heading: 'Administration.contactSalesModal.overview.heading',
  description: 'Administration.contactSalesModal.overview.description',
})

type ContactSalesModalOverviewProps = {
  contactVariants: ContactVariant[]
} & Required<Pick<ModalProps, 'opened' | 'onClose'>>

const ContactSalesModalOverview = ({
  contactVariants,
  ...props
}: ContactSalesModalOverviewProps) => (
  <Modal {...props} title={<FormattedMessage {...messages.title} />} width={950}>
    <Stack spacing={32}>
      <Stack>
        <Title order={3} align='center'>
          <FormattedMessage {...messages.heading} />
        </Title>
        <Text
          color='subtleText'
          sx={{
            textAlign: 'center',
          }}
        >
          <FormattedMessage {...messages.description} />
        </Text>
      </Stack>
      <SimpleGrid cols={4} spacing='xl'>
        {contactVariants.map(({ label, icon, onClick, type }) => (
          <UnstyledButton key={type} onClick={onClick}>
            <Paper
              shadow='xs'
              sx={{
                height: '100%',
              }}
            >
              <Stack
                spacing={30}
                align='center'
                sx={{
                  padding: '42px 32px 32px 32px',
                }}
              >
                <Avatar size='lg' color='primary'>
                  <UntitledIcon icon={icon} size={24} />
                </Avatar>
                <Text weight='bold' align='center'>
                  {label}
                </Text>
              </Stack>
            </Paper>
          </UnstyledButton>
        ))}
      </SimpleGrid>
    </Stack>
  </Modal>
)

export default ContactSalesModalOverview
