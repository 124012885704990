import { type RefObject, useEffect } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 * https://stackoverflow.com/a/42234988
 */
const useOutsideAlerter = (
  ref: RefObject<HTMLElement>,
  onClickOutside: (event: Event) => void,
  parentRef?: RefObject<HTMLElement>
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: MouseEvent) => {
      const isClickedInsideElement = ref?.current?.contains(event.target as HTMLElement)
      // Always true if no parent is set
      const isClickInsideParent =
        parentRef === undefined || parentRef?.current?.contains(event.target as HTMLElement)
      if (!isClickedInsideElement && isClickInsideParent) {
        onClickOutside(event)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, parentRef, onClickOutside])
}

export default useOutsideAlerter
