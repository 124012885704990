import { yup } from '@faceup/form'
import { useSearchParams } from '@faceup/router'
import {
  RATING_RANGE,
  type Rating,
  ReportCreationOrigin,
  ReportJustification,
  ReportSourceType,
} from '@faceup/utils'
import moment from 'moment-timezone'
import {
  ClosingReason,
  ReportDeadlineFilter,
  ReportPriority,
  ReportStatusType,
} from '../__generated__/globalTypes'

export enum CreationOriginFilter {
  Original = 'Original',
  Redacted = 'Redacted',
}

const origins = {
  [CreationOriginFilter.Original]: [ReportCreationOrigin.Reporter, ReportCreationOrigin.Member],
  [CreationOriginFilter.Redacted]: [ReportCreationOrigin.Redaction],
}

export const reportsFilterSchema = yup.object().shape({
  dateFrom: yup.date(),
  dateTo: yup.date(),
  categoryIds: yup.array().min(1).of(yup.string().required()),
  assignedManagerIds: yup.array().min(1).of(yup.string().required()),
  selectedCompanyIds: yup.array().min(1).of(yup.string().required()),
  motherIds: yup.array().min(1).of(yup.string().required()),
  closingReasons: yup
    .array()
    .min(1)
    .of(yup.string().oneOf(Object.values(ClosingReason)).required()),
  sources: yup
    .array()
    .min(1)
    .of(yup.string().oneOf(Object.values(ReportSourceType)).required()),
  reportSourceIds: yup.array().min(1).of(yup.string().required()),
  labelIds: yup.array().min(1).of(yup.string().required()),
  statuses: yup
    .array()
    .min(1)
    .of(yup.string().oneOf(Object.values(ReportStatusType)).required()),
  priorities: yup
    .array()
    .min(1)
    .of(yup.string().oneOf(Object.values(ReportPriority)).required()),
  deadline: yup.string().oneOf(Object.values(ReportDeadlineFilter)),
  tag: yup.string(),
  justifications: yup
    .array()
    .min(1)
    .of(yup.string().oneOf(Object.values(ReportJustification)).required()),
  creationOrigin: yup.string().oneOf(Object.values(CreationOriginFilter)),
  rating: yup.string().oneOf(RATING_RANGE.map(String)),
})

type ReturnReportsFilterSchema = {
  dateFrom: string | null
  dateTo: string | null
  categoryIds: string[] | null
  closingReasons: ClosingReason[] | null
  companyIds: string[] | null
  managerIds: string[] | null
  labelIds: string[] | null
  statuses: ReportStatusType[] | null
  priorities: ReportPriority[] | null
  deadline: ReportDeadlineFilter | null
  sources: ReportSourceType[] | null
  tag: string | null
  justifications: ReportJustification[] | null
  reportSourceIds: string[] | null
  creationOrigins: ReportCreationOrigin[] | undefined
  rating: Rating | null
}

export type ReportsFilterSchema = {
  dateFrom?: string
  dateTo?: string
  categoryIds?: string[]
  assignedManagerIds?: string[]
  selectedCompanyIds?: string[]
  motherIds?: string[]
  closingReasons?: ClosingReason[]
  sources?: ReportSourceType[]
  reportSourceIds?: string[]
  labelIds?: string[]
  statuses?: ReportStatusType[]
  priorities?: ReportPriority[]
  deadline?: ReportDeadlineFilter
  tag?: string
  justifications?: ReportJustification[]
  creationOrigin?: CreationOriginFilter
  rating?: `${Rating}`
}

// Hint: Do not forget to add new parameter into gql queries which use this function,
// otherwise it will be sent to BE but not used. GQL doesn't alert you. This advice prevents unnecessary debugging.
export const convertFiltersForGqlVariables = (
  filter: ReportsFilterSchema
): ReturnReportsFilterSchema => ({
  dateFrom: filter?.dateFrom ?? null,
  dateTo: filter?.dateTo ?? null,
  categoryIds: filter?.categoryIds ?? null,
  closingReasons: filter?.closingReasons ?? null,
  companyIds: filter?.selectedCompanyIds ?? null,
  managerIds: filter?.assignedManagerIds ?? null,
  labelIds: filter?.labelIds ?? null,
  statuses: filter?.statuses ?? null,
  priorities: filter.priorities ?? null,
  deadline: filter.deadline ?? null,
  sources: filter.sources ?? null,
  // ID can be searchable with prefixed (or suffixed) # and we do not want to send this character to BE, do not use `replaceAll` as it is not supported in all browsers
  tag: filter.tag?.split('#').join('') ?? null,
  justifications: filter?.justifications ?? null,
  reportSourceIds: filter.reportSourceIds ?? null,
  creationOrigins: filter.creationOrigin ? origins[filter.creationOrigin] : undefined,
  rating: filter.rating ? (Number(filter.rating) as Rating) : null,
})

export type FilterVariant = 'reports' | 'statistics'

export const defaultFilter: Record<FilterVariant, ReportsFilterSchema> = {
  reports: {
    statuses: [ReportStatusType.Open],
  },
  statistics: {
    statuses: [ReportStatusType.Open, ReportStatusType.Closed],
    dateFrom: moment().subtract(1, 'year').startOf('day').toISOString(),
    dateTo: moment().endOf('day').toISOString(),
  },
}

export const useFilterParams = (variant: FilterVariant) => {
  const [params, setParams] = useSearchParams()

  const getFilter = () => {
    const filter = Object.entries(reportsFilterSchema.fields).reduce((acc, [key, item]) => {
      const getValue = () => {
        if ('type' in item && item.type === 'array') {
          const values = params.getAll(key)
          return values.length > 0 ? values : undefined
        }
        return params.get(key) ?? undefined
      }
      const value = getValue()
      if (value === undefined) {
        return acc
      }
      return { ...acc, [key]: value }
    }, {} as ReportsFilterSchema)
    if (Object.keys(filter).length === 0) {
      setParams(defaultFilter[variant])
      return defaultFilter[variant]
    }
    if (reportsFilterSchema.isValidSync(filter)) {
      return filter
    }

    setParams(defaultFilter[variant])
    return defaultFilter[variant]
  }

  const filter = getFilter()

  const setFilter = (filter: ReportsFilterSchema) => {
    const params = Object.entries(filter).reduce(
      (acc, [key, value]) => {
        if (value === undefined) {
          return acc
        }
        return { ...acc, [key]: value }
      },
      {} as Record<string, string | string[]>
    )
    setParams(params)
  }
  return { filter, setFilter }
}
