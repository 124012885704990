import { readReportKey } from './report'
import { getSodium, handleError } from './utils/general'

export const readClosingComment = async (
  ciphertext: string | null,
  nonce: string,
  recipientKey: string
) => {
  if (!ciphertext) {
    return { text: '' }
  }

  const sodium = await getSodium()

  try {
    const payload = await readReportKey(recipientKey)

    if (typeof payload === 'string') {
      throw new Error(payload)
    }

    const { reportKey } = payload
    const decryptedText = sodium.crypto_secretbox_open_easy(
      sodium.from_base64(ciphertext),
      sodium.from_base64(nonce),
      reportKey,
      'text'
    )

    return { text: decryptedText }
  } catch (e) {
    return handleError(e)
  }
}

export const createClosingComment = async (text: string, recipientKey: string) => {
  const sodium = await getSodium()

  try {
    const nonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES)
    const payload = await readReportKey(recipientKey)
    if (typeof payload === 'string') {
      throw new Error(payload)
    }

    const { reportKey } = payload
    const ciphertext = sodium.crypto_secretbox_easy(text, nonce, reportKey, 'base64')

    return {
      body: ciphertext,
      nonce: sodium.to_base64(nonce),
    }
  } catch (e) {
    return handleError(e)
  }
}
