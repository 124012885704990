import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulPlus } from '@faceup/icons/ulPlus'
import { Button, Typography } from '@faceup/ui-base'
import { Avatar, LoadingOverlay, Stack } from '@mantine/core'
import type { ReactNode } from 'react'

type NoDataOverlayProps = {
  visible: boolean
  icon: UntitledIconData
  label: ReactNode
  description?: ReactNode
  button?: {
    icon?: UntitledIconData
    label: ReactNode
    onClick: () => void
  }
  isTransparentBackground?: boolean
}

export const NoDataOverlay = ({
  visible,
  icon,
  label,
  description,
  button,
  isTransparentBackground,
}: NoDataOverlayProps) => (
  <LoadingOverlay
    overlayOpacity={isTransparentBackground ? 0 : 1}
    zIndex={1}
    visible={visible}
    loader={
      <Stack align='center' spacing={16}>
        <Avatar size={56} radius='xl' color='blue.6'>
          <UntitledIcon icon={icon} />
        </Avatar>
        <Stack align='center' spacing={8}>
          <Typography.Title level={5}>{label}</Typography.Title>
          <Typography.Text type='secondary'>{description}</Typography.Text>
        </Stack>
        {button && (
          <Button
            type='primary'
            icon={<UntitledIcon icon={button.icon ?? ulPlus} />}
            onClick={button.onClick}
          >
            {button.label}
          </Button>
        )}
      </Stack>
    }
  />
)
