import { gql, useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { Link, useNavigate, useParams } from '@faceup/router'
import { Button, useLayout } from '@faceup/ui'
import { Checkbox as AntCheckbox, Form, Input, List, Space, Typography } from '@faceup/ui-base'
import {
  DEFAULT_REGION,
  RecaptchaType,
  errorMessageRecaptchaScoreTooLow,
  isCompanyEmail,
  isEmail,
} from '@faceup/utils'
import { useState } from 'react'
import Review from '../../Components/Review'
import { SelectRegion } from '../../Components/SelectRegion'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import type {
  VerifyEmailMutation,
  VerifyEmailMutationVariables,
} from '../../__generated__/globalTypes'
import { mq } from '../../constants'
import useConfigForProject from '../../hooks/useConfigForProject'
import { useRecaptcha } from '../../hooks/useRecaptcha'
import useRegion from '../../utils/useRegion'
import PageTemplateUnlogged from '../PageTemplateUnlogged'
import logoAaaAuto from './assets/aaa-auto-logo.svg'
import checkIcon from './assets/check-icon.svg'
import dotsIcon from './assets/dots-icon.svg'
import logoDPD from './assets/dpd-logo.svg'
import messageIcon from './assets/message-icon.svg'
import logoPacketa from './assets/packeta-logo.svg'
import reviewIcon from './assets/review-person-icon.png'
import logoZentiva from './assets/zentiva-logo.svg'

const { Title } = Typography

const messages = defineMessages({
  title: 'Administration.registration.trialRequest.title',
  purchaseTitle: 'Administration.registration.purchase.title',
  description: 'Administration.registration.trialRequest.description',
  purchaseDescription: 'Administration.registration.purchase.description',
  wantToSendReport: 'Administration.registration.trialRequest.wantToSendReport',
  emailLabel: 'Administration.registration.trialRequest.email.label',
  errorEmail: 'Administration.registration.trialRequest.email.error',
  errorEmailDuplicit: 'Administration.registration.trialRequest.email.errorDuplicit',
  errorEmailSuggestion: 'Administration.registration.trialRequest.emailSuggestion.error',
  outerDisclaimer: 'Administration.registration.trialRequest.outerDisclaimer',
  tryForFree: 'Administration.registration.trialRequest.tryForFree',
  tryForFreePurchase: 'Administration.registration.purchase.tryForFree',
  seeWhyUseFaceUp: 'Administration.registration.seeWhyUseFaceUp',
  infoTitle: 'Administration.registration.trialRequest.info.title',
  infoFeatures: 'Administration.registration.trialRequest.info.features',
  infoFeaturesPurchase: 'Administration.registration.purchase.info.features',
  reviewName: 'Administration.registration.review.name',
  reviewJobDescription: 'Administration.registration.review.jobDescription',
  reviewText: 'Administration.registration.review.text',
})

const mutations = {
  VerifyEmail: gql`
    mutation VerifyEmailMutation($input: VerifyEmailInput!) {
      verifyEmail(input: $input) {
        token
        emailSuggestion
      }
    }
  `,
}

type TrialRequestContentProps = {
  isPurchase?: boolean
}

const TrialRequestContent = ({ isPurchase }: TrialRequestContentProps) => {
  const { plan } = useParams<'plan'>()
  const navigate = useNavigate()
  const [email, setEmail] = useState({
    value: '',
    errorCompany: false,
    errorDuplicate: false,
    errorInvalid: false,
    errorSuggestion: '',
  })
  const [agreeWithDisclaimer, setAgreeWithDisclaimer] = useState({ value: false, error: false })
  const { termsLink, privacyLink, reportingAppDomain } = useConfigForProject()
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)
  const region = useRegion()
  const {
    recaptcha,
    resetRecaptcha,
    handleTooLowRecaptchaScore,
    isRecaptchaInvalid,
    getRecaptchaToken,
  } = useRecaptcha()
  const layout = useLayout()

  const [verifyEmail, { loading: verifyEmailLoading }] = useMutation<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >(mutations.VerifyEmail, {
    onError: error => {
      switch (error.message) {
        case 'Invalid email':
          setEmail({ ...email, errorCompany: false, errorDuplicate: false, errorInvalid: true })
          resetRecaptcha()
          break
        case errorMessageRecaptchaScoreTooLow: {
          handleTooLowRecaptchaScore()
          break
        }
        default:
          setEmail({ ...email, errorCompany: false, errorDuplicate: true, errorInvalid: false })
          break
      }
      setLoading(false)
    },
    onCompleted: ({ verifyEmail }) => {
      if (verifyEmail?.emailSuggestion) {
        setEmail({
          ...email,
          errorCompany: false,
          errorDuplicate: false,
          errorInvalid: false,
          errorSuggestion: verifyEmail?.emailSuggestion,
        })
        setLoading(false)
        return
      }
      window?.dataLayer?.push({
        event: 'virtual_pageview',
        page_name: '/register/verify-email',
      })
      setLoading(false)

      navigate(routes => routes.registerFillInfo({ token: verifyEmail?.token ?? '' }))
    },
  })

  const isFormValid = async () => {
    let isValid = true

    if (!(await isCompanyEmail(email.value))) {
      setEmail({ ...email, errorDuplicate: false, errorInvalid: false, errorCompany: true })
      isValid = false
    }

    if (!isEmail(email.value)) {
      setEmail({ ...email, errorDuplicate: false, errorInvalid: true, errorCompany: false })
      isValid = false
    }

    if (!agreeWithDisclaimer.value) {
      setAgreeWithDisclaimer(prevState => ({ ...prevState, error: true }))
      isValid = false
    }

    if (isRecaptchaInvalid()) {
      isValid = false
    }

    return isValid
  }

  const infoFeaturesMessage = isPurchase ? messages.infoFeaturesPurchase : messages.infoFeatures
  const features = formatMessage(infoFeaturesMessage).split('\n')

  return (
    <PageTemplateUnlogged
      publicHeaderProps={{ showLinkTo: 'signIn' }}
      isFooterShown
      contentUnderCard={
        <SeeWhyBoxRelative>
          <SeeWhy />
        </SeeWhyBoxRelative>
      }
      cardProps={{
        style: {
          maxWidth: layout.isMdUp ? 897 : undefined,
        },
        styles: {
          body: {
            padding: 0,
            backgroundColor: '#f2fafe',
          },
        },
      }}
    >
      <BoxWrapper>
        <BoxLeft>
          <BoxLeftContentForm>
            <div>
              <Space
                direction='vertical'
                align='center'
                style={{ marginBottom: '2.5rem', textAlign: 'center', width: '100%' }}
              >
                <Title level={3}>
                  {isPurchase ? (
                    <FormattedMessage {...messages.purchaseTitle} />
                  ) : (
                    <FormattedMessage {...messages.title} />
                  )}
                </Title>
                <Description>
                  {isPurchase ? (
                    <FormattedMessage {...messages.purchaseDescription} />
                  ) : (
                    <FormattedMessage {...messages.description} />
                  )}
                </Description>
              </Space>
              <Form
                noValidate
                style={{ width: '100%' }}
                layout='vertical'
                onFinish={async () => {
                  if (loading) {
                    return
                  }
                  setLoading(true)
                  if (!(await isFormValid()) || verifyEmailLoading) {
                    setLoading(false)
                    return
                  }

                  verifyEmail({
                    variables: {
                      input: {
                        token: await getRecaptchaToken(),
                        recaptchaType: recaptcha.type,
                        email: email.value,
                        plan,
                        isPurchase,
                      },
                    },
                  })
                }}
              >
                <Form.Item
                  data-cy='TrialRequestContent-email-item'
                  label={<FormattedMessage {...messages.emailLabel} />}
                  {...(email.errorCompany && {
                    validateStatus: 'error',
                    help: <FormattedMessage {...messages.errorEmail} />,
                  })}
                  {...(email.errorInvalid && {
                    validateStatus: 'error',
                    help: <FormattedMessage {...sharedMessages.invalidEmailError} />,
                  })}
                  {...(email.errorSuggestion && {
                    validateStatus: 'error',
                    help: (
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          setEmail({ ...email, value: email.errorSuggestion, errorSuggestion: '' })
                        }
                      >
                        <FormattedMessage
                          {...messages.errorEmailSuggestion}
                          values={{ email: email.errorSuggestion }}
                        />
                      </div>
                    ),
                  })}
                  {...(email.errorDuplicate && {
                    validateStatus: 'error',
                    help: (
                      <FormattedMessage
                        {...messages.errorEmailDuplicit}
                        values={{
                          login: login => <Link to={routes => routes.home()}>{login}</Link>,
                        }}
                      />
                    ),
                  })}
                >
                  <Input
                    type='email'
                    autoComplete='email'
                    size='large'
                    value={email.value}
                    onChange={({ target: { value } }) =>
                      setEmail({
                        value,
                        errorCompany: false,
                        errorDuplicate: false,
                        errorInvalid: false,
                        errorSuggestion: '',
                      })
                    }
                    data-cy='TrialRequestContent-email'
                  />
                </Form.Item>

                <Form.Item>
                  <Checkbox
                    checked={agreeWithDisclaimer.value}
                    onChange={e =>
                      setAgreeWithDisclaimer({ value: e.target.checked, error: false })
                    }
                    error={agreeWithDisclaimer.error}
                    data-cy='TrialRequestContent-terms'
                  >
                    <Typography.Text type={agreeWithDisclaimer.error ? 'danger' : undefined}>
                      <FormattedMessage
                        {...messages.outerDisclaimer}
                        values={{
                          terms: value => (
                            <a
                              style={{ textDecoration: 'underline' }}
                              href={termsLink}
                              rel='noopener noreferrer'
                              target='_blank'
                            >
                              {value}
                            </a>
                          ),
                          privacy: value => (
                            <a
                              style={{ textDecoration: 'underline' }}
                              href={privacyLink}
                              rel='noopener noreferrer'
                              target='_blank'
                            >
                              {value}
                            </a>
                          ),
                        }}
                      />
                    </Typography.Text>
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  {...(recaptcha.error && {
                    validateStatus: 'error',
                    help: <FormattedMessage {...sharedMessages.invalidInputError} />,
                  })}
                  style={
                    recaptcha.type === RecaptchaType.Invisible
                      ? {
                          marginBottom: 0,
                          height: 0,
                        }
                      : {}
                  }
                >
                  <div id='recaptcha-checkbox' />
                </Form.Item>
                <Button
                  type='submit'
                  isFullWidth
                  loading={loading || verifyEmailLoading}
                  data-cy='TrialRequestContent-submit'
                >
                  {isPurchase ? (
                    <FormattedMessage {...messages.tryForFreePurchase} />
                  ) : (
                    <FormattedMessage {...messages.tryForFree} />
                  )}
                </Button>
              </Form>
            </div>
            <SelectRegion value={region.region ?? DEFAULT_REGION} onChange={region.setRegion} />
          </BoxLeftContentForm>

          {!isPurchase && (
            <WrapperWantToSendReport>
              <TextWantToSendReport>
                <FormattedMessage
                  {...messages.wantToSendReport}
                  values={{
                    link: link => (
                      <a href={reportingAppDomain} style={{ display: 'block' }}>
                        <img src={messageIcon} alt='' />{' '}
                        <LinkToReportTextPart>{link}</LinkToReportTextPart>
                      </a>
                    ),
                  }}
                />
              </TextWantToSendReport>
            </WrapperWantToSendReport>
          )}
        </BoxLeft>
        <BoxRight>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
          <TitleRight level={3}>
            <FormattedMessage {...messages.infoTitle} />
          </TitleRight>
          <List
            dataSource={features}
            renderItem={item => (
              <FeatureItem>
                <Space align='start' size={16}>
                  <img src={checkIcon} alt='' />
                  <FeatureText>{item}</FeatureText>
                </Space>
              </FeatureItem>
            )}
          />
          <Review
            image={reviewIcon}
            name={formatMessage(messages.reviewName)}
            jobDescription={formatMessage(messages.reviewJobDescription)}
            text={formatMessage(messages.reviewText)}
            style={{ marginBlockStart: 72 }}
          />
        </BoxRight>
      </BoxWrapper>
      <SeeWhyBoxAbsolute>
        <SeeWhy />
      </SeeWhyBoxAbsolute>
    </PageTemplateUnlogged>
  )
}

const SeeWhy = () => (
  <SeeWhyBox>
    <BrandsBox>
      <BrandImgLarge src={logoDPD} alt='Logo DPD' />
      <BrandImgSmall src={logoZentiva} alt='Logo Zentiva' />
      <BrandImgLarge src={logoPacketa} alt='Logo Packeta' />
      <BrandImgSmall src={logoAaaAuto} alt='Logo AAA Auto' />
    </BrandsBox>
    <SeeWhyParagraph>
      <FormattedMessage {...messages.seeWhyUseFaceUp} />
    </SeeWhyParagraph>
  </SeeWhyBox>
)

const Checkbox = styled(AntCheckbox, { shouldForwardProp: prop => prop !== 'error' })<{
  error: boolean
}>`
  .ant-checkbox-inner {
    border-color: ${({ error }) => (error ? '#ff4d4f' : '#d9d9d9')};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #0e9af7;
  }
`

const SeeWhyBoxAbsolute = styled.div`
  display: none;
  position: absolute;
  bottom: -7.5rem;

  @media (min-height: 900px) {
    display: initial;
  }
`

const SeeWhyBoxRelative = styled.div`
  margin-top: 2rem;

  @media (min-height: 900px) {
    display: none;
  }
`

const SeeWhyBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const SeeWhyParagraph = styled.p`
  color: #688699;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  margin-top: 16px;
`

const BrandsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
`

const BrandImgLarge = styled.img`
  height: 25px;
`

const BrandImgSmall = styled.img`
  height: 15px;
`

const TextWantToSendReport = styled(Typography.Text)`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
`

const Description = styled(Typography.Text)`
  font-size: 12px;
`

const LinkToReportTextPart = styled.span`
  text-decoration: underline;
`

const BoxWrapper = styled.div`
  flex-direction: column;
  ${mq.smUp} {
    flex-direction: row;
  }

  display: flex;
  width: 100%;
`

const BoxLeft = styled.div`
  max-width: 100%;
  ${mq.smUp} {
    max-width: 489px;
  }
  background-color: #ffffff;
  border-radius: 6px;
  padding-block: 56px 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const BoxLeftContent = styled.div`
  padding-inline: 24px;
  padding-bottom: 24px;

  ${mq.smUp} {
    padding-inline-start: 66px;
    padding-inline-end: 48px;
  }
`

const BoxLeftContentForm = styled(BoxLeftContent)`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`

const WrapperWantToSendReport = styled(BoxLeftContent)`
  border-top: 1px solid #e6eaec;
  padding-block-start: 24px;
  text-align: center;
`

const BoxRight = styled.div`
  max-width: 100%;
  ${mq.smUp} {
    max-width: 408px;
  }
  position: relative;
  padding-block: 56px;
  width: 100%;

  & > * {
    padding-inline-start: 24px;
    padding-inline-end: 48px;

    ${mq.smUp} {
      padding-inline-start: 59px;
      padding-inline-end: 32px;
    }
  }
`

const DotsWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  top: 16px;
  width: 100%;
  display: flex;
  justify-content: end;
  padding-inline: 16px;
`

const Dots = styled.div`
  width: 44px;
  height: 84px;
  background-image: url(${dotsIcon});
  background-repeat: no-repeat;
`

const TitleRight = styled(Typography.Title)`
  padding-inline-end: 93px;
  padding-block-end: 16px;
`

const FeatureItem = styled.div`
  padding-block-end: 13px;
`

const FeatureText = styled(Typography.Text)`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #38576b;
`

export default TrialRequestContent
