import { gql } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulRocket02 } from '@faceup/icons/ulRocket02'
import { usAlertCircle } from '@faceup/icons/usAlertCircle'
import { Link, useMatchPath } from '@faceup/router'
import { Button, Tooltip } from '@faceup/ui'
import { Space, Typography } from '@faceup/ui-base'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import type { PaymentNotice_company } from '../../__generated__/globalTypes'
import useSubscriptionDetail, {
  SubscriptionDetailFragments,
} from '../../hooks/useSubscriptionDetail'
import SubscriptionEndedModal from '../SubscriptionEndedModal'
import PaymentNoticeMessage, { PaymentNoticeMessageFragments } from './PaymentNoticeMessage'

const { Text } = Typography

const messages = defineMessages({
  buy: 'Administration.trial.buy',
  renew: 'Administration.paymentNotice.renew',
})

export const PaymentNoticeFragments = {
  PaymentNotice_company: gql`
    fragment PaymentNotice_company on Company {
      id

      billing {
        id
        subscriptionStatus
        subscriptionEnd
      }

      ...SubscriptionDetail_company
      ...PaymentNoticeMessage_company
    }

    ${SubscriptionDetailFragments.SubscriptionDetail_company}
    ${PaymentNoticeMessageFragments.PaymentNoticeMessage_company}
  `,
}

type Props = {
  mother: PaymentNotice_company
  variant?: 'inline' | 'icon'
}

const PaymentNotice = ({ mother, variant = 'inline' }: Props) => {
  const getSubscriptionDetail = useSubscriptionDetail()
  const matchPath = useMatchPath()
  const isSettingsInvoicing = !!matchPath(routes => routes.settingsInvoicing())
  const { state, warningColor } = getSubscriptionDetail(mother)

  const realInstitution = mother?.realInstitution
  const showPaymentInfo =
    ['trialExpired', 'cancelled', 'inTrial', 'nonRenewing'].includes(state) && realInstitution
  const forceSubscriptionEndedModal = ['trialExpired', 'cancelled'].includes(state)

  if (!showPaymentInfo) {
    return null
  }

  switch (variant) {
    case 'icon':
      return (
        <>
          <Tooltip title={<PaymentNoticeMessage institution={mother} />} placement='right'>
            <Link to={routes => routes.settingsInvoicing()}>
              <UntitledIcon icon={usAlertCircle} color={warningColor} fontSize={32} />
            </Link>
          </Tooltip>
          <SubscriptionEndedModal
            visible={forceSubscriptionEndedModal && !isSettingsInvoicing}
            variant={state === 'trialExpired' ? 'trialEnded' : 'subscriptionEnded'}
          />
        </>
      )
    default:
      return (
        <Space size='large'>
          <Text strong>
            <PaymentNoticeMessage institution={mother} />
          </Text>
          <Link to={routes => routes.settingsInvoicing()}>
            <Button
              iconBefore={
                <UntitledIcon icon={ulRocket02} fontSize='1rem' style={{ marginRight: '0.5rem' }} />
              }
            >
              {state === 'nonRenewing' ? (
                <FormattedMessage {...messages.renew} />
              ) : (
                <FormattedMessage {...messages.buy} />
              )}
            </Button>
          </Link>
        </Space>
      )
  }
}

export default PaymentNotice
