import { FormItemType, getTranslation } from '@faceup/utils'
import { sharedMessages } from '../../../../Shared/translations'
import type {
  FormItemsListItem_formItem,
  FormItemsListItem_reportSource,
} from '../../../../__generated__/globalTypes'
import useMockedReportMessages from '../../../../hooks/useMockedReportMessages'

type ListItemLabelProps = {
  reportSource: FormItemsListItem_reportSource
  formItem: FormItemsListItem_formItem
}

export const ListItemLabel = ({ formItem, reportSource }: ListItemLabelProps) => {
  const { formatMessageWithConfigLanguage } = useMockedReportMessages(reportSource.defaultLanguage)
  const labelTranslation = getTranslation(
    formItem.labelTranslations,
    reportSource.defaultLanguage,
    reportSource.defaultLanguage
  )
  const labels: Record<FormItemType, string> = {
    [FormItemType.Category]:
      labelTranslation || formatMessageWithConfigLanguage(sharedMessages.categoryLabel),
    [FormItemType.OrganizationalUnit]:
      labelTranslation || formatMessageWithConfigLanguage(sharedMessages.organizationLabel),
    [FormItemType.Classroom]:
      labelTranslation || formatMessageWithConfigLanguage(sharedMessages.classNameLabel),
    [FormItemType.MoreInformation]:
      labelTranslation || formatMessageWithConfigLanguage(sharedMessages.moreInfoLabel),
    [FormItemType.SimpleText]: labelTranslation,
    [FormItemType.Select]: labelTranslation,
    [FormItemType.MultiSelect]: labelTranslation,
    [FormItemType.SenderName]:
      labelTranslation || formatMessageWithConfigLanguage(sharedMessages.globalNameLabel),
    [FormItemType.MultilineText]: labelTranslation,
    [FormItemType.Date]: labelTranslation,
    [FormItemType.Email]: labelTranslation,
    [FormItemType.PhoneNumber]: labelTranslation,
  }

  return <>{labels[formItem.type]}</>
}
