import type { UntitledIconData } from '@faceup/icons'

export const ucFormItemLargeText: UntitledIconData = {
  prefix: 'custom',
  name: 'ucFormItemLargeText',
  viewBox: '0 0 20 20',
  paths: [
    {
      shape: 'M14 8.5H6M14 5.5H6M14 11.5H6M10 14.5H6',
      stroke: '#8296A2',
      strokeWidth: '1.4',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  rects: [
    {
      x: '0.785',
      y: '0.785',
      width: '18.43',
      height: '18.43',
      rx: '7.215',
      stroke: '#CED6DB',
      strokeWidth: '1.57',
    },
  ],
}
