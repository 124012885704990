import { lazy } from 'react'

const SurveysTableView = lazy(() => import('./SurveysTableView'))
const SurveyDetailView = lazy(() => import('./SurveyDetailView'))
const SurveySubmissionsView = lazy(() => import('./SurveySubmissionsView'))

const SurveysView = {
  SurveysTableView,
  SurveyDetailView,
  SurveySubmissionsView,
}

export default SurveysView
