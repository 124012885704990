import {
  type FieldValues,
  type FormItemWrapperProps,
  type UseControllerProps,
  useCustomController,
} from '@faceup/form'
import { FormItem } from '@faceup/ui'
import ComponentTextEditor, {
  type TextEditorProps as ComponentTextEditorProps,
} from '../TextEditor'

type TextEditorRHFProps<T extends FieldValues> = UseControllerProps<T> &
  FormItemWrapperProps &
  Partial<Pick<ComponentTextEditorProps, 'onChangeContent' | 'maxLength'>>

export const TextEditor = <T extends FieldValues>(props: TextEditorRHFProps<T>) => {
  const {
    formItemProps,
    inputProps: { onChange: fieldOnChange, value, maxLength },
  } = useCustomController(props)

  return (
    <FormItem {...formItemProps}>
      <ComponentTextEditor
        content={value}
        maxLength={maxLength}
        onChangeContent={content => {
          fieldOnChange(content)
          props.onChangeContent?.(content)
        }}
      />
    </FormItem>
  )
}
