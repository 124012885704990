import {
  Checkbox,
  EditableSortableList,
  type InferType,
  ModalForm,
  NumberInput,
  TextInput,
  useForm,
  yup,
} from '@faceup/form'
import {
  FormItemType,
  LONG_STRING_MAX_LENGTH,
  SHORT_STRING_MAX_LENGTH,
  STRING_MIN_LENGTH,
} from '@faceup/utils'
import type { ReactNode } from 'react'
import { formItemTypeMessages } from '../../../../../../Shared/translations'
import { FormattedMessage, defineMessages } from '../../../../../../TypedIntl'

const messages = defineMessages({
  labelQuestion: 'Administration.pageFormItems.label.question',
  labelDescription: 'Administration.pageFormItems.label.description',
  labelMaxLength: 'Administration.pageFormItems.label.maxLength',
  labelIsRequired: 'Administration.pageFormItems.label.isRequired',
  labelResponses: 'Administration.pageFormItems.label.responses',
})

// Has to be everything specified because we are using stupid enums instead of TS types
const useSchemas = () => ({
  [FormItemType.MultilineText]: yup.object().shape({}),
  [FormItemType.Select]: yup.object().shape({
    question: yup.string().required().max(SHORT_STRING_MAX_LENGTH),
    description: yup.string().max(SHORT_STRING_MAX_LENGTH),
    isRequired: yup.boolean(),
    responses: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string().required(),
          label: yup.string().required().max(SHORT_STRING_MAX_LENGTH),
        })
      )
      .min(1)
      .required(),
  }),
  [FormItemType.MultiSelect]: yup.object().shape({
    question: yup.string().required().max(SHORT_STRING_MAX_LENGTH),
    description: yup.string().max(SHORT_STRING_MAX_LENGTH),
    isRequired: yup.boolean(),
    responses: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string().required(),
          label: yup.string().required().max(SHORT_STRING_MAX_LENGTH),
        })
      )
      .min(1)
      .required(),
  }),
  [FormItemType.MoreInformation]: yup.object().shape({}),
  [FormItemType.Category]: yup.object().shape({}),
  [FormItemType.OrganizationalUnit]: yup.object().shape({}),
  [FormItemType.SenderName]: yup.object().shape({}),
  [FormItemType.Classroom]: yup.object().shape({}),
  [FormItemType.SimpleText]: yup.object().shape({
    question: yup.string().required().max(SHORT_STRING_MAX_LENGTH),
    description: yup.string().max(SHORT_STRING_MAX_LENGTH),
    maxLength: yup.number().required(),
    isRequired: yup.boolean(),
  }),
  [FormItemType.Date]: yup.object().shape({}),
  [FormItemType.Email]: yup.object().shape({}),
  [FormItemType.PhoneNumber]: yup.object().shape({}),
})

type Values<T extends FormItemType> = InferType<ReturnType<typeof useSchemas>[T]>
type FnOrPromise<T> = T | Promise<T>
type FnOnSubmit<T extends FormItemType> = (values: Values<T>) => FnOrPromise<boolean>

type AbstractSurveyFormItemProps = {
  opened: boolean
  onClose: () => void
  variant: 'create' | 'edit'
} & (
  | {
      type: FormItemType.SimpleText
      defaultValues: Values<FormItemType.SimpleText>
      onSubmit: FnOnSubmit<FormItemType.SimpleText>
    }
  | {
      type: FormItemType.Select
      defaultValues: Values<FormItemType.Select>
      onSubmit: FnOnSubmit<FormItemType.Select>
    }
  | {
      type: FormItemType.MultiSelect
      defaultValues: Values<FormItemType.MultiSelect>
      onSubmit: FnOnSubmit<FormItemType.MultiSelect>
    }
  | {
      type: FormItemType.MoreInformation
      defaultValues: Values<FormItemType.MoreInformation>
      onSubmit: FnOnSubmit<FormItemType.MoreInformation>
    }
  | {
      type: FormItemType.Category
      defaultValues: Values<FormItemType.Category>
      onSubmit: FnOnSubmit<FormItemType.Category>
    }
  | {
      type: FormItemType.OrganizationalUnit
      defaultValues: Values<FormItemType.OrganizationalUnit>
      onSubmit: FnOnSubmit<FormItemType.OrganizationalUnit>
    }
  | {
      type: FormItemType.SenderName
      defaultValues: Values<FormItemType.SenderName>
      onSubmit: FnOnSubmit<FormItemType.SenderName>
    }
  | {
      type: FormItemType.Classroom
      defaultValues: Values<FormItemType.Classroom>
      onSubmit: FnOnSubmit<FormItemType.Classroom>
    }
  | {
      type: FormItemType.MultilineText
      defaultValues: Values<FormItemType.MultilineText>
      onSubmit: FnOnSubmit<FormItemType.MultilineText>
    }
  | {
      type: FormItemType.Date
      defaultValues: Values<FormItemType.Date>
      onSubmit: FnOnSubmit<FormItemType.Date>
    }
  | {
      type: FormItemType.Email
      defaultValues: Values<FormItemType.Email>
      onSubmit: FnOnSubmit<FormItemType.Email>
    }
  | {
      type: FormItemType.PhoneNumber
      defaultValues: Values<FormItemType.PhoneNumber>
      onSubmit: FnOnSubmit<FormItemType.PhoneNumber>
    }
)

const items: Record<FormItemType, (props: AbstractSurveyFormItemProps) => ReactNode> = {
  [FormItemType.MultilineText]: () => <>Not Implemented</>,
  [FormItemType.Select]: ({ type, defaultValues, onClose, onSubmit, variant, opened }) => {
    const schema = useSchemas()[FormItemType.Select]
    const form = useForm({
      schema,
      defaultValues: type === FormItemType.Select ? defaultValues : {},
      afterSubmit: variant === 'create' ? 'resetValues' : 'persistValues',
    })
    return (
      <ModalForm
        form={form}
        onSubmit={type === FormItemType.Select ? onSubmit : () => true}
        title={<FormattedMessage {...formItemTypeMessages[FormItemType.Select]} />}
        width={620}
        onClose={onClose}
        opened={opened}
        submitButtonText='save'
      >
        <TextInput
          control={form.control}
          name='question'
          label={<FormattedMessage {...messages.labelQuestion} />}
          data-cy='survey-select-question'
        />
        <TextInput
          control={form.control}
          name='description'
          label={<FormattedMessage {...messages.labelDescription} />}
          data-cy='survey-select-question-description'
        />
        <EditableSortableList
          control={form.control}
          name='responses'
          label={<FormattedMessage {...messages.labelResponses} />}
          canAddItem
          shouldDisplayDeleteButton
          config={{
            minItems: 2,
          }}
        />
        <Checkbox
          control={form.control}
          name='isRequired'
          label={<FormattedMessage {...messages.labelIsRequired} />}
        />
      </ModalForm>
    )
  },
  [FormItemType.MultiSelect]: ({ type, defaultValues, onClose, onSubmit, variant, opened }) => {
    const schema = useSchemas()[FormItemType.MultiSelect]
    const form = useForm({
      schema,
      defaultValues: type === FormItemType.MultiSelect ? defaultValues : {},
      afterSubmit: variant === 'create' ? 'resetValues' : 'persistValues',
    })
    return (
      <ModalForm
        form={form}
        onSubmit={type === FormItemType.MultiSelect ? onSubmit : () => true}
        title={<FormattedMessage {...formItemTypeMessages[FormItemType.MultiSelect]} />}
        width={620}
        onClose={onClose}
        opened={opened}
        submitButtonText='save'
      >
        <TextInput
          control={form.control}
          name='question'
          label={<FormattedMessage {...messages.labelQuestion} />}
          data-cy='survey-multiSelect-question'
        />
        <TextInput
          control={form.control}
          name='description'
          label={<FormattedMessage {...messages.labelDescription} />}
          data-cy='survey-multiSelect-question-description'
        />
        <EditableSortableList
          control={form.control}
          name='responses'
          label={<FormattedMessage {...messages.labelResponses} />}
          canAddItem
          shouldDisplayDeleteButton
          config={{
            minItems: 2,
          }}
        />
        <Checkbox
          control={form.control}
          name='isRequired'
          label={<FormattedMessage {...messages.labelIsRequired} />}
        />
      </ModalForm>
    )
  },
  [FormItemType.MoreInformation]: () => <>Not Implemented</>,
  [FormItemType.Category]: () => <>Not Implemented</>,
  [FormItemType.OrganizationalUnit]: () => <>Not Implemented</>,
  [FormItemType.SenderName]: () => <>Not Implemented</>,
  [FormItemType.Classroom]: () => <>Not Implemented</>,
  [FormItemType.SimpleText]: ({ type, defaultValues, onClose, onSubmit, variant, opened }) => {
    const schema = useSchemas()[FormItemType.SimpleText]
    const form = useForm({
      schema,
      defaultValues: type === FormItemType.SimpleText ? defaultValues : {},
      afterSubmit: variant === 'create' ? 'resetValues' : 'persistValues',
    })
    return (
      <ModalForm
        form={form}
        onSubmit={type === FormItemType.SimpleText ? onSubmit : () => true}
        title={<FormattedMessage {...formItemTypeMessages[FormItemType.SimpleText]} />}
        width={620}
        onClose={onClose}
        opened={opened}
        submitButtonText='save'
      >
        <TextInput
          control={form.control}
          name='question'
          label={<FormattedMessage {...messages.labelQuestion} />}
          data-cy='survey-text-question'
        />
        <TextInput
          control={form.control}
          name='description'
          label={<FormattedMessage {...messages.labelDescription} />}
          data-cy='survey-text-question-description'
        />
        <NumberInput
          control={form.control}
          name='maxLength'
          label={<FormattedMessage {...messages.labelMaxLength} />}
          min={STRING_MIN_LENGTH}
          max={LONG_STRING_MAX_LENGTH}
        />
        <Checkbox
          control={form.control}
          name='isRequired'
          label={<FormattedMessage {...messages.labelIsRequired} />}
        />
      </ModalForm>
    )
  },
  [FormItemType.Date]: () => <>Not Implemented</>,
  [FormItemType.Email]: () => <>Not Implemented</>,
  [FormItemType.PhoneNumber]: () => <>Not Implemented</>,
}

export const AbstractSurveyFormItem = (props: AbstractSurveyFormItemProps) =>
  items[props.type](props)
