import { type ReactNode, useState } from 'react'
import { ModalsContext } from './ModalsContext'

type Props = {
  children: ReactNode
}

const ModalsProvider = ({ children }: Props) => {
  const [isInstitutionVerificationVisible, setIsInstitutionVerificationVisible] = useState(false)
  const [isChatVisible, setIsChatVisible] = useState(false)
  const [isChatLoading, setIsChatLoading] = useState(true)
  const [chatUnreadMessages, setChatUnreadMessages] = useState(0)

  return (
    <ModalsContext.Provider
      value={{
        isInstitutionVerificationVisible,
        setIsInstitutionVerificationVisible,
        isChatVisible,
        setIsChatVisible,
        isChatLoading,
        setIsChatLoading,
        chatUnreadMessages,
        setChatUnreadMessages,
      }}
    >
      {children}
    </ModalsContext.Provider>
  )
}

export default ModalsProvider
