import { useUiBaseConfig } from '@faceup/ui-base'

export type Colors = Record<string, string>

/**
 * This hook takes `companyStatistics` data and generates colors for every graph item
 */
export const useColors = (items: Record<string, unknown>): Colors => {
  const { token } = useUiBaseConfig()

  const paletteColors = [
    token.blue5,
    '#49D4B2',
    '#8E5DDF',
    '#DF5DAB',
    '#FECC4B',
    '#3C7DAC',
    '#5DB0FE',
    '#B6DC4A',
    '#D32C58',
    '#644BFE',
    '#3CAC7D',
    '#363A85',
    '#FF993B',
    '#6F409F',
    '#D681D7',
    '#A5CCE6',
    '#EE4672',
    '#5BABCE',
    '#5E8DB8',
    '#3F32C9',
    '#C5EC87',
  ]

  const paletteColorGenerator = (count: number) => {
    let generatedPalette: string[] = []
    for (let i = 0; i < count; i++) {
      const key = i % paletteColors.length
      const color = paletteColors[key]
      if (color) {
        generatedPalette = [...generatedPalette, color]
      }
    }

    return generatedPalette
  }

  const categoriesPalette = paletteColorGenerator(Object.keys(items ?? {}).length)

  return Object.keys(items ?? {}).reduce<{ [name: string]: string }>(
    (acc, itemName, index) => ({
      ...acc,
      [itemName ?? '']: categoriesPalette[index] ?? '',
    }),
    {}
  )
}
