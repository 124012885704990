import { UntitledIcon } from '@faceup/icons'
import { ulLock01 } from '@faceup/icons/ulLock01'
import { Avatar, Center, Stack } from '@mantine/core'
import type { ReactNode } from 'react'
import { Text } from '../CoreComponents'

type ContentNoPermissionProps = {
  title: ReactNode
  description: ReactNode
}

export const ContentNoPermission = (props: ContentNoPermissionProps) => {
  const { title, description } = props
  return (
    <Center mt='10rem'>
      <Stack spacing='2.5rem' align='center' w='442px'>
        <Avatar color='primary' size='5rem'>
          <UntitledIcon icon={ulLock01} />
        </Avatar>
        <Stack spacing='1rem'>
          <Text align='center' variant='headline' size='small'>
            {title}
          </Text>
          <Text align='center' size='large' color='text'>
            {description}
          </Text>
        </Stack>
      </Stack>
    </Center>
  )
}
