import type { UntitledIconData } from '../../UntitledIconData'

const ulRefreshCw01: UntitledIconData = {
  prefix: 'ul',
  name: 'refresh-cw-01',
  paths: [
    {
      shape:
        'M22 10C22 10 19.995 7.26822 18.3662 5.63824C16.7373 4.00827 14.4864 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.1031 21 19.5649 18.2543 20.6482 14.5M22 10V4M22 10H16',
    },
  ],
}

export default ulRefreshCw01
