import styled from '@emotion/styled'
import { Typography } from '@faceup/ui-base'
import { FormattedMessage, defineMessages } from '../TypedIntl'
import Auth from '../utils/auth'
import PageTemplateUnlogged from './PageTemplateUnlogged'
import underConstruction from './assets/under-construction-illustration.svg'

const { Title: AntTitle } = Typography

const messages = defineMessages({
  register: 'Administration.login.register',
  underConstructionTitle: 'Administration.underconstruction.title',
  underConstructionDescription: 'Administration.underconstruction.description',
})

const NotAvailable = () => (
  <PageTemplateUnlogged
    publicHeaderProps={{
      showLinkTo: Auth.getJwt() ? 'none' : 'register',
      isLanguageDropdownShown: false,
    }}
    isFooterShown
  >
    <UnderConstruction>
      <img src={underConstruction} alt='Under construction' />
      <Title>
        <FormattedMessage {...messages.underConstructionTitle} />
      </Title>
      <Text>
        <FormattedMessage {...messages.underConstructionDescription} />
      </Text>
    </UnderConstruction>
  </PageTemplateUnlogged>
)

const UnderConstruction = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const Title = styled(AntTitle)`
  text-align: center;
  margin-bottom: 1rem !important;
  margin-top: 2rem !important;
`

const Text = styled.div`
  text-align: center;
`

export default NotAvailable
