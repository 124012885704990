import { Radio, type RadioProps, Stack, Text } from '@mantine/core'
import { type ReactNode, type Ref, forwardRef } from 'react'

export type PhoneNumberTypeRadioButtonProps = RadioProps & {
  label: ReactNode
  description: ReactNode
}

export const PhoneNumberTypeRadioButton = forwardRef(
  (
    { label, description, ...props }: PhoneNumberTypeRadioButtonProps,
    ref: Ref<HTMLInputElement>
  ) => (
    <Radio
      sx={theme => ({
        cursor: 'pointer',
        padding: '10px 8px',
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: 6,
      })}
      label={
        <Stack mt={-4} spacing={0}>
          <Text weight={600}>{label}</Text>
          <Text>{description}</Text>
        </Stack>
      }
      ref={ref}
      {...props}
    />
  )
)
