import { UntitledIcon } from '@faceup/icons'
import { ulDotsVertical } from '@faceup/icons/ulDotsVertical'
import { Popover, type PopoverProps } from '@faceup/ui-base'
import { Menu, UnstyledButton } from '@mantine/core'
import type { ReactNode } from 'react'

type MoreOptionsAntProps = {
  isMantine?: false
} & PopoverProps

type MoreOptionsMantineProps = {
  isMantine: true
  children: ReactNode
}

type MoreOptionsProps = MoreOptionsAntProps | MoreOptionsMantineProps

const MoreOptions = ({ isMantine, children, ...props }: MoreOptionsProps) => {
  if (isMantine) {
    return (
      <Menu
        trigger='hover'
        styles={{
          item: {
            padding: '3px 7px!important',
          },
        }}
      >
        <Menu.Target>
          <UnstyledButton color='prussianBlue' data-onboarding='more-options-button'>
            <UntitledIcon icon={ulDotsVertical} />
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>{children}</Menu.Dropdown>
      </Menu>
    )
  }

  return (
    <Popover
      placement='bottomRight'
      trigger={['click']}
      arrow={{ pointAtCenter: true }}
      overlayInnerStyle={{ padding: 0, overflow: 'hidden' }}
      {...props}
    >
      <UnstyledButton color='prussianBlue' data-onboarding='more-options-button'>
        <UntitledIcon icon={ulDotsVertical} />
      </UnstyledButton>
    </Popover>
  )
}

MoreOptions.Divider = Menu.Divider
MoreOptions.Item = Menu.Item
MoreOptions.Label = Menu.Label

export default MoreOptions
