import { gql } from '@apollo/client'
import { Modal } from '@faceup/ui'
import { ExclamationCircleFilled, Space, Typography } from '@faceup/ui-base'
import { useContext, useEffect } from 'react'
import { ModalsContext } from '../Contexts/ModalsContext'
import { FormattedMessage, defineMessages } from '../TypedIntl'
import {
  type AccountVerificationModal_companyRegistration,
  type AccountVerificationModal_partnerInstitution,
  VerificationStatus,
} from '../__generated__/globalTypes'

const { Title } = Typography

export const accountVerificationModalFragments = {
  AccountVerificationModal_companyRegistration: gql`
    fragment AccountVerificationModal_companyRegistration on Company {
      id
      isVerified
    }
  `,

  AccountVerificationModal_partnerInstitution: gql`
    fragment AccountVerificationModal_partnerInstitution on PartnerInstitution {
      id
      verificationStatus
    }
  `,
}

const messages = defineMessages({
  title: 'Administration.verifyingAccount.title',
  description: 'Administration.verifyingAccount.description',
})

type Props = {
  isUserPartner: boolean
  mother: AccountVerificationModal_companyRegistration | null
  partnerInstitution: AccountVerificationModal_partnerInstitution | null
}

const AccountVerificationModal = ({ isUserPartner, mother, partnerInstitution }: Props) => {
  const { isInstitutionVerificationVisible, setIsInstitutionVerificationVisible } =
    useContext(ModalsContext)

  useEffect(() => {
    if (
      isUserPartner
        ? partnerInstitution?.verificationStatus === VerificationStatus.Pending
        : mother?.isVerified === false
    ) {
      setIsInstitutionVerificationVisible(true)
    }
  }, [
    mother?.isVerified,
    partnerInstitution?.verificationStatus,
    setIsInstitutionVerificationVisible,
    isUserPartner,
  ])

  return (
    <Modal opened={isInstitutionVerificationVisible} data-cy='AccountVerificationModal'>
      <Space
        style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
        align='center'
        direction='vertical'
        size='large'
      >
        <ExclamationCircleFilled style={{ color: '#f7990e', fontSize: '1.5rem' }} />
        <Space align='center' direction='vertical'>
          <Title level={4} style={{ textAlign: 'center' }}>
            <FormattedMessage {...messages.title} />
          </Title>
          <FormattedMessage {...messages.description} />
        </Space>
      </Space>
    </Modal>
  )
}

export default AccountVerificationModal
