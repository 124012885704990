import { getTranslateReportLanguage } from '@faceup/utils'
import { useLocalization } from '../contexts'

export const useTranslatableContentLanguages = (reportId: string) => {
  const { language } = useLocalization()

  const [savedSourceLanguage, savedTargetLanguage] = getTranslateReportLanguage(reportId)

  const sourceLanguage =
    (savedSourceLanguage === 'auto' ? language : savedSourceLanguage) ?? language
  const targetLanguage = savedTargetLanguage ?? language

  return { sourceLanguage, targetLanguage }
}
