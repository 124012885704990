import { USER_PASSWORD_MIN_LENGTH, getPasswordStrengthScore, removeDiacritics } from '@faceup/utils'

export type Strength = 'none' | 'veryWeak' | 'weak' | 'medium' | 'strong' | 'veryStrong'

export type PasswordStats = {
  score: number
  strength: Strength
  hasLower: boolean
  hasUpper: boolean
  hasNumber: boolean
  hasSymbol: boolean
  hasMinLength: boolean
  minLength: number
  passwordLength: number
}

const usePasswordStats = () => {
  const getPasswordStrength = (score: number): Strength => {
    switch (true) {
      case score >= 55:
        return 'veryStrong'
      case score >= 50:
        return 'strong'
      case score >= 40:
        return 'medium'
      case score >= 30:
        return 'weak'
      case score > 1:
        return 'veryWeak'
      default:
        return 'none'
    }
  }

  return {
    getPasswordStats: (password: string): PasswordStats => {
      const score = getPasswordStrengthScore(password)

      const normalizedPassword = removeDiacritics(password)
      return {
        score,
        strength: getPasswordStrength(score),
        hasLower: /[a-z]/g.test(normalizedPassword),
        hasUpper: /[A-Z]/g.test(normalizedPassword),
        hasNumber: /\d/g.test(normalizedPassword),
        hasSymbol: /\W/g.test(normalizedPassword),
        hasMinLength: password.length >= USER_PASSWORD_MIN_LENGTH,
        minLength: USER_PASSWORD_MIN_LENGTH,
        passwordLength: password.length,
      }
    },
  }
}

export default usePasswordStats
