import { Box } from '@mantine/core'
import type { ReactNode } from 'react'

type GridItemComponent = (props: GridItemProps) => ReactNode
type GridComponent = (props: GridProps) => ReactNode
type ComplexComponent = GridComponent & { Item: GridItemComponent }

type GridProps = {
  children?: ReactNode
  flexWrap?: 'wrap' | 'nowrap'
}

export const Grid: ComplexComponent = ({ children, flexWrap = 'nowrap' }: GridProps) => (
  <Box
    sx={{
      display: 'flex',
      flexWrap,
    }}
  >
    {children}
  </Box>
)

type Width = '25%' | '50%' | '75%' | '100%'

type GridItemProps = {
  children?: ReactNode
  width: Width
}

const GridItem = ({ children, width }: GridItemProps) => (
  <Box
    sx={{
      width,
      flexShrink: 0,
      padding: '12px',
    }}
  >
    {children}
  </Box>
)

Grid.Item = GridItem
