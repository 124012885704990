import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import moment from 'moment-timezone'
import { defineMessages } from '../../TypedIntl'
import logoFu from '../../assets/logo-fu.png'
import logoNntb from '../../assets/logo-nntb.png'
import { ThemeType, getTheme } from '../../locales'
import stopImg from './assets/stop.png'

const theme = getTheme()

export const messages = defineMessages({
  subtitle: 'Administration.settings.recovery.pdf.subtitle',
  created: 'Shared.global.created',
  recoveryKey: 'Administration.settings.recovery.title',
  description: 'Administration.settings.recovery.pdf.description',
  doNotShare: 'Shared.pdf.doNotShare',
})

Font.register({
  family: 'Inter',
  fonts: [
    { src: '/fonts/inter-regular.ttf', fontStyle: 'normal', fontWeight: 400 },
    { src: '/fonts/inter-semibold.ttf', fontStyle: 'normal', fontWeight: 600 },
  ],
})

const styles = StyleSheet.create({
  page: {
    padding: '60px 36px',
    color: '#062d46',
    fontSize: 14,
    lineHeight: 1.4,
    fontFamily: 'Inter',
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  link: { color: '#062d46' },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 52,
  },
  logo: {
    width: theme === ThemeType.NNTB ? 88 : 110,
    height: theme === ThemeType.NNTB ? 38 : 30,
    padding: 2,
  },
  date: { fontSize: 12 },
  title: { marginBottom: 24, fontSize: 20, fontFamily: 'Inter', fontWeight: 600 },
  subtitle: { marginBottom: 16, fontFamily: 'Inter', fontWeight: 600 },
  description: { marginBottom: 16 },
  inputWrapper: {
    backgroundColor: '#ebeef0',
    borderRadius: 8,
    padding: '14px 24px 20px',
    textAlign: 'center',
    marginTop: 24,
  },
  inputTitle: { marginBottom: 10, fontFamily: 'Inter', fontWeight: 600 },
  input: {
    backgroundColor: '#fff',
    borderRadius: 6,
    padding: '8px 12px',
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: 600,
  },
  information: {
    flexDirection: 'row',
    fontSize: 14,
    position: 'absolute',
    bottom: 36,
    left: 36,
    fontFamily: 'Inter',
    fontWeight: 600,
  },
  stop: { marginRight: 12, padding: 2, width: 26, height: 26 },
  stopText: { marginTop: 3 },
})

// There is a problem with context not being reachable from here
// This problem was solved by translating messages to string on top level
type Props = {
  recoveryKey: string | null
  name: string | null
  createdAt: Date | null
  translations: Record<keyof typeof messages, string>
}

const PDFRecoveryKey = ({ recoveryKey, name, createdAt, translations }: Props) => (
  <Document creator={theme} producer={theme}>
    <Page size='A4' style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.logo} src={theme === ThemeType.NNTB ? logoNntb : logoFu} />
        <Text style={styles.date}>
          {translations.created} {moment(createdAt).format('l')}
        </Text>
      </View>
      <View>
        <Text style={styles.title}>
          {translations.recoveryKey} - {name}
        </Text>

        <Text style={styles.subtitle}>{translations.subtitle}</Text>
        <Text style={styles.description}>{translations.description}</Text>
      </View>
      <View style={styles.inputWrapper}>
        <Text style={styles.inputTitle}>{translations.recoveryKey}</Text>
        <Text style={styles.input}>{recoveryKey}</Text>
      </View>

      <View style={styles.information}>
        <Image style={styles.stop} src={stopImg} />
        <Text style={styles.stopText}>{translations.doNotShare}</Text>
      </View>
    </Page>
  </Document>
)

export default PDFRecoveryKey
