import { Form, InputPhone } from '@faceup/ui'
import { Input } from '@faceup/ui-base'
import { USER_PASSWORD_MIN_LENGTH } from '@faceup/utils'
import PasswordInputWithPopover from '../../../../Components/PasswordInput'
import { sharedMessages } from '../../../../Shared/translations'
import { FormattedMessage, defineMessages } from '../../../../TypedIntl'
import usePhoneDefaultCountryCode from '../../../../hooks/usePhoneDefaultCountryCode'
import type { FormValue } from '../../../../types'
import { StepHeading } from './StepHeading'

const messages = defineMessages({
  phoneHint: 'Administration.registration.fillInfo.phone.hint',
  title: 'Administration.registration.fillInfo.step1.title',
  description: 'Administration.registration.fillInfo.step1.description',
})

type Step1Props = {
  name: FormValue
  setName: (value: FormValue) => void
  password: FormValue
  setPassword: (value: FormValue) => void
  phone: FormValue
  setPhone: (value: FormValue) => void
}

export const Step1 = (props: Step1Props) => {
  const { name, setName, password, setPassword, phone, setPhone } = props
  const { country: defaultCountry } = usePhoneDefaultCountryCode()

  return (
    <>
      <StepHeading
        title={<FormattedMessage {...messages.title} />}
        description={<FormattedMessage {...messages.description} />}
      />
      <Form.Item
        label={<FormattedMessage {...sharedMessages.fullNameLabel} />}
        withAsterisk
        errorMessage={name.error && <FormattedMessage {...sharedMessages.invalidNameError} />}
      >
        <Input
          autoComplete='name'
          value={name.value}
          onChange={({ target: { value } }) => setName({ value, error: false })}
          data-cy='FillInfoContent-userFullName'
        />
      </Form.Item>
      <Form.Item
        label={<FormattedMessage {...sharedMessages.passwordLabel} />}
        withAsterisk
        errorMessage={
          password.error && (
            <FormattedMessage
              {...sharedMessages.invalidPasswordError}
              values={{ minLength: USER_PASSWORD_MIN_LENGTH }}
            />
          )
        }
      >
        <PasswordInputWithPopover
          autoComplete='new-password'
          value={password.value}
          onChange={({ target: { value } }) => setPassword({ value, error: false })}
          data-cy='FillInfoContent-password'
        />
      </Form.Item>
      <Form.Item
        label={<FormattedMessage {...sharedMessages.phoneLabel} />}
        hint={<FormattedMessage {...messages.phoneHint} />}
        withAsterisk
        errorMessage={phone.error && <FormattedMessage {...sharedMessages.invalidPhoneError} />}
      >
        <InputPhone
          inputProps={{
            autoComplete: 'tel',
          }}
          country={defaultCountry}
          value={phone.value}
          onChange={value => setPhone({ value, error: false })}
          data-cy='FillInfoContent-phoneNumber'
        />
      </Form.Item>
    </>
  )
}
