import { gql, useQuery } from '@apollo/client'
import { ContentNoPermission, Text } from '@faceup/ui'
import { Skeleton, notification } from '@faceup/ui-base'
import { Institution, Plan } from '@faceup/utils'
import moment from 'moment-timezone'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import {
  ChargebeeSubscriptionStatus,
  type CompanyInvoicingQuery,
  type CompanyInvoicingQueryVariables,
  ContactIssue,
  InvoicedTo,
} from '../../__generated__/globalTypes'
import { useMotherId } from '../../hooks/useMotherId'
import { usePartner } from '../../hooks/usePartner'
import { AfterPurchase, AfterPurchaseFragments } from './CompanyInvoicing/AfterPurchase'
import { BeforePurchase } from './CompanyInvoicing/BeforePurchase'
import type { BillingFrequency } from './Components/BillingPeriodSwitcher'

const messages = defineMessages({
  noAccessToInvoicingTitle: 'Administration.settings.companyInvoicing.noAccessToInvoicingTitle',
  noAccessToInvoicingDescription:
    'Administration.settings.companyInvoicing.noAccessToInvoicingDescription',
})

export const getContactSalesIssue = (companyStatus: Plan) => {
  switch (companyStatus) {
    case Plan.Free:
      return ContactIssue.BuyMaxVariantFree
    case Plan.Basic:
      return ContactIssue.BuyMaxVariantBasic
    case Plan.Standard:
      return ContactIssue.BuyMaxVariantStandard
    case Plan.Premium:
      return ContactIssue.BuyMaxVariantPremium
    case Plan.Unregistered:
      return ContactIssue.BuyMaxVariantFree
  }
}

export const mapPriceDivider: Record<BillingFrequency, number> = {
  yearly: 12,
  quarterly: 3,
  monthly: 1,
}

export const CompanyInvoicingFragments = {
  CompanyInvoicingFragments_billing: gql`
    fragment CompanyInvoicingFragments_billing on Billing {
      id
      ...AfterPurchaseFragments_billing
      subscriptionEnd
      subscriptionStatus
      plan
    }
    ${AfterPurchaseFragments.AfterPurchaseFragments_billing}
  `,
}

export const query = {
  CompanyInvoicingQuery: gql`
    query CompanyInvoicingQuery($motherId: CompanyGlobalId!) {
      managerViewer {
        id
        mother(motherId: $motherId) {
          id
          ...AfterPurchaseFragments_mother
          type
          billing {
            id
            invoicedTo
            ...CompanyInvoicingFragments_billing
          }
          partner {
            id
            name
          }
        }
      }
      priceList {
        ...AfterPurchaseFragments_priceList
      }
    }

    ${AfterPurchaseFragments.AfterPurchaseFragments_billing}
    ${AfterPurchaseFragments.AfterPurchaseFragments_mother}
    ${AfterPurchaseFragments.AfterPurchaseFragments_priceList}
    ${CompanyInvoicingFragments.CompanyInvoicingFragments_billing}
  `,
}

type CompanyInvoicingProps = {
  institutionId?: string
  changePlanAction: () => void
}

const CompanyInvoicing = ({ institutionId, changePlanAction }: CompanyInvoicingProps) => {
  const { getMotherId } = useMotherId()
  const { formatMessage } = useIntl()
  const { isPartner } = usePartner()
  const motherId = institutionId ?? getMotherId()

  const { data } = useQuery<CompanyInvoicingQuery, CompanyInvoicingQueryVariables>(
    query.CompanyInvoicingQuery,
    {
      variables: {
        motherId,
      },
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
    }
  )

  const viewer = data?.managerViewer
  const mother = viewer?.mother
  const billing = mother?.billing

  const isBillingInvoicedToPartner =
    data?.managerViewer?.mother?.billing?.invoicedTo === InvoicedTo.Partner

  if (!mother) {
    return <Skeleton />
  }

  if (!isPartner && isBillingInvoicedToPartner) {
    return (
      <ContentNoPermission
        title={<FormattedMessage {...messages.noAccessToInvoicingTitle} />}
        description={
          <FormattedMessage
            {...messages.noAccessToInvoicingDescription}
            values={{
              partner: (
                <Text weight='bold' size='large' component='span'>
                  {mother.partner?.name}
                </Text>
              ),
            }}
          />
        }
      />
    )
  }

  const isTrial = billing?.subscriptionStatus === ChargebeeSubscriptionStatus.InTrial

  const subscriptionEnd = billing?.subscriptionEnd
  const today = moment()
  const isSubscriptionEnded =
    today.isAfter(subscriptionEnd) ||
    billing?.subscriptionStatus === ChargebeeSubscriptionStatus.Cancelled

  const allowBuyStandardPlanAsSchoolWithActiveFreePlan =
    mother.type === Institution.School &&
    billing?.plan === Plan.Free &&
    billing?.subscriptionStatus === ChargebeeSubscriptionStatus.Active

  if (isTrial || isSubscriptionEnded || allowBuyStandardPlanAsSchoolWithActiveFreePlan) {
    return <BeforePurchase institutionId={institutionId} />
  }

  if (data?.priceList) {
    return (
      <AfterPurchase
        mother={mother}
        priceList={data.priceList}
        changePlanAction={changePlanAction}
      />
    )
  }

  return null
}

export default CompanyInvoicing
