import { Stack, Text, Title } from '@mantine/core'
import type { ReactNode } from 'react'

type StepHeadingProps = {
  title: ReactNode
  description: ReactNode
}

export const StepHeading = (props: StepHeadingProps) => {
  const { title, description } = props
  return (
    <Stack mb='2rem'>
      <Title order={3} align='center'>
        {title}
      </Title>
      <Text align='center'>{description}</Text>
    </Stack>
  )
}
