import type { UntitledIconData } from '../../UntitledIconData'

const ulChevronSelectorVertical: UntitledIconData = {
  prefix: 'ul',
  name: 'chevron-selector-vertical',
  paths: [
    {
      shape: 'M7 15L12 20L17 15M7 9L12 4L17 9',
    },
  ],
}

export default ulChevronSelectorVertical
