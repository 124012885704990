import type { Language } from '@faceup/utils'
import { useCallback, useEffect, useState } from 'react'
import type { TypedMessageDescriptor } from '../TypedIntl'
import translations from '../locales'

/**
 *
 * @deprecated use useMockedMessages instead
 */
const useMockedReportMessages = (language: Language) => {
  const [mockedMessages, setMockedMessages] = useState<Record<string, string> | undefined>(
    undefined
  )
  useEffect(() => {
    if (mockedMessages === undefined) {
      void loadMockedMessages(language)
    }
  }, [mockedMessages, language])

  const loadMockedMessages = async (language: Language) => {
    const messages = await translations.messages[language]()

    setMockedMessages(messages.default)
  }

  const formatMessageWithConfigLanguage = useCallback(
    (message: TypedMessageDescriptor, args?: Record<string, string | null>): string => {
      const translatedMessage = mockedMessages?.[message.id] ?? message.id
      if (args) {
        // We simulate simply the intl.formatMessage function
        return Object.entries(args).reduce(
          (acc, [key, value]) => acc.replace(`{${key}}`, value ?? ''),
          translatedMessage
        )
      }
      return translatedMessage
    },
    [mockedMessages]
  )

  return { formatMessageWithConfigLanguage }
}

export default useMockedReportMessages
