import { Link } from '@faceup/router'
import { Space } from '@faceup/ui-base'
import { FormattedMessage, defineMessages } from '../TypedIntl'

const messages = defineMessages({
  register: 'Administration.login.register',
})

const RegisterLink = () => (
  <Space>
    <FormattedMessage
      {...messages.register}
      values={{
        register: register => <Link to={routes => routes.registerTrialRequest()}>{register}</Link>,
      }}
    />
  </Space>
)

export default RegisterLink
