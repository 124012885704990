import { PageContentSkeleton } from '@faceup/ui'
import { type ReactNode, Suspense } from 'react'

type PageSuspenseProps = { children: ReactNode }

const PageSuspense = (props: PageSuspenseProps) => (
  <Suspense fallback={<PageContentSkeleton />} {...props} />
)

export default PageSuspense
