import { MotherProvider } from '@faceup/institution'
import { FeatureFlagProvider } from '@faceup/ui'
import { DEFAULT_LANGUAGE, languageEnumToBasename } from '@faceup/utils'
import * as Sentry from '@sentry/react'
import { IntlProvider } from 'react-intl'
import { AppRouter } from './App/AppRouter'
import AppProviders from './AppProviders'
import { LanguageContext } from './Contexts/LanguageContext'
import LanguageProvider from './Contexts/LanguageProvider'
import ModalsProvider from './Contexts/ModalsProvider'
import CustomApolloProvider from './CustomApolloProvider'
import { ThemeContext, themes } from './ThemeContext'
import { getTheme } from './locales'

// TODO!!!
// if user is logged and domain is NNTB and their country is CZ/SK, redirect them to NNTB

const App = () => (
  <MotherProvider>
    <LanguageProvider>
      <LanguageContext.Consumer>
        {({ language, messages, direction, antLocale }) => (
          <IntlProvider
            locale={languageEnumToBasename(language ?? DEFAULT_LANGUAGE)}
            key={language}
            messages={messages}
            onError={() => {
              // Ignore errors
            }}
          >
            <ThemeContext.Provider value={themes[getTheme()]}>
              <CustomApolloProvider>
                <FeatureFlagProvider>
                  <AppProviders direction={direction} antLocale={antLocale}>
                    <ModalsProvider>
                      <AppRouter />
                    </ModalsProvider>
                  </AppProviders>
                </FeatureFlagProvider>
              </CustomApolloProvider>
            </ThemeContext.Provider>
          </IntlProvider>
        )}
      </LanguageContext.Consumer>
    </LanguageProvider>
  </MotherProvider>
)

export default Sentry.withProfiler(App)
