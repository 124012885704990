import { gql, useMutation } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulInfoCircle } from '@faceup/icons/ulInfoCircle'
import { useMotherId } from '@faceup/institution'
import { Button, FormItem, InputCopyable, Modal, ModalConfirm } from '@faceup/ui'
import { Alert, Flex, QRCode, type QRCodeProps, notification, useMessage } from '@faceup/ui-base'
import { ChannelStatus } from '@faceup/utils'
import { type ReactNode, useState } from 'react'
import { sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import type {
  PublishSurveyModalFragment,
  ToggleSurveyActivation,
  ToggleSurveyActivationVariables,
} from '../../../__generated__/globalTypes'
import useConfigForProject from '../../../hooks/useConfigForProject'
import { downloadImage } from '../../../utils/downloader'
import type { SurveyModalModes } from './SurveyNavigation'

const messages = defineMessages({
  publish: 'Administration.surveys.modal.publishTitle',
  publishButton: 'Administration.surveys.detail.publish',
  share: 'Administration.surveys.modal.shareTitle',
  shareInputLabel: 'Administration.surveys.modal.shareInputLabel',
  downloadQRCode: 'Administration.surveys.modal.downloadQRCode',
  publishAlert: 'Administration.surveys.modal.publishAlert',
})

const mutations = {
  ToggleSurveyActivation: gql`
    mutation ToggleSurveyActivation($input: ToggleSurveyActivationInput!) {
      toggleSurveyActivation(input: $input) {
        survey {
          id
          isEnabled
        }
      }
    }
  `,
}

export const PublishSurveyModalFragments = {
  PublishSurveyModalFragment: gql`
    fragment PublishSurveyModalFragment on ReportSource {
      id
      shortLink
      isEnabled
    }
  `,
}

type Config = Record<
  SurveyModalModes,
  {
    title: ReactNode
    alertText: ReactNode | null
    hasNoCancelButton: boolean
    onConfirm: (() => void) | undefined
  }
>

type PublishSurveyModalProps = {
  mode: SurveyModalModes
  onClose: () => void
  survey: PublishSurveyModalFragment
}

export const PublishSurveyModal = ({ mode, onClose, survey }: PublishSurveyModalProps) => {
  const { formatMessage } = useIntl()
  const message = useMessage()
  const { getMotherId } = useMotherId()
  const { generateSurveyUrl } = useConfigForProject()

  const [toggleSurveyActivation] = useMutation<
    ToggleSurveyActivation,
    ToggleSurveyActivationVariables
  >(mutations.ToggleSurveyActivation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: () => message.success(formatMessage(sharedMessages.savedMessage)),
    refetchQueries: ['SurveysTableQuery'],
  })

  const config: Config = {
    publish: {
      title: <FormattedMessage {...messages.publish} />,
      alertText: <FormattedMessage {...messages.publishAlert} />,
      hasNoCancelButton: false,
      onConfirm: async () => {
        await toggleSurveyActivation({
          variables: {
            input: {
              motherId: getMotherId(),
              reportSourceId: survey?.id ?? '',
              status: survey.isEnabled ? ChannelStatus.Disabled : ChannelStatus.Enabled,
            },
          },
        })
        onClose()
      },
    },
    share: {
      title: <FormattedMessage {...messages.share} />,
      alertText: null,
      hasNoCancelButton: true,
      onConfirm: undefined,
    },
  }

  if (!mode) {
    return null
  }

  const currentConfig = config[mode]

  const surveyUrl = generateSurveyUrl(survey?.shortLink ?? '')

  return mode === 'publish' ? (
    <ModalConfirm
      title={currentConfig.title}
      opened
      width={620}
      onClose={() => {
        onClose()
      }}
      hasNoCancelButton={currentConfig.hasNoCancelButton}
      onConfirm={currentConfig.onConfirm}
      confirmText={<FormattedMessage {...messages.publishButton} />}
      dataCy='publish-survey-modal'
    >
      <ModalChildren surveyUrl={surveyUrl} currentConfig={currentConfig} />
    </ModalConfirm>
  ) : (
    <Modal
      title={currentConfig.title}
      opened
      onClose={() => {
        onClose()
      }}
    >
      <ModalChildren surveyUrl={surveyUrl} currentConfig={currentConfig} />
    </Modal>
  )
}

const ModalChildren = ({
  surveyUrl,
  currentConfig,
}: {
  surveyUrl: string
  currentConfig: Config[keyof Config]
}) => {
  const [qRCodeType, setQRCodeType] = useState<QRCodeProps['type']>('canvas')

  const downloadQRCode = (format: 'png' | 'jpeg' | 'svg') => {
    if (format === 'svg') {
      setQRCodeType('svg')
      const svgElement = document
        .getElementById('share-survey-qr-code')
        ?.querySelector<SVGSVGElement>('svg')
      if (svgElement) {
        const svgData = new XMLSerializer().serializeToString(svgElement)
        const svgUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgData)}`
        downloadImage(svgUrl, 'qr-code.svg')
      }
    } else {
      setQRCodeType('canvas')
      const canvas = document
        .getElementById('share-survey-qr-code')
        ?.querySelector<HTMLCanvasElement>('canvas')
      const mimeType = format === 'png' ? 'image/png' : 'image/jpeg'
      const url = canvas?.toDataURL(mimeType)
      if (url) {
        downloadImage(url, `qr-code.${format}`)
      }
    }
  }

  return (
    <Flex vertical gap='middle'>
      <InputCopyable
        value={surveyUrl}
        buttonPosition='afterInput'
        label={<FormattedMessage {...messages.shareInputLabel} />}
        copyButtonDataCy='copy-survey-link'
      />
      <Flex gap='small' vertical>
        <FormItem label={<FormattedMessage {...messages.downloadQRCode} />}>
          <div id='share-survey-qr-code'>
            <QRCode
              type={qRCodeType}
              value={surveyUrl}
              style={{ display: 'none' }}
              color='black'
              bgColor='white'
            />
          </div>
          <Flex gap='small'>
            <Button variant='tertiary' onClick={() => downloadQRCode('jpeg')}>
              JPG
            </Button>
            <Button variant='tertiary' onClick={() => downloadQRCode('png')}>
              PNG
            </Button>
            <Button variant='tertiary' onClick={() => downloadQRCode('svg')}>
              SVG
            </Button>
          </Flex>
        </FormItem>
      </Flex>
      {currentConfig.alertText && (
        <Alert
          type='info'
          showIcon
          icon={<UntitledIcon icon={ulInfoCircle} />}
          message={currentConfig.alertText}
        />
      )}
    </Flex>
  )
}
