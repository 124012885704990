import { transitionDuration } from '@faceup/ui'
import { Box, Group, useMantineTheme } from '@mantine/core'

type Step = 1 | 2 | 3

type StepperProps = {
  step: Step
}

export const Stepper = (props: StepperProps) => {
  const { step } = props
  const theme = useMantineTheme()
  return (
    <Group grow w='100%'>
      {[...Array(3).keys()].map(i => (
        <Box
          key={i}
          sx={{
            height: '4px',
            width: '100%',
            borderRadius: '3px',
            backgroundColor: i < step ? theme.colors.primary[7] : theme.colors.prussianBlue[1],
            transition: `background-color ${transitionDuration}`,
          }}
        />
      ))}
    </Group>
  )
}
