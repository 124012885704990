import styled from '@emotion/styled'
import { Rate, Space, Typography } from '@faceup/ui-base'
import type { HTMLAttributes, ReactNode } from 'react'

type ReviewProps = {
  image: string
  name: ReactNode
  jobDescription: ReactNode
  text: ReactNode
} & HTMLAttributes<HTMLDivElement>

const Review = ({ image, name, jobDescription, text, ...props }: ReviewProps) => (
  <div {...props}>
    <Space direction='vertical' size={16}>
      <Space size={16}>
        <div>
          <img src={image} alt='' width={64} height={64} />
        </div>
        <Space direction='vertical' size={0}>
          <Name>{name}</Name>
          <JobDescription>{jobDescription}</JobDescription>
          <StyledRate disabled value={5} />
        </Space>
      </Space>
      <ReviewText>{text}</ReviewText>
    </Space>
  </div>
)

const Name = styled(Typography.Text)`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #00a2ff;
`
const JobDescription = styled(Typography.Text)`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #38576b;
  position: relative;
  top: -2px;
`

const StyledRate = styled(Rate)`
  line-height: 0;
  & li > div {
    line-height: 0;
  }
`

const ReviewText = styled(Typography.Text)`
  display: block;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #38576b;
`

export default Review
