import styled from '@emotion/styled'
import { Loader, type MantineSize } from '@mantine/core'

const LoadingSpinner = ({ size = 'lg' }: { size?: Extract<'lg' | 'sm', MantineSize> }) => (
  <SpinnerWrapper>
    <Loader size={size} />
  </SpinnerWrapper>
)

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default LoadingSpinner
