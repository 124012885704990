export const mockCredentialTableColumns = [
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Country',
    dataIndex: 'country',
  },
  {
    title: 'Plan',
    dataIndex: 'plan',
  },
]

/**
 * How to generate this table:
   ___________________________

  WITH
    managers_companies_map AS (
      SELECT
        managers.id AS manager_id,
        jsonb_array_elements(permissions.additional_data -> 'institution_ids'):: int AS company_id
      FROM
        managers
        JOIN user_keys ON user_keys.id = managers.keys_id
        JOIN permissions ON permissions.keys_id = user_keys.id
      WHERE
        permissions.type = 'InstitutionAccess'
    )
  SELECT
    DISTINCT ON (billings.plan, companies.country) managers.email,
    companies.name,
    billings.plan,
    companies.country
  FROM
    managers_companies_map
    JOIN managers ON managers_companies_map.manager_id = managers.id
    JOIN companies ON managers_companies_map.company_id = companies.id
    JOIN billings ON managers_companies_map.company_id = billings.mother_id
  WHERE
    companies.type = 'Company'
  ORDER BY
    billings.plan,
    companies.country;
 */

export const teachersMock = [
  {
    email: 'torsten_javurek@email.com',
    name: 'Vítek a Šlechta a.s.',
    plan: 'Free',
    country: 'Cz',
  },
  {
    email: 'vicente_price@email.com',
    name: 'King LLC',
    plan: 'Free',
    country: 'Us',
  },
  {
    email: 'ryder_hintz@email.com',
    name: 'Hansen LLC',
    plan: 'Free',
    country: 'Za',
  },
  {
    email: 'alojzije_tomaskova@email.com',
    name: 'Hanáková a.s.',
    plan: 'Standard',
    country: 'Cz',
  },
  {
    email: 'edgardo_williamson@email.com',
    name: 'Schulist, Daugherty and McLaughlin',
    plan: 'Standard',
    country: 'Us',
  },
  {
    email: 'eloise_leannon@email.com',
    name: 'Mohr, Ferry and Stokes',
    plan: 'Standard',
    country: 'Za',
  },
  {
    email: 'erna_bohacova@email.com',
    name: 'Urbánek a Valenta v.o.s.',
    plan: 'Unregistered',
    country: 'Cz',
  },
]

export const managersMock = [
  {
    email: 'rachel_barakova@email.com',
    name: 'Bradáčová v.o.s.',
    plan: 'Basic',
    country: 'Cz',
  },
  {
    email: 'mable_lakin@email.com',
    name: 'Hickle LLC',
    plan: 'Basic',
    country: 'Us',
  },
  {
    email: 'kathryne_quigley@email.com',
    name: 'Zieme - Reichel',
    plan: 'Basic',
    country: 'Za',
  },
  {
    email: 'detrich_mikova@email.com',
    name: 'Balog a Zajíc s.r.o.',
    plan: 'Free',
    country: 'Cz',
  },
  {
    email: 'katharina_rau@email.com',
    name: 'Medhurst - Rau',
    plan: 'Free',
    country: 'Us',
  },
  {
    email: 'adell_kuvalis@email.com',
    name: "Tromp, Lockman and D'Amore",
    plan: 'Free',
    country: 'Za',
  },
  {
    email: 'john.doe+premium_mother_user_2@faceup.com',
    name: 'PREMIUM COMPANY MOTHER',
    plan: 'Premium',
    country: 'Cz',
  },
  {
    email: 'arden_satterfield@email.com',
    name: 'Bernier - Quigley',
    plan: 'Premium',
    country: 'Us',
  },
  {
    email: 'rosie_daugherty@email.com',
    name: 'Farrell Group',
    plan: 'Premium',
    country: 'Za',
  },
  {
    email: 'zoroslava_musil@email.com',
    name: 'Havelková s.r.o.',
    plan: 'Standard',
    country: 'Cz',
  },
  {
    email: 'cara_robel@email.com',
    name: 'White, Hartmann and Ebert',
    plan: 'Standard',
    country: 'Us',
  },
  {
    email: 'amanda_green@email.com',
    name: 'Gusikowski, Aufderhar and Kunde',
    plan: 'Standard',
    country: 'Za',
  },
]
