import { Box } from '@mantine/core'
import EChartsReact from 'echarts-for-react'
import type { Colors } from '../../../hooks/charts/useColors'
import NoDataOverlay from '../../NoDataOverlay'

const defaultOption = {
  textStyle: {
    fontFamily: `Inter, sans-serif`,
  },
  tooltip: {
    trigger: 'item',
    axisPointer: {
      type: 'shadow',
    },
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  yAxis: {
    minInterval: 1,
  },
}
/**
 * Bar chart with category on X axis, Y axis step = 1, no stack
 */
export const BarChart = ({
  overlay,
  data,
  xAxisLabels,
  colors,
}: {
  overlay: boolean
  data: [string, unknown][]
  xAxisLabels?: string[]
  colors: Colors
}) => (
  <Box
    sx={{
      width: '100%',
      // For NoDataOverlay
      position: 'relative',
    }}
  >
    <NoDataOverlay visible={overlay} />
    <Box
      sx={{ width: '100%' }}
      component={EChartsReact}
      style={{ height: 250 }}
      option={{
        ...defaultOption,
        xAxis: {
          type: 'category',
          data: xAxisLabels ? xAxisLabels : data.map(([name]) => name),
          axisLabel: {
            rotate: 45,
            width: 150,
            overflow: 'truncate',
          },
        },
        series: {
          type: 'bar',
          cursor: 'default',
          data: data.map(([name, value]) => ({
            type: 'bar',
            value,
            itemStyle: {
              color: colors[name],
              borderRadius: 8,
            },
          })),
        },
      }}
    />
  </Box>
)
