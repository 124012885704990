import { Progress, type ProgressProps } from '@faceup/ui-base'
import type { PasswordStats } from '../hooks/usePasswordStats'

const getProgressData = ({ strength }: PasswordStats): Partial<ProgressProps> => {
  switch (strength) {
    case 'veryStrong':
      return { percent: 100, strokeColor: '#0CCE6B' }
    case 'strong':
      return { percent: 75, strokeColor: '#0CCE6B' }
    case 'medium':
      return { percent: 50, strokeColor: '#FD9F13' }
    case 'weak':
      return { percent: 25, strokeColor: '#EF4A45' }
    case 'veryWeak':
      return { percent: 10, strokeColor: '#EF4A45' }
    default:
      return { percent: 0, strokeColor: '#F5F5F5' }
  }
}

type PasswordStrengthMeterProps = {
  stats: PasswordStats
}

const PasswordStrengthMeter = ({ stats }: PasswordStrengthMeterProps) => {
  const progressData = getProgressData(stats)
  return <Progress {...progressData} showInfo={false} />
}

export default PasswordStrengthMeter
