import { Link } from '@faceup/router'
import { Button, Modal } from '@faceup/ui'
import { ExclamationCircleFilled, Space, Typography } from '@faceup/ui-base'
import { useState } from 'react'
import { FormattedMessage, defineMessages } from '../TypedIntl'
import { ContactIssue } from '../__generated__/globalTypes'
import ContactSalesModal from './ContactSalesModal'

const { Title } = Typography

const messages = defineMessages({
  action: 'Administration.trialEnded.action',
  contactSales: 'Administration.trialEnded.contactSales',
})

type ModalMessages = 'title' | 'description'

const messagesTrialEnded = defineMessages<ModalMessages>({
  title: 'Administration.trialEnded.title',
  description: 'Administration.trialEnded.description',
})

const messagesSubscriptionEnded = defineMessages<ModalMessages>({
  title: 'Administration.subscriptionEnded.title',
  description: 'Administration.subscriptionEnded.description',
})

type TrialEndedModalProps = {
  variant: 'trialEnded' | 'subscriptionEnded'
  visible: boolean
}

const SubscriptionEndedModal = ({ variant, visible }: TrialEndedModalProps) => {
  const [isContactSalesModalOpened, setIsContactSalesModalOpened] = useState<boolean>(false)
  const modalMessages = variant === 'trialEnded' ? messagesTrialEnded : messagesSubscriptionEnded

  return (
    <>
      <Modal opened={visible} title={<FormattedMessage {...modalMessages.title} />} width={520}>
        <Space
          style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
          align='center'
          direction='vertical'
          size='large'
        >
          <ExclamationCircleFilled style={{ color: '#ef4a45', fontSize: '1.5rem' }} />
          <Space align='center' direction='vertical'>
            <Title level={2}>
              <FormattedMessage {...modalMessages.title} />
            </Title>
            <FormattedMessage {...modalMessages.description} />
            <Button
              sx={{ textDecoration: 'underline' }}
              variant='text'
              onClick={() => {
                setIsContactSalesModalOpened(true)
              }}
            >
              <FormattedMessage {...messages.contactSales} />
            </Button>
          </Space>
          <Link to={routes => routes.settingsInvoicing()}>
            <Button>
              <FormattedMessage {...messages.action} />
            </Button>
          </Link>
        </Space>
      </Modal>
      <ContactSalesModal
        contactIssue={ContactIssue.ContactAfterTrial}
        onClose={() => setIsContactSalesModalOpened(false)}
        opened={isContactSalesModalOpened}
      />
    </>
  )
}

export default SubscriptionEndedModal
