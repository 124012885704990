import type { UntitledIconData } from '@faceup/icons'
import { ulCalendarPlus02 } from '@faceup/icons/ulCalendarPlus02'
import { ulMessageTextSquare02 } from '@faceup/icons/ulMessageTextSquare02'
import { ulPhoneIncoming01 } from '@faceup/icons/ulPhoneIncoming01'
import { ulSend01 } from '@faceup/icons/ulSend01'
import type { ModalProps } from '@faceup/ui'
import { type ReactNode, useEffect, useState } from 'react'
import { FormattedMessage, defineMessages } from '../TypedIntl'
import type { ContactIssue } from '../__generated__/globalTypes'
import useHubSpotChat from '../hooks/useHubSpotChat'
import ContactSalesModalByEmail from './ContactSalesModal/ContactSalesModalByEmail'
import ContactSalesModalByMeeting from './ContactSalesModal/ContactSalesModalByMeeting'
import ContactSalesModalByPhone from './ContactSalesModal/ContactSalesModalByPhone'
import ContactSalesModalOverview from './ContactSalesModal/ContactSalesModalOverview'

const messagesContentType = defineMessages<Exclude<ContactModalContent, 'overview'>>({
  chat: 'Administration.contactSalesModal.byChat.title',
  phone: 'Administration.contactSalesModal.byPhone.title',
  email: 'Administration.contactSalesModal.byEmail.title',
  meeting: 'Administration.contactSalesModal.byMeeting.title',
})

export type ContactModalContent = 'overview' | 'meeting' | 'phone' | 'email' | 'chat'

export type ContactVariant = {
  type: ContactModalContent
  label: ReactNode
  icon: UntitledIconData
  onClick: () => void
}

type ContactSalesModalProps = { contactIssue: ContactIssue; note?: string } & Required<
  Pick<ModalProps, 'opened' | 'onClose'>
>

const ContactSalesModal = ({ contactIssue, ...props }: ContactSalesModalProps) => {
  const [content, setContent] = useState<ContactModalContent>('overview')
  const { load: loadChat } = useHubSpotChat()

  useEffect(() => {
    if (!props.opened) {
      setContent('overview')
    }
  }, [props.opened])

  const contactVariants: ContactVariant[] = [
    {
      type: 'meeting',
      label: <FormattedMessage {...messagesContentType.meeting} />,
      icon: ulCalendarPlus02,
      onClick: () => setContent('meeting'),
    },
    {
      type: 'email',
      label: <FormattedMessage {...messagesContentType.email} />,
      icon: ulSend01,
      onClick: () => setContent('email'),
    },
    {
      type: 'phone',
      label: <FormattedMessage {...messagesContentType.phone} />,
      icon: ulPhoneIncoming01,
      onClick: () => setContent('phone'),
    },
    {
      type: 'chat',
      label: <FormattedMessage {...messagesContentType.chat} />,
      icon: ulMessageTextSquare02,
      onClick: () => {
        loadChat()
        props.onClose()
      },
    },
  ]

  switch (content) {
    case 'overview':
      return <ContactSalesModalOverview {...props} contactVariants={contactVariants} />
    case 'phone':
      return <ContactSalesModalByPhone {...props} contactIssue={contactIssue} />
    case 'email':
      return <ContactSalesModalByEmail {...props} contactIssue={contactIssue} />
    case 'meeting':
      return <ContactSalesModalByMeeting {...props} />
    default:
      return null
  }
}

export default ContactSalesModal
