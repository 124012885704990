import { useEffect } from 'react'

const useScript = (
  url: string,
  id: string,
  options: { onLoad?: GlobalEventHandlers['onload'] } = {}
) => {
  const { onLoad } = options
  useEffect(() => {
    const existingScript = document?.getElementById(id)

    if (existingScript) {
      return
    }

    const script = document.createElement('script')

    script.src = url
    script.id = id
    script.defer = true

    if (onLoad) {
      script.onload = onLoad
    }

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [id, onLoad, url])
}

export default useScript
