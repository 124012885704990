import { gql } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { usAlertCircle } from '@faceup/icons/usAlertCircle'
import { Space } from '@faceup/ui-base'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import type { PaymentNoticeMessage_company } from '../../__generated__/globalTypes'
import useSubscriptionDetail, {
  type SubscriptionDetail,
  SubscriptionDetailFragments,
} from '../../hooks/useSubscriptionDetail'

const messages = defineMessages({
  trialEndsIn: 'Administration.trial.endsIn',
  trialEnded: 'Administration.trial.ended',
  subscriptionEnded: 'Administration.paymentNotice.subscriptionEnded',
  subscriptionValidTo: 'Administration.paymentNotice.subscriptionValidTo',
})

export const PaymentNoticeMessageFragments = {
  PaymentNoticeMessage_company: gql`
    fragment PaymentNoticeMessage_company on Company {
      id

      ...SubscriptionDetail_company
    }

    ${SubscriptionDetailFragments.SubscriptionDetail_company}
  `,
}

type PaymentNoticeMessageProps = {
  institution: PaymentNoticeMessage_company
}

const PaymentNoticeMessage = ({ institution }: PaymentNoticeMessageProps) => {
  const getSubscriptionDetail = useSubscriptionDetail()
  const subscription = getSubscriptionDetail(institution)
  const { warningColor } = subscription

  return (
    <Space data-cy='PaymentNoticeMessage'>
      <UntitledIcon icon={usAlertCircle} color={warningColor} />
      <Message subscription={subscription} />
    </Space>
  )
}

const Message = ({
  subscription: { state, warningColor, humanizedSubscriptionEnd, humanizedSubscriptionEndDate },
}: {
  subscription: SubscriptionDetail
}) => {
  switch (state) {
    case 'trialExpired':
      return <FormattedMessage {...messages.trialEnded} />
    case 'cancelled':
      return <FormattedMessage {...messages.subscriptionEnded} />
    case 'inTrial':
      return (
        <span>
          <FormattedMessage {...messages.trialEndsIn} />{' '}
          <span style={{ color: warningColor }}>{humanizedSubscriptionEnd}</span>
        </span>
      )
    case 'nonRenewing':
      return (
        <span>
          <FormattedMessage {...messages.subscriptionValidTo} />{' '}
          <span>{humanizedSubscriptionEndDate}</span>
        </span>
      )
    case 'active':
    case 'future':
      return null
  }
}

export default PaymentNoticeMessage
