import { gql } from '@apollo/client'

const companyInvoicingPriceListInstitutionFragment = gql`
  fragment CompanyInvoicingPriceListInstitutionFragment on PriceListInstitution {
    members
    versions {
      version
      prices {
        currency
        billingPrice
        originalPrice
        discountTill
      }
    }
  }
`
// Moved to separate file otherwise there were some loading problems, maybe circular dependency?
export const CompanyInvoicingInstitutionAndSchoolFragment = gql`
  fragment CompanyInvoicingInstitutionAndSchoolFragment on PriceListInstitutionType {
    company {
      ...CompanyInvoicingPriceListInstitutionFragment
    }
    school {
      ...CompanyInvoicingPriceListInstitutionFragment
    }
  }

  ${companyInvoicingPriceListInstitutionFragment}
`
