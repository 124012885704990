import { FillInfoContent } from './FillInfoContent'
import TrialRequestContent from './TrialRequestContent'

type Props = {
  step: 'trial-request' | 'fill-info'
  isPurchase?: boolean
}

const Registration = ({ step, isPurchase }: Props) => (
  <>
    {step === 'trial-request' && <TrialRequestContent isPurchase={isPurchase} />}
    {step === 'fill-info' && <FillInfoContent />}
  </>
)

export default Registration
