import { useCallback, useState } from 'react'
import useScript from '../useScript'

/**
 * Adds chargebee script to page and, if all necessities are needed, initialises it.
 *
 * https://www.chargebee.com/checkout-portal-docs/settingup-api-ref.html
 */
const useChargebee = () => {
  const [chargebee, setChargebee] = useState<Chargebee | null>(null)

  if (window.Chargebee?.inited && !chargebee) {
    setChargebee(window.Chargebee.getInstance())
  }
  const initChargebee = useCallback(() => {
    if (!window.Chargebee || !import.meta.env.VITE_CHARGEBEE_SITE) {
      if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
        console.error(`chargebee not loaded`)
      }
      return
    }

    // if user navigates away the script is removed from DOM but `window.Chargebee` stays untouched
    if (!window.Chargebee?.inited) {
      window.Chargebee.init({
        site: import.meta.env.VITE_CHARGEBEE_SITE,
      })
    }

    setChargebee(window.Chargebee.getInstance())
  }, [])

  useScript('https://js.chargebee.com/v2/chargebee.js', 'chargebee', { onLoad: initChargebee })
  return chargebee
}

export default useChargebee
