import { gql } from '@apollo/client'
import styled from '@emotion/styled'
import { Paper, Table, Tooltip, createColumns, useReactTable } from '@faceup/ui'
import { Typography } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import type { AnswersTable_submissionStatistics } from '../../../__generated__/globalTypes'
import { ucClipboard } from '../../../customIcons/ucClipboard'

const messages = defineMessages({
  answerColumnLabel: 'Administration.surveys.submissions.statistics.answers',
  answerCountLabel: 'Administration.surveys.submissions.statistics.answersCount',
  answerShareLabel: 'Administration.surveys.submissions.statistics.answersShare',
  xAnswers: 'Administration.surveys.submissions.statistics.xAnswers',
  emptyLabel: 'Administration.surveys.submission.statistics.table.emptyLabel',
  emptyDescription: 'Administration.surveys.submission.statistics.table.emptyDescription',
})

export const AnswersTableFragments = {
  AnswersTable_submissionStatistics: gql`
    fragment AnswersTable_submissionStatistics on AnswersStatistics {
      answersCount
      statistics {
        label
        count
        relativeFrequency
      }
    }
  `,
}

type SurveyTableProps = {
  label: ReactNode
} & AnswersTable_submissionStatistics

export const AnswersTable = ({ label, statistics, answersCount }: SurveyTableProps) => {
  const { formatMessage } = useIntl()
  const table = useReactTable({
    columns: createColumns<SurveyTableProps['statistics'][number]>({
      data: [
        {
          id: 'value',
          header: formatMessage(messages.answerColumnLabel),
          cell: ({ cell }) => (
            <Tooltip placement='topLeft' title={cell.row.original.label}>
              {cell.row.original.label}
            </Tooltip>
          ),
          size: 50,
        },
        {
          id: 'count',
          header: formatMessage(messages.answerCountLabel),
          cell: ({ cell }) => cell.row.original.count,
          size: 20,
        },
        {
          id: 'share',
          header: formatMessage(messages.answerShareLabel),
          cell: ({ cell }) => `${cell.row.original.relativeFrequency * 100} %`,
          size: 20,
        },
      ],
      actions: [],
    }),
    data: statistics,
  })

  return (
    <Paper
      sx={{
        height: '330px',
      }}
    >
      <PaddingWrapper>
        <div>
          <Typography.Title level={5}>{label}</Typography.Title>
          <Typography.Text type='secondary' strong>
            <FormattedMessage {...messages.xAnswers} values={{ count: answersCount }} />
          </Typography.Text>
        </div>
        <OverflowWrapper>
          <Table
            table={table}
            noData={{
              icon: ucClipboard,
              label: <FormattedMessage {...messages.emptyLabel} />,
              description: <FormattedMessage {...messages.emptyDescription} />,
            }}
          />
        </OverflowWrapper>
      </PaddingWrapper>
    </Paper>
  )
}

const OverflowWrapper = styled.div`
  max-height: 210px;
  overflow-y: auto;
`

const PaddingWrapper = styled.div`
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: column;
`
