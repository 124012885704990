import { useContext } from 'react'
import { GeoDataContext } from '../Contexts/GeoDataProvider'

const usePhoneDefaultCountryCode = () => {
  const { callingCode, country } = useContext(GeoDataContext)

  return { code: callingCode, country }
}

export default usePhoneDefaultCountryCode
