import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulChevronSelectorVertical } from '@faceup/icons/ulChevronSelectorVertical'
import {
  BaseComponent,
  Paper,
  Text as UiText,
  transitionDuration,
  useThemeColors,
} from '@faceup/ui'
import { Box, Flex, Group, Text } from '@mantine/core'
import { type ReactNode, forwardRef, useMemo } from 'react'

type DragListItemProps = {
  isFocused: boolean
  icon?: UntitledIconData
  label: ReactNode
  isDraggable: boolean
  isClickable?: boolean
  onClick?: () => void
  children?: ReactNode
  secondary?: ReactNode
  identifier: string
  disabled?: boolean
}

export const DragListItem = forwardRef<HTMLDivElement, DragListItemProps>((props, ref) => {
  const {
    isFocused,
    icon,
    label,
    isDraggable,
    isClickable,
    onClick,
    children,
    secondary,
    identifier,
    disabled = false,
  } = props
  const { getColorFromTheme } = useThemeColors()
  const getCursor: () => string = () => {
    if (isFocused) {
      return 'default'
    }
    if (isClickable || onClick) {
      return 'pointer'
    }
    return 'default'
  }

  return (
    <Paper
      ref={ref}
      className={identifier ? `${identifier}-item` : undefined}
      outline='dark'
      radius='6px'
      sx={{
        padding: 0,
        marginBlock: '12px',
      }}
    >
      <ListItemContent isFocused={isFocused} disabled={disabled}>
        <Flex sx={{ width: '100%' }}>
          {isDraggable && (
            <Flex
              className={`dragger draggable ${identifier}`}
              sx={{
                height: '100%',
                paddingInline: '8px',
                borderRight: `1px solid ${getColorFromTheme('dark.20')}`,
                alignItems: 'center',
                cursor: 'grab',
                transition: `color ${transitionDuration}`,
              }}
            >
              <UntitledIcon icon={ulChevronSelectorVertical} size={20} />
            </Flex>
          )}
          <Flex
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px 16px',
              width: '100%',
              minWidth: 0,
              cursor: getCursor(),
            }}
            onClick={onClick}
          >
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Flex
                justify='space-between'
                align='center'
                sx={{
                  width: '100%',
                }}
              >
                <Text
                  color={isFocused ? 'primary' : undefined}
                  sx={{
                    flex: '1 0!important',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Flex gap='8px' align='center'>
                    {icon && (
                      <Box sx={{ flexShrink: 0 }}>
                        <UntitledIcon icon={icon} color={getColorFromTheme('dark.50')} size={20} />
                      </Box>
                    )}
                    <UiText variant='title' size='small' color={disabled ? 'dark.50' : undefined}>
                      {label}
                    </UiText>
                  </Flex>
                </Text>
                <Group
                  className='secondaryContent'
                  spacing={0}
                  sx={{
                    cursor: 'initial',
                    color: getColorFromTheme('text'),
                    transition: `color ${transitionDuration}`,
                    '& svg': {
                      color: getColorFromTheme('dark.50'),
                      transition: `color ${transitionDuration}`,
                    },
                    '& .mantine-UnstyledButton-root[disabled]': {
                      color: getColorFromTheme('dark.20'),
                      '& svg': {
                        color: getColorFromTheme('dark.20'),
                        transition: `color ${transitionDuration}`,
                      },
                    },
                  }}
                  onClick={e => e.stopPropagation()}
                >
                  {secondary}
                </Group>
              </Flex>
              {isFocused && children}
            </Box>
          </Flex>
        </Flex>
      </ListItemContent>
    </Paper>
  )
})

type ListItemContentProps = {
  isFocused: boolean
  children: ReactNode
  disabled: boolean
}

const ListItemContent = ({ isFocused, children, disabled }: ListItemContentProps) => {
  const backgroundColor = useMemo(() => {
    if (isFocused) {
      return 'dark.4'
    }
    if (disabled) {
      return 'dark.4'
    }
    return 'white'
  }, [isFocused, disabled])

  return (
    <BaseComponent
      sx={({ getColorFromTheme }) => ({
        width: '100%',
        background: getColorFromTheme(backgroundColor),
        transition: `all ${transitionDuration}`,
        minHeight: '50px',
        display: 'flex',
        alignItems: 'stretch',
        height: '100%',
        '&:hover': {
          background: isFocused ? undefined : getColorFromTheme('dark.4'),

          '& .dragger': {
            color: getColorFromTheme('dark.100'),
          },
        },
        '& .dragger': {
          color: getColorFromTheme('dark.50'),
        },

        '& .secondaryContent .mantine-UnstyledButton-root:not([disabled])': {
          color: getColorFromTheme('dark.50'),
          '& svg': {
            color: getColorFromTheme('dark.50'),
          },
        },
      })}
    >
      {children}
    </BaseComponent>
  )
}
