import { createContext } from 'react'
import { ThemeType } from './locales'

type ThemeContent = {
  project: ThemeType
}

type Theme = {
  [ThemeType.NNTB]: ThemeContent
  [ThemeType.FaceUp]: ThemeContent
}

export const themes: Theme = {
  [ThemeType.NNTB]: {
    project: ThemeType.NNTB,
  },
  [ThemeType.FaceUp]: {
    project: ThemeType.FaceUp,
  },
}

export const ThemeContext = createContext(themes[ThemeType.NNTB])
