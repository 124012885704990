import { gql, useMutation } from '@apollo/client'
import { notification } from '@faceup/ui-base'
import { useCallback, useEffect } from 'react'
import { sharedMessages } from '../../Shared/translations'
import { useIntl } from '../../TypedIntl'
import type {
  CreatePartnerPortalSessionMutation,
  CreatePartnerPortalSessionMutationVariables,
  CreatePortalSessionMutation,
  CreatePortalSessionMutationVariables,
} from '../../__generated__/globalTypes'
import useChargebee from './useChargebee'

const mutation = {
  CreatePortalSessionMutation: gql`
    mutation CreatePortalSessionMutation($input: CreatePortalSessionInput!) {
      createPortalSession(input: $input) {
        portalSession {
          id
          token
        }
      }
    }
  `,
  CreatePartnerPortalSessionMutation: gql`
    mutation CreatePartnerPortalSessionMutation($input: CreatePartnerPortalSessionInput!) {
      createPartnerPortalSession(input: $input) {
        portalSession {
          id
          token
        }
      }
    }
  `,
}

type Parameters =
  | {
      motherId: string
      partnerId?: never
    }
  | {
      motherId?: never
      partnerId: string
    }

/**
 * Creates chargebee portal session, so we can use chargebee frontend library to show data.
 */
const useChargebeePortal = ({ motherId, partnerId }: Parameters) => {
  const chargebee = useChargebee()
  const { formatMessage } = useIntl()

  const [createPortalSession] = useMutation<
    CreatePortalSessionMutation,
    CreatePortalSessionMutationVariables
  >(mutation.CreatePortalSessionMutation, {
    variables: {
      input: {
        motherId: motherId ?? '',
      },
    },
    // https://support.chargebee.com/support/solutions/articles/247842-error-session-expired
    // not sure, whether the session will be valid after 1 hour or we need to refresh manually
    // onCompleted: data => chargebee?.setPortalSession(data?.createPortalSession?.portalSession),
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const [createPartnerPortalSession] = useMutation<
    CreatePartnerPortalSessionMutation,
    CreatePartnerPortalSessionMutationVariables
  >(mutation.CreatePartnerPortalSessionMutation, {
    variables: {
      input: {
        partnerId: partnerId ?? '',
      },
    },
    // https://support.chargebee.com/support/solutions/articles/247842-error-session-expired
    // not sure, whether the session will be valid after 1 hour or we need to refresh manually
    // onCompleted: data => chargebee?.setPortalSession(data?.createPortalSession?.portalSession),
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const createPortal = useCallback(() => {
    // `chargebee.setPortalSession()` won't tell us, if the session is created and valid or not, that means we have to create a session on the page load
    // possible fix: set portal session on button click and wait few hundred milliseconds and then open portal
    // if an error occurs, chargebee can automatically refresh the session (only after next button click - there is no error handler)
    chargebee?.setPortalSession(async () => {
      if (motherId) {
        return (await createPortalSession()).data?.createPortalSession?.portalSession
      }
      if (partnerId) {
        return (await createPartnerPortalSession()).data?.createPartnerPortalSession?.portalSession
      }
      return null
    })
  }, [chargebee, createPortalSession, createPartnerPortalSession, motherId, partnerId])

  useEffect(() => {
    createPortal()

    return () => {
      chargebee?.logout()
    }
  }, [chargebee, createPortal])

  return {
    isPortalAvailable: Boolean(chargebee),

    openManageBilling: useCallback(
      ({ onClose }: { onClose?: () => void }) =>
        chargebee?.createChargebeePortal()?.open({
          close: () => onClose?.(),
        }),
      [chargebee]
    ),

    openBillingHistory: useCallback(
      () =>
        chargebee?.createChargebeePortal()?.openSection(
          {
            // chargebee is loaded otherwise nothing works
            // biome-ignore lint/style/noNonNullAssertion:
            sectionType: window.Chargebee!.getPortalSections().BILLING_HISTORY,
          },
          {}
        ),
      [chargebee]
    ),
    openAddress: useCallback(
      ({ onClose, visit }: { onClose?: () => void; visit?: (data: string) => void }) =>
        chargebee?.createChargebeePortal()?.openSection(
          {
            // biome-ignore lint/style/noNonNullAssertion:
            sectionType: window.Chargebee!.getPortalSections().ADDRESS,
          },
          {
            close: () => onClose?.(),
            visit: (data: string) => visit?.(data),
          }
        ),
      [chargebee]
    ),
    openSubscriptionDetails: useCallback(
      (
        { subscriptionId }: { subscriptionId?: string },
        callbacks: {
          close: () => void
          visit: (data: string) => void
        }
      ) => {
        chargebee?.createChargebeePortal()?.openSection(
          {
            // chargebee is loaded otherwise nothing works
            // biome-ignore lint/style/noNonNullAssertion:
            sectionType: window.Chargebee!.getPortalSections().SUBSCRIPTION_DETAILS,
            params: { subscriptionId },
          },
          callbacks
        )
      },
      [chargebee]
    ),
  }
}

export default useChargebeePortal
