import { gql } from '@apollo/client'
import { Card, Grid } from '@faceup/ui'
import { FormItemType } from '@faceup/utils'
import { FormattedMessage, defineMessages } from '../../../TypedIntl'
import type { SubmissionStatistics_reportSource } from '../../../__generated__/globalTypes'
import { AnswersChart, AnswersChartFragments } from './AnswersChart'
import { AnswersTable, AnswersTableFragments } from './AnswersTable'

const messages = defineMessages({
  title: 'Administration.surveys.submissions.statistics.title',
})

type SubmissionSummaryConfig = SubmissionStatistics_reportSource['config']
type SubmissionSummaryFormItems = Extract<
  SubmissionSummaryConfig,
  { __typename: 'SurveyChannelConfiguration' }
>['formItems']

export const SubmissionsStatisticsFragments = {
  SubmissionStatistics_reportSource: gql`
    fragment SubmissionStatistics_reportSource on ReportSource {
      id
      name
      config {
        ... on SurveyChannelConfiguration {
          formItems {
            id
            order
            type
            labelTranslations {
              translation
            }
            answersStatistics {
              ...AnswersTable_submissionStatistics
              ...AnswersChart_submissionStatistics
              answersCount
              statistics {
                label
                count
                relativeFrequency
              }
            }
          }
        }
      }
    }
    ${AnswersTableFragments.AnswersTable_submissionStatistics}
    ${AnswersChartFragments.AnswersChart_submissionStatistics}
  `,
}

export const SubmissionsStatistics = ({ data }: { data: SubmissionStatistics_reportSource }) => {
  const questions =
    data?.config?.__typename === 'SurveyChannelConfiguration' ? data?.config?.formItems ?? [] : []

  return (
    <Card title={<FormattedMessage {...messages.title} />}>
      <Grid flexWrap='wrap'>
        {questions.map(question => (
          <Grid.Item width='50%' key={question.id}>
            <Question formItem={question} />
          </Grid.Item>
        ))}
      </Grid>
    </Card>
  )
}

const Question = ({ formItem }: { formItem: SubmissionSummaryFormItems[number] }) => {
  const data = formItem.answersStatistics.statistics
  const label = formItem.labelTranslations[0]?.translation
  const answersStatistics = formItem.answersStatistics

  return (data && formItem.type === FormItemType.Select) ||
    formItem.type === FormItemType.MultiSelect ? (
    <AnswersChart label={label} {...answersStatistics} />
  ) : (
    <AnswersTable label={label} {...answersStatistics} />
  )
}
