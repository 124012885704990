import { useEffect, useState } from 'react'

const breakpoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
}

const useLayout = () => {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    const onResizeHandler = () => setWidth(window.innerWidth)
    window.addEventListener('load', onResizeHandler)
    window.addEventListener('resize', onResizeHandler)
    return () => {
      window.removeEventListener('load', onResizeHandler)
      window.removeEventListener('resize', onResizeHandler)
    }
  }, [])

  return {
    isXsUp: breakpoints.xs < width,
    isSmUp: breakpoints.sm < width,
    isMdUp: breakpoints.md < width,
    isLgUp: breakpoints.lg < width,
    isXlUp: breakpoints.xl < width,
    isXxlUp: breakpoints.xxl < width,
  }
}

export default useLayout
