import { decryptPrivateKey, prehashPassword } from '@faceup/crypto'
import { TokenType } from '@faceup/utils'

export type ProcessMigrationToSSOPayload = {
  password: string
  salt: string
  privateKey: string
  nonce: string
}

export const processMigrationToSSO = async ({
  password,
  nonce,
  privateKey,
  salt,
}: ProcessMigrationToSSOPayload) => {
  const { passwordKey } = await prehashPassword({ password, salt })
  const privateKeyPayload = await decryptPrivateKey(privateKey, nonce, passwordKey)

  if (typeof privateKeyPayload === 'string') {
    return privateKeyPayload
  }

  return [
    {
      tokenType: TokenType.MigrateToSSO as const,
      privateKey: privateKeyPayload.privateKey,
    },
    null,
  ] as const
}
