import { Card, type CardProps, useTabKeyBlocker } from '@faceup/ui'
import { Box, Overlay } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useRef } from 'react'
import { UpsellIcon } from './UpsellIcon'
import { UpsellModal } from './UpsellModal'
import type { UpsellData } from './upsellData'

type UpsellCardProps = {
  upsell: UpsellData
} & CardProps

export const UpsellCard = ({ upsell, ...props }: UpsellCardProps) => {
  const [opened, { open, close }] = useDisclosure(false)
  const refContent = useRef<HTMLDivElement>(null)
  const refExtra = useRef<HTMLDivElement>(null)
  useTabKeyBlocker(upsell.showUpsell ? [refContent, refExtra] : [])
  return (
    <>
      <Card
        {...props}
        extra={
          props.extra && (
            <Box sx={{ display: 'flex' }} ref={refExtra}>
              {props.extra}
            </Box>
          )
        }
        title={
          <>
            {props.title} {upsell.showUpsell && <UpsellIcon />}
          </>
        }
      >
        {props.children && (
          <>
            {upsell.showUpsell && <Overlay onClick={open} opacity={0} />}
            <Box ref={refContent}>{props.children}</Box>
          </>
        )}
      </Card>
      <UpsellModal isOpened={opened} onClose={close} upsell={upsell} />
    </>
  )
}
