import { gql, useMutation } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulEye } from '@faceup/icons/ulEye'
import { ulEyeOff } from '@faceup/icons/ulEyeOff'
import { ulTrash01 } from '@faceup/icons/ulTrash01'
import { useMotherId } from '@faceup/institution'
import { Modal, Table, Text, createColumns, useReactTable } from '@faceup/ui'
import { Button, Flex, Menu, Space, Tooltip, notification, useModal } from '@faceup/ui-base'
import { FormItemType } from '@faceup/utils'
import moment from 'moment-timezone'
import { type Dispatch, useMemo, useState } from 'react'
import MoreOptions from '../../../Components/MoreOptions'
import { sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import type {
  DeleteSurveySubmission,
  DeleteSurveySubmissionVariables,
  SubmissionDetails_reportSource,
  ToggleSurveySubmissionVisibility,
  ToggleSurveySubmissionVisibilityVariables,
} from '../../../__generated__/globalTypes'
import { ucClipboard } from '../../../customIcons/ucClipboard'
import { Question } from './Question'

const messages = defineMessages({
  delete: 'Administration.surveys.options.delete',
  viewDetails: 'Administration.surveys.options.viewDetails',
  hide: 'Administration.surveys.options.hide',
  show: 'Administration.surveys.options.show',
  submissionNumber: 'Administration.surveys.submissions.submissionNumber',
  submittedNumber: 'Administration.surveys.submissionsDetail.submittedNumber',
  submittedAt: 'Administration.surveys.submissionsDetail.submittedAt',
  emptyLabel: 'Administration.surveys.submissionsDetail.emptyLabel',
  emptyDescription: 'Administration.surveys.submissionsDetail.emptyDescription',
  deleteSubmissionTitle: 'Administration.submissions.delete.confirmationModal.title',
  deleteSubmissionContent: 'Administration.submissions.delete.confirmationModal.content',
  deletedSubmission: 'Administration.submissions.deleted',
  hideSubmissionTitle: 'Administration.submissions.hide.confirmationModal.title',
  hideSubmissionContent: 'Administration.submissions.hide.confirmationModal.content',
  hiddenSubmission: 'Administration.submissions.hidden',
  showSubmissionTitle: 'Administration.surveys.show.confirmationModal.title',
  showSubmissionContent: 'Administration.surveys.show.confirmationModal.content',
})

export const SubmissionDetailsFragments = {
  SubmissionDetails_reportSource: gql`
    fragment SubmissionDetails_reportSource on ReportSource {
      id
      config {
        ... on SurveyChannelConfiguration {
          formItems {
            id
            options {
              id
              order
              labelTranslations {
                translation
                language
              }
            }
            type
            labelTranslations {
              translation
              language
            }
          }
        }
      }
      allCases: cases(includeHiddenAndDeleted: true) {
        ... on Submission {
          id
          submittedAt
          isHidden
          isDeleted
          answers {
            id
            values
            formItem {
              id
            }
          }
        }
      }
    }
  `,
}

const mutations = {
  DeleteSurveySubmission: gql`
    mutation DeleteSurveySubmission($input: DeleteSurveySubmissionInput!) {
      deleteSurveySubmission(input: $input) {
        channel {
          id
          cases {
            ... on Submission {
              id
              isDeleted
            }
          }
        }
      }
    }
  `,
  ToggleSurveySubmissionVisibility: gql`
    mutation ToggleSurveySubmissionVisibility($input: ToggleSurveySubmissionVisibilityInput!) {
      toggleSurveySubmissionVisibility(input: $input) {
        submission {
          id
          isHidden
        }
      }
    }
  `,
}

type Submission = SubmissionDetails_reportSource['allCases'][number]
type SubmissionWithOrder = Submission & { order: number }

export const SubmissionsDetails = ({
  data,
  refetch,
}: {
  data: SubmissionDetails_reportSource
  refetch: () => void // need to refetch the data for the other tab
}) => {
  const [selectedSubmissionId, setSelectedSubmissionId] = useState<
    SubmissionWithOrder['id'] | null
  >()
  const { formatMessage } = useIntl()

  const submissionsWithOrders = useMemo(() => {
    const submissions = data?.allCases ?? []
    return submissions.map((submission, index) => ({
      ...submission,
      order: index + 1,
    }))
  }, [data?.allCases])

  const selectedSubmission = submissionsWithOrders.find(
    submission => submission.id === selectedSubmissionId
  )

  const columns = createColumns<SubmissionWithOrder>({
    data: [
      {
        id: 'submissionNumber',
        header: formatMessage(messages.submittedNumber),
        size: 400,
        cell: ({ cell }) => (
          <Space>
            <Text color={cell.row.original?.isDeleted ? 'dark.50' : undefined}>
              {cell.row.original?.order}
            </Text>
            {cell.row.original?.isDeleted ? (
              <Tooltip title={<FormattedMessage {...messages.deletedSubmission} />}>
                <UntitledIcon icon={ulTrash01} color='#ef4a45' />
              </Tooltip>
            ) : (
              cell.row.original?.isHidden && (
                <Tooltip title={<FormattedMessage {...messages.hiddenSubmission} />}>
                  <UntitledIcon icon={ulEyeOff} />
                </Tooltip>
              )
            )}
          </Space>
        ),
      },
      {
        id: 'submittedAt',
        header: formatMessage(messages.submittedAt),
        size: 400,
        cell: ({ cell }) => (
          <Text color={cell.row.original?.isDeleted ? 'dark.50' : undefined}>
            {moment(cell.row.original?.submittedAt).format('LLL')}
          </Text>
        ),
      },
    ],
    actions: [
      {
        id: 'options',
        size: 16,
        cell: props =>
          !props.cell.row.original?.isDeleted && (
            <CellOptions
              {...props}
              channel={data}
              selectedSubmission={selectedSubmission}
              setSelectedSubmissionId={setSelectedSubmissionId}
              refetch={refetch}
            />
          ),
      },
    ],
  })

  const table = useReactTable({
    columns,
    data: submissionsWithOrders,
  })

  return (
    <Table
      table={table}
      onRowClick={submission => !submission.isDeleted && setSelectedSubmissionId(submission.id)}
      noData={{
        icon: ucClipboard,
        label: <FormattedMessage {...messages.emptyLabel} />,
        description: <FormattedMessage {...messages.emptyDescription} />,
      }}
    />
  )
}

const menuOptions = {
  viewItem: 'viewItem',
  toggleVisibilityItem: 'toggleVisibilityItem',
  deleteItem: 'deleteItem',
} as const

type ItemType = keyof typeof menuOptions

const CellOptions = ({
  cell: {
    row: { original: submission },
  },
  channel,
  selectedSubmission,
  setSelectedSubmissionId,
  refetch,
}: {
  cell: { row: { original: SubmissionWithOrder } }
  channel: SubmissionDetails_reportSource
  selectedSubmission: SubmissionWithOrder | null | undefined
  setSelectedSubmissionId: Dispatch<SubmissionWithOrder['id'] | null>
  refetch: () => void
}) => {
  const modal = useModal()
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()

  const formItems =
    channel.config?.__typename === 'SurveyChannelConfiguration' ? channel.config.formItems : []

  const [deleteSurveySubmission] = useMutation<
    DeleteSurveySubmission,
    DeleteSurveySubmissionVariables
  >(mutations.DeleteSurveySubmission, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: () => {
      refetch()
    },
  })

  const [toggleSurveySubmissionVisibility] = useMutation<
    ToggleSurveySubmissionVisibility,
    ToggleSurveySubmissionVisibilityVariables
  >(mutations.ToggleSurveySubmissionVisibility, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: () => {
      refetch()
    },
  })

  const confirmToggleVisibility = (submission: SubmissionWithOrder) => {
    const toggleVisibilityMessages = {
      isVisible: {
        title: messages.hideSubmissionTitle,
        content: messages.hideSubmissionContent,
      },
      isHidden: {
        title: messages.showSubmissionTitle,
        content: messages.showSubmissionContent,
      },
    }

    const { title, content } =
      toggleVisibilityMessages[submission.isHidden ? 'isHidden' : 'isVisible']

    modal.confirm({
      type: 'warning',
      title: formatMessage(title),
      content: formatMessage(content),
      onOk: async () => {
        await toggleSurveySubmissionVisibility({
          variables: {
            input: {
              motherId: getMotherId(),
              channelId: channel.id,
              submissionId: submission.id,
              isVisible: !submission.isHidden,
            },
          },
        })
      },
    })
  }

  const actions: Record<ItemType, () => void> = {
    viewItem: () => {
      setSelectedSubmissionId(submission.id)
    },
    deleteItem: () => {
      modal.confirm({
        type: 'warning',
        title: formatMessage(messages.deleteSubmissionTitle),
        content: formatMessage(messages.deleteSubmissionContent),
        onOk: async () => {
          await deleteSurveySubmission({
            variables: {
              input: {
                motherId: getMotherId(),
                channelId: channel.id,
                submissionId: submission.id,
              },
            },
          })

          setSelectedSubmissionId(null)

          return
        },
      })
    },
    toggleVisibilityItem: () => {
      confirmToggleVisibility(submission)
    },
  }
  return (
    <>
      <MoreOptions
        content={
          <Menu
            onClick={menuItem => {
              const action = actions[menuItem.key as ItemType]
              action()
            }}
            items={[
              {
                key: menuOptions.viewItem,
                label: <FormattedMessage {...messages.viewDetails} />,
              },
              {
                key: menuOptions.toggleVisibilityItem,
                label: submission.isHidden ? (
                  <FormattedMessage {...messages.show} />
                ) : (
                  <FormattedMessage {...messages.hide} />
                ),
              },
              {
                key: menuOptions.deleteItem,
                label: <FormattedMessage {...messages.delete} />,
                danger: true,
              },
            ]}
          />
        }
      />
      {selectedSubmission && (
        <Modal
          opened={Boolean(selectedSubmission)}
          width={620}
          onClose={() => setSelectedSubmissionId(null)}
          title={
            <FormattedMessage
              {...messages.submissionNumber}
              values={{ number: selectedSubmission.order }}
            />
          }
        >
          <Flex vertical gap='large'>
            <Text color='subtleText' variant='body' size='small'>
              <Flex gap='middle'>
                <div>{moment(selectedSubmission.submittedAt).format('LLL')}</div>
              </Flex>
            </Text>
            <Flex vertical gap='20px'>
              {formItems.map(formItem => {
                const answer = selectedSubmission.answers.find(
                  answer => answer.formItem.id === formItem.id
                )

                switch (formItem.type) {
                  case FormItemType.SimpleText:
                    return (
                      <Question
                        key={formItem.id}
                        title={formItem.labelTranslations?.[0]?.translation ?? ''}
                        type={formItem.type}
                        answer={answer?.values[0] ?? ''}
                      />
                    )
                  case FormItemType.MultiSelect:
                  case FormItemType.Select:
                    return (
                      <Question
                        key={formItem.id}
                        title={formItem.labelTranslations?.[0]?.translation}
                        type={formItem.type}
                        answers={formItem.options.map(option => ({
                          id: option.id,
                          title: option.labelTranslations?.[0]?.translation ?? '',
                          active: answer?.values.includes(option.id) ?? false,
                        }))}
                      />
                    )
                  default:
                    return null
                }
              })}
            </Flex>
            <Flex gap='large'>
              <Button
                type='text'
                onClick={() => {
                  confirmToggleVisibility(selectedSubmission)
                }}
                icon={<UntitledIcon icon={selectedSubmission.isHidden ? ulEye : ulEyeOff} />}
              >
                <FormattedMessage
                  {...(selectedSubmission.isHidden ? messages.show : messages.hide)}
                />
              </Button>
              <Button
                type='text'
                icon={<UntitledIcon icon={ulTrash01} />}
                danger
                onClick={() => {
                  modal.confirm({
                    type: 'warning',
                    title: formatMessage(messages.deleteSubmissionTitle),
                    content: formatMessage(messages.deleteSubmissionContent),
                    onOk: async () => {
                      await deleteSurveySubmission({
                        variables: {
                          input: {
                            motherId: getMotherId(),
                            channelId: channel.id,
                            submissionId: selectedSubmission.id,
                          },
                        },
                      })

                      setSelectedSubmissionId(null)

                      return
                    },
                  })
                }}
              >
                <FormattedMessage {...messages.delete} />
              </Button>
            </Flex>
          </Flex>
        </Modal>
      )}
    </>
  )
}
