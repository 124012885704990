import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { defineMessages } from '../../TypedIntl'
import { mq } from '../../constants'
import useConfigForProject from '../../hooks/useConfigForProject'

const messages = defineMessages({
  termsAndConditions: 'Administration.footer.termsAndConditions',
  privacy: 'Administration.footer.privacy',
})

const Footer = () => {
  const { privacyLink, termsLink } = useConfigForProject()

  return (
    <FooterWrapper>
      <FooterContainer>
        <div>FaceUp Technology s.r.o. &copy; {new Date().getFullYear()}</div>
        <AnchorWrapper>
          <div>
            <Anchor href={termsLink} target='_blank' rel='noopener noreferrer'>
              <FormattedMessage {...messages.termsAndConditions} />
            </Anchor>
          </div>
          <div>
            <Anchor href={privacyLink} target='_blank' rel='noopener noreferrer'>
              <FormattedMessage {...messages.privacy} />
            </Anchor>
          </div>
        </AnchorWrapper>
      </FooterContainer>
    </FooterWrapper>
  )
}

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid #e6eaec;
  flex-shrink: 0;
  position: relative;
  top: 81px;
  margin-top: -81px;
  background-color: #f6f7f8;
`

const FooterContainer = styled.footer`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  font-weight: 600;
  font-size: 14px;
  color: #688699;
  padding: 32px;
  flex-direction: column-reverse;

  ${mq.smUp} {
    flex-direction: row;
  }
`

const AnchorWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 32px;
  justify-content: center;

  ${mq.smUp} {
    justify-content: flex-end;
    flex-wrap: nowrap;
  }
`

const Anchor = styled.a`
  font-weight: 400;
  color: #688699;
`

export default Footer
