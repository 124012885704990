import { Button } from '@faceup/ui'
import { Form, Input, Modal, Typography } from '@faceup/ui-base'
import { Suspense, lazy, useState } from 'react'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'

// We want to load pdf downloader lazy
const DownloadRecoveryKeyButton = lazy(() => import('./DownloadRecoveryKeyButton'))

const { Paragraph } = Typography

const messages = defineMessages({
  title: 'Administration.login.recovery.title',
  content: 'Administration.login.recovery.content',
})

type Props = {
  recoveryKey: string
  visible: boolean
}

const Recovery = ({ recoveryKey, visible }: Props) => {
  const [didDownload, setDidDownload] = useState(false)
  const { formatMessage } = useIntl()

  return (
    <Modal
      open={visible}
      title={formatMessage(messages.title)}
      okText={formatMessage(sharedMessages.ok)}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ disabled: !didDownload }}
      confirmLoading={!recoveryKey}
      onOk={() => window.location.replace('/')}
      closable={false}
      style={{
        minWidth: '50%',
      }}
    >
      <Paragraph>
        <FormattedMessage {...messages.content} values={{ b: text => <b>{text}</b>, br: <br /> }} />
      </Paragraph>
      <Form layout='inline'>
        <Form.Item style={{ flex: 1, marginRight: 30 }}>
          <Input value={recoveryKey} disabled />
        </Form.Item>
        <Form.Item>
          <Suspense
            fallback={
              <Button loading>
                <FormattedMessage {...sharedMessages.download} />
              </Button>
            }
          >
            <DownloadRecoveryKeyButton
              recoveryKey={recoveryKey}
              onClick={() => {
                setDidDownload(true)
              }}
            >
              <FormattedMessage {...sharedMessages.download} />
            </DownloadRecoveryKeyButton>
          </Suspense>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Recovery
