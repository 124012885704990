import { UntitledIcon } from '@faceup/icons'
import { ulCheckCircle } from '@faceup/icons/ulCheckCircle'
import { FormItem } from '@faceup/ui'
import { Flex, Input, Typography, useUiBaseConfig } from '@faceup/ui-base'
import { FormItemType } from '@faceup/utils'
import type React from 'react'
import type { ReactNode } from 'react'

type SurveyItemType = {
  id: string
  title: ReactNode
  active: boolean
  large?: boolean
}

type QuestionProps = {
  title: ReactNode
  description?: ReactNode
  large?: boolean
  required?: boolean
  secondary?: ReactNode
  unanswered?: boolean
} & (
  | {
      answers: SurveyItemType[]
      answer?: never
      type: FormItemType.MultiSelect | FormItemType.Select
    }
  | {
      answers?: never
      answer: string
      type: FormItemType.SimpleText
    }
)

export const Question = ({
  title,
  description,
  answers,
  type,
  answer,
  secondary,
  large = false,
  required = false,
}: QuestionProps) => {
  const unanswered = answers?.every(answer => !answer.active) ?? true

  return (
    <FormItem
      variant='black'
      label={title}
      description={description}
      withAsterisk={required}
      secondary={secondary}
    >
      <Flex vertical gap='5px'>
        {type === FormItemType.MultiSelect || type === FormItemType.Select ? (
          answers.map(item => (
            <SurveyItem key={item.id} item={item} large={large} unanswered={unanswered} />
          ))
        ) : (
          <AnswerComponent answer={answer} />
        )}
      </Flex>
    </FormItem>
  )
}

const AnswerComponent = ({ answer }: { answer: string | undefined }) =>
  answer ? <Typography.Text>{answer}</Typography.Text> : <Input disabled />

type SurveyItemProps = {
  item: SurveyItemType
  large?: boolean
  unanswered: boolean
}

const SurveyItem = ({ item, large = false, unanswered }: SurveyItemProps) => {
  const { token } = useUiBaseConfig()

  const activeColor: React.CSSProperties = { color: '#FFFFFF' }
  const activeStyles: React.CSSProperties = {
    backgroundColor: token.colorPrimary,
    border: 'none',
    ...activeColor,
  }

  const unansweredStyles: React.CSSProperties = {
    backgroundColor: 'var(--ant-color-bg-container-disabled)',
    border: '1px solid var(--ant-color-border)',
    color: undefined,
  }

  return (
    <Flex
      style={{
        minHeight: large ? '54px' : '48px',
        borderRadius: token.borderRadiusLG,
        backgroundColor: '#FFFFFF',
        border: `1px solid ${token.colorBorderSecondary}`,
        padding: '16px',
        ...(item.active ? activeStyles : {}),
        ...(unanswered ? unansweredStyles : {}),
      }}
      align='center'
      justify='space-between'
    >
      <Typography.Text
        style={{
          ...(item.active ? activeColor : {}),
        }}
      >
        {item.title}
      </Typography.Text>
      {item.active && <UntitledIcon icon={ulCheckCircle} />}
    </Flex>
  )
}
