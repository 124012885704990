import { Select, type SelectProps } from '@faceup/ui-base'
import { Country, convertCountryToCountryName } from '@faceup/utils'
import { useIntl } from '../TypedIntl'

type CountrySelectProps = Omit<SelectProps, 'showSearch' | 'optionFilterProp'> & {
  'data-cy': string
}

// TODO: sync this `CountrySelect` component with another one `CountrySelect`
const CountrySelect = (props: CountrySelectProps) => {
  const { locale } = useIntl()

  return (
    <Select
      {...props}
      showSearch
      optionFilterProp='children'
      data-cy={`${props['data-cy']}-input`}
      dropdownRender={menu => <div data-cy={`${props['data-cy']}-menu`}>{menu}</div>}
    >
      {Object.values(Country).map(country => (
        <Select.Option key={country} value={country}>
          {convertCountryToCountryName(country, locale)}
        </Select.Option>
      ))}
    </Select>
  )
}

export default CountrySelect
