import { UntitledIcon } from '@faceup/icons'
import { ulCopy01 } from '@faceup/icons/ulCopy01'
import { Button, Flex } from '@faceup/ui-base'
import { Box, Group, TextInput, type TextInputProps } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { useContext } from 'react'
import { ButtonSmallCirclePrimary } from '../ButtonSmallCirclePrimary'
import { UiContext } from '../UiProvider/UiProvider'

export type InputCopyableProps = {
  onCopyModify?: (inputValue: string) => string
  onCopySuccess?: () => void
  onCopyError?: () => void
  copyButtonDataCy?: string
  buttonPosition?: 'insideInput' | 'afterInput'
} & Omit<TextInputProps, 'children'>

export const InputCopyable = ({
  onCopyModify = inputValue => inputValue,
  onCopySuccess,
  onCopyError,
  copyButtonDataCy,
  buttonPosition = 'insideInput',
  ...props
}: InputCopyableProps) => {
  const { inputCopyable } = useContext(UiContext)
  const { ref, width } = useElementSize()

  const onClick = async () => {
    try {
      await navigator.clipboard.writeText(onCopyModify(props?.value?.toString() ?? ''))
      inputCopyable?.onCopySuccess?.()
      onCopySuccess?.()
    } catch {
      onCopyError?.()
    }
  }
  return (
    <Flex gap='small' align='end'>
      <TextInput
        sx={{
          paddingInlineEnd: 4,
          paddingBlockEnd: buttonPosition === 'afterInput' ? 0 : undefined,
          width: '100%',
        }}
        readOnly
        {...props}
        rightSectionWidth={width ? width + 8 : undefined}
        rightSection={
          <Box>
            <Group ref={ref} w='100%' spacing='0.5rem' noWrap>
              {props.rightSection}
              {buttonPosition === 'insideInput' && (
                <ButtonSmallCirclePrimary data-cy={copyButtonDataCy} onClick={onClick}>
                  <UntitledIcon icon={ulCopy01} />
                </ButtonSmallCirclePrimary>
              )}
            </Group>
          </Box>
        }
      />
      {buttonPosition === 'afterInput' && (
        <Button onClick={onClick} type='primary' size='large' ghost data-cy={copyButtonDataCy}>
          {inputCopyable?.copy ?? 'Copy'}
        </Button>
      )}
    </Flex>
  )
}
