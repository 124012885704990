import { gql, useQuery } from '@apollo/client'
import { UpsellPageContent, useMotherId, useUpsell } from '@faceup/institution'
import { useParams } from '@faceup/router'
import { ContentLayout } from '@faceup/ui'
import { useState } from 'react'
import type {
  SurveyDetailViewQuery,
  SurveyDetailViewQueryVariables,
} from '../../__generated__/globalTypes'
import InstitutionCustomizationPagesView from '../InstitutionCustomization/ReportForm/InstitutionCustomizationPagesView'
import {
  PublishSurveyModal,
  PublishSurveyModalFragments,
  type SurveyModalModes,
} from './components'
import { SurveyContentHeader, SurveyContentHeaderFragments } from './components/SurveyContentHeader'

const query = {
  SurveyDetailViewQuery: gql`
    query SurveyDetailViewQuery(
      $reportSourceId: ReportSourceGlobalId!
      $motherId: CompanyGlobalId!
    ) {
      survey(reportSourceId: $reportSourceId, motherId: $motherId) {
        id
        ...SurveyContentHeader_reportSource
        ...PublishSurveyModalFragment
      }
    }
    ${PublishSurveyModalFragments.PublishSurveyModalFragment}
    ${SurveyContentHeaderFragments.SurveyContentHeader_reportSource}
  `,
}

const SurveyDetailView = () => {
  const { id } = useParams<{ id: string }>()

  const upsell = useUpsell()
  const { getMotherId } = useMotherId()

  const [shareModal, setShareModal] = useState<SurveyModalModes>()

  const { data } = useQuery<SurveyDetailViewQuery, SurveyDetailViewQueryVariables>(
    query.SurveyDetailViewQuery,
    {
      variables: { reportSourceId: id ?? '', motherId: getMotherId() },
    }
  )

  const survey = data?.survey

  if (!survey) {
    return null
  }

  return (
    <UpsellPageContent upsell={upsell.surveys}>
      <ContentLayout
        header={
          <SurveyContentHeader
            survey={survey}
            surveyNavigationType='submissions'
            arrowGoBackTo={routes => routes.surveys()}
          />
        }
      >
        <InstitutionCustomizationPagesView formId={survey.id} />
      </ContentLayout>
      {shareModal && (
        <PublishSurveyModal
          survey={survey}
          mode={shareModal}
          onClose={() => setShareModal(undefined)}
        />
      )}
    </UpsellPageContent>
  )
}

export default SurveyDetailView
