import { gql, useMutation } from '@apollo/client'
import type { PageTranslations } from '@faceup/ui'
import { notification } from '@faceup/ui-base'
import { ChannelPageType } from '@faceup/utils'
import { useEffect } from 'react'
import { sharedMessages } from '../../../../../Shared/translations'
import { useIntl } from '../../../../../TypedIntl'
import type {
  EditPageForm_reportSource,
  EditPageMutation,
  EditPageMutationVariables,
  EditSurveyAfterSendMutation,
  EditSurveyAfterSendMutationVariables,
  EditSurveyBeforeSendMutation,
  EditSurveyBeforeSendMutationVariables,
  Language,
} from '../../../../../__generated__/globalTypes'
import { useMotherId } from '../../../../../hooks/useMotherId'
import usePageTemplate from '../../../../../hooks/usePageTemplate'
import PageFormAbstract, { PageFormAbstractFragments } from './PageFormAbstract'
import { PagesListFragments } from './PagesList'

type EditPageForm_reportSource_pages = EditPageForm_reportSource['pages'][number]

export const EditPageFormFragments = {
  EditPageForm_reportSource: gql`
    fragment EditPageForm_reportSource on ReportSource {
      id
      isDefault
      defaultLanguage
      ...PageFormAbstract_reportSource
      pages(language: $language) {
        ... on ChannelPage {
          id
          title
          content
          icon
          language
          order
          type
        }
        ... on Page {
          id
          title
          content
          icon
          language
          order
        }
      }
    }
    ${PageFormAbstractFragments.PageFormAbstract_reportSource}
  `,
}

const mutations = {
  EditPageMutation: gql`
    mutation EditPageMutation($input: EditPageInput!, $language: Language!) {
      editPage(input: $input) {
        reportSource {
          id
          ...PagesList_reportSource
        }
      }
    }
    ${PagesListFragments.PagesList_reportSource}
  `,
  SurveyBeforeSendMutation: gql`
    mutation EditSurveyBeforeSendMutation(
      $input: EditSurveyBeforeSendInput!
      $language: Language!
    ) {
      editSurveyBeforeSend(input: $input) {
        survey {
          id
          ...EditPageForm_reportSource
        }
      }
    }
    ${EditPageFormFragments.EditPageForm_reportSource}
  `,
  SurveyAfterSendMutation: gql`
    mutation EditSurveyAfterSendMutation($input: EditSurveyAfterSendInput!, $language: Language!) {
      editSurveyAfterSend(input: $input) {
        survey {
          id
          ...EditPageForm_reportSource
        }
      }
    }
    ${EditPageFormFragments.EditPageForm_reportSource}
  `,
}

type EditPageFormProps = {
  reportSource: EditPageForm_reportSource
  language: Language
  page: EditPageForm_reportSource_pages
}

const EditPageForm = ({ reportSource, page: pageUnfilled, language }: EditPageFormProps) => {
  const { fillObject, isTitleDefault, isContentDefault, setIsDefaultForm } =
    usePageTemplate(language)

  const getPage = (): PageTranslations<EditPageForm_reportSource_pages> => {
    if (pageUnfilled.__typename === 'ChannelPage') {
      return fillObject({
        ...pageUnfilled,
        type: pageUnfilled.type as ChannelPageType,
      })
    }
    return fillObject(pageUnfilled)
  }

  const page = getPage()

  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()

  useEffect(() => {
    setIsDefaultForm(reportSource.isDefault)
  }, [reportSource.isDefault, setIsDefaultForm])

  const [updatePage] = useMutation<EditPageMutation, EditPageMutationVariables>(
    mutations.EditPageMutation,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
    }
  )

  const [editSurveyBeforeSend] = useMutation<
    EditSurveyBeforeSendMutation,
    EditSurveyBeforeSendMutationVariables
  >(mutations.SurveyBeforeSendMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const [editSurveyAfterSend] = useMutation<
    EditSurveyAfterSendMutation,
    EditSurveyAfterSendMutationVariables
  >(mutations.SurveyAfterSendMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  if (page.__typename === 'Page') {
    return (
      <PageFormAbstract
        buttonVariant='update'
        type='reportForm'
        onSubmit={async ({ title, content, icon }) => {
          const result = await updatePage({
            variables: {
              language: page.language,
              input: {
                reportSourceId: reportSource.id,
                pageId: page.id,
                title: isTitleDefault(title.trim()) ? null : title.trim(),
                content: isContentDefault(content.trim()) ? null : content.trim(),
                icon,
                language: page.language,
                motherId: getMotherId(),
              },
            },
          })
          return !result.errors
        }}
        values={page}
      />
    )
  }

  switch (page.type) {
    case ChannelPageType.BeforeSend:
      return (
        <PageFormAbstract
          buttonVariant='update'
          type={ChannelPageType.BeforeSend}
          onSubmit={async values => {
            const result = await editSurveyBeforeSend({
              variables: {
                input: {
                  motherId: getMotherId(),
                  reportSourceId: reportSource.id,
                  title: values.title ?? '',
                  description: values.content || null,
                },
                language: reportSource.defaultLanguage,
              },
            })
            return !result.errors
          }}
          values={page}
          channel={reportSource}
        />
      )
    case ChannelPageType.FormItems:
      return (
        <PageFormAbstract
          buttonVariant='update'
          type={ChannelPageType.FormItems}
          channel={reportSource}
        />
      )
    case ChannelPageType.AfterSend:
      return (
        <PageFormAbstract
          buttonVariant='update'
          type={ChannelPageType.AfterSend}
          onSubmit={async values => {
            const result = await editSurveyAfterSend({
              variables: {
                input: {
                  motherId: getMotherId(),
                  reportSourceId: reportSource.id,
                  title: values.title ?? '',
                  description: values.content || null,
                },
                language: reportSource.defaultLanguage,
              },
            })
            return !result.errors
          }}
          values={page}
          channel={reportSource}
        />
      )
  }

  return null
}

export default EditPageForm
