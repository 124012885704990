import moment from 'moment-timezone'
import { useCallback } from 'react'
import { useIntl } from '../TypedIntl'
import type { ExportSectionSchema } from './useExportSchema'

const extension = 'csv'

type CsvName = `${string}.${typeof extension}`

/**
 * Hook to generate CSV file.
 */
const useCsv = () => {
  const { formatMessage } = useIntl()
  const formatDate = (date: string) => (date ? moment(date).format('l') : '')

  // biome-ignore lint/correctness/useExhaustiveDependencies(formatDate):
  return useCallback(
    <N extends string, E extends object>(
      fileSchema: ExportSectionSchema<N, E>,
      entities: E[],
      csvName: CsvName
    ): File => {
      const filteredColumns = fileSchema.columns.filter(
        column => !column.shouldInclude || column.shouldInclude(entities)
      )
      const header = filteredColumns
        .map(column => (typeof column.name === 'object' ? formatMessage(column.name) : column.name))
        .map(name => `"${name}"`)

      const rows = entities.map(entity =>
        filteredColumns.map(column => {
          switch (column.type) {
            case 'string': {
              const value = column.getValue(entity) ?? ''
              return `"${value}"`
            }
            case 'date':
              return `"${formatDate(column.getValue(entity))}"`
            case 'message':
              return `"${formatMessage(column.getValue(entity))}"`
            default:
              throw new Error(`Unsupported column type in csv export`)
          }
        })
      )

      const data = [header, ...rows].map(row => row.join(',')).join('\n')

      const csvFile = new Blob([data], {
        type: 'text/csv;charset=utf-8',
      })

      return new File([csvFile], `${csvName}`)
    },
    [formatMessage]
  )
}

export default useCsv
