import type { UntitledIconData } from '@faceup/icons'

export const ucFormItemPhoneNumber: UntitledIconData = {
  prefix: 'custom',
  name: 'ucFormItemPhoneNumber',
  viewBox: '0 0 20 20',
  paths: [
    {
      shape:
        'M7.92956 8.38539C8.30365 9.16452 8.8136 9.89476 9.45942 10.5406C10.1052 11.1864 10.8355 11.6964 11.6146 12.0704C11.6816 12.1026 11.7151 12.1187 11.7575 12.1311C11.9082 12.175 12.0933 12.1434 12.2209 12.0521C12.2568 12.0263 12.2875 11.9956 12.3489 11.9342C12.5368 11.7463 12.6308 11.6523 12.7253 11.5909C13.0816 11.3593 13.5409 11.3593 13.8972 11.5909C13.9916 11.6523 14.0856 11.7463 14.2735 11.9342L14.3782 12.0389C14.6639 12.3246 14.8067 12.4674 14.8843 12.6208C15.0386 12.9258 15.0386 13.2861 14.8843 13.5911C14.8067 13.7445 14.6639 13.8873 14.3782 14.173L14.2935 14.2577C14.0089 14.5423 13.8665 14.6847 13.673 14.7934C13.4583 14.914 13.1248 15.0007 12.8785 15C12.6566 14.9993 12.5049 14.9563 12.2015 14.8702C10.5711 14.4074 9.03266 13.5343 7.74918 12.2508C6.46569 10.9673 5.59258 9.42889 5.12983 7.79851C5.04372 7.49513 5.00066 7.34344 5 7.12149C4.99927 6.87521 5.086 6.54171 5.20662 6.32698C5.31532 6.13347 5.45765 5.99114 5.74231 5.70648L5.82704 5.62176C6.11268 5.33612 6.2555 5.1933 6.40888 5.11572C6.71393 4.96143 7.07419 4.96143 7.37924 5.11572C7.53262 5.1933 7.67544 5.33612 7.96108 5.62176L8.06582 5.7265C8.25372 5.9144 8.34767 6.00835 8.4091 6.10283C8.64075 6.45912 8.64075 6.91844 8.4091 7.27472C8.34767 7.3692 8.25372 7.46315 8.06582 7.65106C8.00438 7.7125 7.97366 7.74322 7.94794 7.77913C7.85657 7.90674 7.82501 8.09177 7.86894 8.24246C7.8813 8.28486 7.89739 8.31837 7.92956 8.38539Z',
      stroke: '#8296A2',
      strokeWidth: '1.4',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  rects: [
    {
      x: '0.785',
      y: '0.785',
      width: '18.43',
      height: '18.43',
      rx: '7.215',
      stroke: '#CED6DB',
      strokeWidth: '1.57',
    },
  ],
}
