import { type ExcelSchema, type XlsxFileName, xlxsFiles } from '@faceup/utils'
import moment from 'moment-timezone'
import { useCallback } from 'react'
import { useIntl } from '../TypedIntl'
import type { ExportColumnSchema, ExportReportSchema, ExportSurveySchema } from './useExportSchema'

const formatDate = (date: string) => (date ? moment(date).format('l') : '')

/**
 * Hook for creating XLSX files. XLSX format supports creating more sheets. Thus, you can put more related data
 * into one file.
 */
const useXlsx = () => {
  const { formatMessage } = useIntl()

  return useCallback(
    async <S extends ExportSurveySchema | ExportReportSchema, E>(
      fileSchema: S,
      entities: E[][],
      xlsxName: XlsxFileName
    ): Promise<File> => {
      /*
       * Our custom config fits our needs perfectly,
       * however in order to let the xlsx library work,
       * we need to transform the schema format to theirs.
       */
      const transformedFileSchema: ExcelSchema<E>[] = fileSchema.sections.map(
        // biome-ignore lint/suspicious/noExplicitAny:
        (section: any, index): ExcelSchema<E> =>
          section.columns
            .filter(
              (column: ExportColumnSchema<unknown>) =>
                // biome-ignore lint/style/noNonNullAssertion:
                !column.shouldInclude || column.shouldInclude(entities[index]!)
            )
            .map((column: ExportColumnSchema<unknown>) => ({
              column: typeof column.name === 'object' ? formatMessage(column.name) : column.name,
              type: String,
              value: (entity: E) => {
                switch (column.type) {
                  case 'string':
                    return column.getValue(entity)
                  case 'message':
                    return formatMessage(column.getValue(entity))
                  case 'date':
                    return formatDate(column.getValue(entity))
                  default:
                    throw new Error('Unsupported type in xlsx export')
                }
              },
            }))
      )

      const xlsxFile = xlxsFiles<E>(
        entities,
        {
          // The schema parameter should be an array of schemas for each sheet.
          schema: transformedFileSchema,
          sheets: fileSchema.sections.map(({ title }) => formatMessage(title)),
          // if not specified blob is returned
          fileName: undefined as unknown as string,
          // style
          headerStyle: {
            backgroundColor: '#e0e0e0',
            fontWeight: 'bold',
            align: 'center',
          },
          fontSize: 12,
        },
        xlsxName
      )

      return xlsxFile
    },
    [formatMessage]
  )
}

export default useXlsx
