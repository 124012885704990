import { TokenType } from '@faceup/utils'
import { getCurrentEncryptionVersion } from '..'
import { savePersonalKeys } from '../keys'
import { changePassword } from '../password'
import { createRecoveryKey, createSystemRecoveryKey } from '../recovery'

export type ProcessCreatePasswordPayload = {
  password: string
  name: string
  recoveryKey: string | undefined
  isE2EE: boolean
  systemPublicKey: string
}

export const processCreatePassword = async ({
  password,
  name,
  recoveryKey,
  isE2EE,
  systemPublicKey,
}: ProcessCreatePasswordPayload) => {
  const passwordPayload = await changePassword(password ?? '', 'generate')

  if (typeof passwordPayload === 'string') {
    return passwordPayload
  }

  const userRecoveryPayload = await createRecoveryKey(
    passwordPayload.privateKeyEncrypted,
    passwordPayload.nonce,
    passwordPayload.passwordKey,
    recoveryKey ?? undefined
  )

  if (typeof userRecoveryPayload === 'string') {
    return userRecoveryPayload
  }

  const systemRecoveryPayload = await createSystemRecoveryKey(
    userRecoveryPayload.recoveryKeyPlain,
    systemPublicKey
  )

  if (typeof systemRecoveryPayload === 'string') {
    return systemRecoveryPayload
  }

  const savePayload = await savePersonalKeys({
    publicKey: passwordPayload.publicKey,
    privateKey: passwordPayload.privateKeyEncrypted,
    nonce: passwordPayload.nonce,
    passwordKey: passwordPayload.passwordKey,
    rememberMe: false,
    version: getCurrentEncryptionVersion(),
  })

  if (typeof savePayload === 'string') {
    return savePayload
  }

  const mutationInput = {
    tokenType: TokenType.CreatePassword as const,
    name,
    passwordPrehash: passwordPayload.password,
    nonce: passwordPayload.nonce,
    salt: passwordPayload.salt,
    privateKeyEncrypted: passwordPayload.privateKeyEncrypted,
    publicKey: passwordPayload.publicKey,
    privateKeyRecovery: userRecoveryPayload.privateKeyRecovery,
    privateKeyRecoveryNonce: userRecoveryPayload.privateKeyRecoveryNonce,
    recoveryKeyEncrypted: userRecoveryPayload.recoveryKeyEncrypted,
    recoveryKeyEncryptedNonce: userRecoveryPayload.recoveryKeyEncryptedNonce,

    ...(!isE2EE && { recoveryKeySystemEncrypted: systemRecoveryPayload.systemRecoveryKey }),
  }

  return [mutationInput, isE2EE ? userRecoveryPayload.recoveryKeyPlain : null] as const
}
