import { createContext } from 'react'

type State = {
  isInstitutionVerificationVisible: boolean
  setIsInstitutionVerificationVisible: (isInstitutionVerificationVisible: boolean) => void
  isChatVisible: boolean
  setIsChatVisible: (isChatVisible: boolean) => void
  isChatLoading: boolean
  setIsChatLoading: (isChatLoading: boolean) => void
  chatUnreadMessages: number
  setChatUnreadMessages: (chatUnreadMessages: number) => void
}

export const ModalsContext = createContext<State>({
  isInstitutionVerificationVisible: false,
  setIsInstitutionVerificationVisible: () => undefined,
  isChatVisible: false,
  setIsChatVisible: () => undefined,
  isChatLoading: true,
  setIsChatLoading: () => undefined,
  chatUnreadMessages: 0,
  setChatUnreadMessages: () => undefined,
})
