import { RecaptchaType } from '@faceup/utils'
import { useState } from 'react'
import useScript from './useScript'

export const useRecaptcha = () => {
  const [recaptcha, setRecaptcha] = useState<{
    value: string
    error: boolean
    type: RecaptchaType
    id: number | null
  }>({
    value: '',
    error: false,
    type: RecaptchaType.Invisible,
    id: null,
  })

  useScript(
    // `recaptcha.net` is same as `google.com`; moved to left because of the chat
    `https://www.recaptcha.net/recaptcha/enterprise.js?badge=bottomleft&render=${
      import.meta.env.VITE_GOOGLE_INVISIBLE_RECAPTCHA_KEY
    }`,
    'invisible-recaptcha'
  )
  useScript('https://www.recaptcha.net/recaptcha/api.js?render=explicit', 'checkbox-recaptcha')

  const resetRecaptcha = () => {
    window?.grecaptcha?.reset?.()
  }

  return {
    recaptcha,
    resetRecaptcha: () => {
      if (typeof recaptcha?.id === 'number') {
        resetRecaptcha()
      }
    },
    handleTooLowRecaptchaScore: () => {
      let recaptchaId = null
      if (typeof recaptcha?.id === 'number') {
        resetRecaptcha()
      } else {
        recaptchaId = window?.grecaptcha?.render?.('recaptcha-checkbox', {
          sitekey: import.meta.env.VITE_GOOGLE_CHECKBOX_RECAPTCHA_KEY ?? '',
          callback: (token: string) =>
            setRecaptcha(prevState => ({ ...prevState, value: token, error: false })),
        })
      }

      setRecaptcha({
        id: recaptcha?.id ?? recaptchaId,
        value: '',
        error: false,
        type: RecaptchaType.Checkbox,
      })
    },
    isRecaptchaInvalid: () => {
      if (!recaptcha.value && recaptcha.type === RecaptchaType.Checkbox) {
        setRecaptcha(prevState => ({ ...prevState, value: '', error: true }))
        return true
      }

      return false
    },
    getRecaptchaToken: async () =>
      import.meta.env.VITE_ENVIRONMENT === 'development' ||
      import.meta.env.VITE_ENVIRONMENT === 'preview'
        ? ''
        : recaptcha.type === RecaptchaType.Checkbox
          ? recaptcha.value
          : await window?.grecaptcha?.enterprise?.execute(
              import.meta.env.VITE_GOOGLE_INVISIBLE_RECAPTCHA_KEY ?? '',
              { action: 'verifyTrialEmail' }
            ),
  }
}
