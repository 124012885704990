import {
  type DropFirst,
  generateShortIdUrl,
  generateSurveyUrl,
  getAdministrationDomain,
  getReportingAppDomain,
} from '@faceup/utils'
import { useContext, useMemo } from 'react'
import { ThemeContext } from '../ThemeContext'
import { ThemeType } from '../locales'

type KnowledgeBaseLink = 'redaction' | 'api' | 'webhooks'

type Links = Record<'supportEmail' | 'privacyLink' | 'termsLink', string> & {
  knowledgeBaseLink: Record<KnowledgeBaseLink, string>
}

const links: Record<ThemeType, Links> = {
  [ThemeType.FaceUp]: {
    supportEmail: 'support@faceup.com',
    privacyLink: 'https://www.faceup.com/en/legal/privacy-policy',
    termsLink: 'https://www.faceup.com/en/legal/terms-of-service ',
    knowledgeBaseLink: {
      redaction: 'https://support.faceup.com/en/redacting-reports',
      api: 'https://support.faceup.com/en/api',
      webhooks: 'https://support.faceup.com/en/webhooks',
    },
  },
  [ThemeType.NNTB]: {
    supportEmail: 'podpora@nntb.cz',
    privacyLink: 'https://www.faceup.com/en/legal/privacy-policy',
    termsLink: 'https://www.faceup.com/en/legal/terms-of-service ',
    knowledgeBaseLink: {
      redaction: 'https://support.faceup.com/cs/redigovani-oznameni',
      api: 'https://support.faceup.com/cs/api',
      webhooks: 'https://support.faceup.com/cs/webhooky',
    },
  },
}

type UseConfigForProject = () => {
  administrationDomain: string
  reportingAppDomain: string
  generateShortIdUrl: (
    ...params: DropFirst<Parameters<typeof generateShortIdUrl>>
  ) => ReturnType<typeof generateShortIdUrl>
  generateSurveyUrl: (
    ...params: DropFirst<Parameters<typeof generateSurveyUrl>>
  ) => ReturnType<typeof generateSurveyUrl>
} & Links

const useConfigForProject: UseConfigForProject = () => {
  const { project } = useContext(ThemeContext)

  const administrationDomain = useMemo(
    () => getAdministrationDomain(import.meta.env.VITE_ENVIRONMENT ?? ''),
    []
  )
  const reportingAppDomain = useMemo(
    () => getReportingAppDomain(import.meta.env.VITE_ENVIRONMENT ?? ''),
    []
  )

  return {
    ...links[project],
    administrationDomain,
    reportingAppDomain,
    generateShortIdUrl: (...params) =>
      generateShortIdUrl(import.meta.env.VITE_ENVIRONMENT ?? '', ...params),
    generateSurveyUrl: (...params) =>
      generateSurveyUrl(import.meta.env.VITE_ENVIRONMENT ?? '', ...params),
  }
}

export default useConfigForProject
