import { type ClosingReason, Institution, type ReportJustification } from '@faceup/utils'
import {
  reportJustificationMessages,
  reportPrioritiesMessages,
  reportSourceMessages,
  reportStatusMessages,
  sharedMessages,
} from '../Shared/translations'
import { type TypedMessageDescriptor, defineMessages } from '../TypedIntl'
import type {
  ReportPriority,
  ReportSourceType,
  ReportStatusType,
} from '../__generated__/globalTypes'
import { useGlobalInfo } from '../locales'

const messages = defineMessages({
  created: 'Shared.report.created',
  category: 'Shared.report.category',
  victim: 'Shared.report.victimName',
  classRoom: 'Shared.report.classRoom',
  status: 'Administration.report.status',
  assignedMembers: 'Administration.report.assignedMembers',
  moreInfo: 'Shared.report.moreInfo',
  source: 'Administration.report.source',
  sender: 'Shared.report.senderName',
  content: 'Administration.export.content',
  reported: 'Shared.report.created',
  date: 'Administration.export.date',
  reportDetail: 'Administration.report.detailHeader',
  submittedAt: 'Administration.surveys.submissionsDetail.submittedAt',
  submittedNumber: 'Administration.surveys.submissionsDetail.submittedNumber',
  survey: 'Administration.report.source.Survey',
  comments: 'Administration.internalComments.ReportActivitiesWrapper.messages',
  messages: 'Shared.chat.title',
  auditLog: 'Administration.auditlog.title',
  action: 'Administration.export.action',
  administrator: 'Administration.export.administrator',
  reportList: 'Administration.export.reportList',
  priority: 'Administration.report.priority',
  labels: 'Administration.report.labels',
  deadline: 'Administration.report.deadline',
  priorityNone: 'Administration.priority.none',
  attachments: 'Shared.report.attachments',
  justification: 'Administration.report.justification',
  reportSource: 'Administration.report.reportSource',
  transcriptionTitle: 'Administration.report.transcription.label',
  closingReason: 'Administration.editReportStatus.closingCode',
  closingComment: 'Administration.editReportStatus.closingComment',
})

/**
 * represents the data to be used when exporting report
 */
export type ReportForExport = {
  // every report has at least one detail...
  details: [Detail, ...Detail[]]
  // ...but can have 0 or multiple of these
  messages: Message[]
  comments: InternalComment[]
  activityLog: ActivityLogItem[]
  attachments: File[]
  answers: Answer[]
}

export type SurveyForExport = {
  detail: Submission[]
}

type ReportDetailSection = ExportSectionSchema<keyof ReportForExport, Detail>
type CommentsSection = ExportSectionSchema<keyof ReportForExport, InternalComment>
type MessagesSection = ExportSectionSchema<keyof ReportForExport, Message>
type ActivityLogSection = ExportSectionSchema<keyof ReportForExport, ActivityLogItem>

type SurveyDetailSection = ExportSectionSchema<keyof SurveyForExport, Submission>

/**
 * Represents the structure of a file to be exported - whole XLSX file or a bunch of CSV files.
 */
export type ExportSchema = {
  sections:
    | [ReportDetailSection, CommentsSection, MessagesSection, ActivityLogSection]
    | [ReportDetailSection]
    | [SurveyDetailSection]
}

export type ExportReportSchema = {
  sections:
    | [ReportDetailSection, CommentsSection, MessagesSection, ActivityLogSection]
    | [ReportDetailSection]
}

export type ExportSurveySchema = {
  sections: [SurveyDetailSection]
}

// section: either XLSX sheet or particular CSV file
export type ExportSectionSchema<Name extends string, Entity extends object> = {
  name: Name
  title: TypedMessageDescriptor
  columns: ExportColumnSchema<Entity>[]
}

export type ExportColumnSchema<E> = {
  name: string | TypedMessageDescriptor
  shouldInclude?: (context: E[]) => boolean
} & (
  | {
      type: 'string'
      getValue: (entity: E) => string
    }
  | {
      type: 'date'
      getValue: (entity: E) => string
    }
  | {
      type: 'message'
      getValue: (entity: E) => TypedMessageDescriptor
    }
)

export type Detail = {
  tag: string
  createdAt: string
  categoryName: string
  moreInfo: string
  transcript: string
  assignedMembersNames: string | null
  status: ReportStatusType
  closingReason: ClosingReason | null
  closingComment: string | null
  senderName: string
  victimName: string
  companyName: string
  source: ReportSourceType
  priority: ReportPriority | null
  justification: ReportJustification
  labels: string[]
  deadline: string | null
  reportSource: string
  formItems: Record<string, string>
  classRoom: string | null
}

export type Submission = {
  id: number
  submittedAt: string
  formItems: Record<string, string>
}

export type InternalComment = {
  createdAt: string
  text: string
  senderName: string
  attachments: File[]
}

export type Message = {
  createdAt: string
  text: string
  senderName: string
  attachments: File[]
}

export type ActivityLogItem = {
  userName: string
  action: string
  createdAt: string
}

export type Answer = {
  label: string
  value: string
}

export const useSurveyExportSchema = () => {
  const surveyDetailSection: SurveyDetailSection = {
    name: 'detail',
    title: messages.survey,
    columns: [
      {
        name: messages.submittedNumber,
        type: 'string',
        getValue: survey => String(survey.id),
      },
      {
        name: messages.submittedAt,
        type: 'date',
        getValue: survey => survey.submittedAt,
      },
    ],
  }

  return {
    surveyDetailSection,
  }
}

export const useReportExportSchema = () => {
  const { institution } = useGlobalInfo()

  const reportDetailSection: ReportDetailSection = {
    name: 'details',
    title: messages.reportDetail,
    columns: [
      {
        name: 'ID',
        type: 'string',
        getValue: report => report.tag,
      },
      {
        name: messages.reported,
        type: 'date',
        getValue: report => report.createdAt,
      },
      {
        name: messages.category,
        type: 'string',
        getValue: report => report.categoryName,
      },
      ...(institution === Institution.School
        ? ([
            {
              name: messages.classRoom,
              type: 'string',
              getValue: report => report.classRoom,
            },
          ] as ExportColumnSchema<Detail>[])
        : []),
      {
        name: messages.moreInfo,
        type: 'string',
        getValue: report => report.moreInfo,
      },
      {
        name: messages.transcriptionTitle,
        type: 'string',
        getValue: report => report.transcript,
      },
      {
        name: messages.assignedMembers,
        type: 'string',
        getValue: report => report.assignedMembersNames ?? '',
      },
      {
        name: messages.status,
        type: 'message',
        getValue: report => reportStatusMessages[report.status],
      },
      {
        name: messages.closingReason,
        type: 'string',
        getValue: report => report.closingReason ?? '',
      },
      {
        name: messages.closingComment,
        type: 'string',
        getValue: report => report.closingComment ?? '',
      },
      // for companies there is always sender
      {
        name: messages.sender,
        type: 'string',
        getValue: report => report.senderName,
      },
      // for schools we add victim since they may have both form for pupils and/or employees
      ...(institution === Institution.School
        ? ([
            {
              name: messages.victim,
              type: 'string',
              getValue: report => report.victimName,
            },
          ] as ExportColumnSchema<Detail>[])
        : []),
      {
        name: messages.priority,
        type: 'message',
        getValue: report =>
          report.priority ? reportPrioritiesMessages[report.priority] : messages.priorityNone,
      },
      {
        name: messages.labels,
        type: 'string',
        getValue: report => report.labels.join(', '),
      },
      {
        name: messages.deadline,
        type: 'date',
        getValue: report => report.deadline ?? '',
      },
      {
        name: messages.justification,
        type: 'message',
        getValue: report => reportJustificationMessages[report.justification],
      },
      {
        name: messages.source,
        type: 'message',
        getValue: report => reportSourceMessages[report.source],
      },
      {
        name: sharedMessages.organizationLabel,
        type: 'string',
        getValue: report => report.companyName,
        shouldInclude: reports => new Set(reports?.map(report => report.companyName)).size > 1,
      },
      {
        name: messages.reportSource,
        type: 'string',
        getValue: report => report.reportSource,
      },
    ],
  }

  const commentsSection: CommentsSection = {
    name: 'comments',
    title: messages.comments,
    columns: [
      {
        name: messages.sender,
        type: 'string',
        getValue: comment => comment.senderName,
      },
      {
        name: messages.content,
        type: 'string',
        getValue: comment => comment.text,
      },
      {
        name: messages.date,
        type: 'date',
        getValue: comment => comment.createdAt,
      },
    ],
  }

  const messagesSection: MessagesSection = {
    name: 'messages',
    title: messages.messages,
    columns: [
      {
        name: messages.sender,
        type: 'string',
        getValue: comment => comment.senderName,
      },
      {
        name: messages.content,
        type: 'string',
        getValue: comment => comment.text,
      },
      {
        name: messages.date,
        type: 'date',
        getValue: comment => comment.createdAt,
      },
      {
        name: messages.attachments,
        type: 'string',
        getValue: comment => comment.attachments.map(attachment => attachment.name).join(', '),
      },
    ],
  }

  const activityLogSection: ActivityLogSection = {
    name: 'activityLog',
    title: messages.auditLog,
    columns: [
      {
        name: messages.administrator,
        type: 'string',
        getValue: message => message.userName,
      },
      {
        name: messages.action,
        type: 'string',
        getValue: message => message.action,
      },
      {
        name: messages.date,
        type: 'date',
        getValue: message => message.createdAt,
      },
    ],
  }

  const reportListSection: ExportSectionSchema<keyof ReportForExport, Detail> = {
    ...reportDetailSection,
    title: messages.reportList,
  }

  const reportDetail: ExportReportSchema = {
    sections: [reportDetailSection, messagesSection, commentsSection, activityLogSection],
  }

  const reportListSchema: ExportReportSchema = {
    sections: [reportListSection],
  }

  return {
    reportDetailSection,
    commentsSection,
    messagesSection,
    activityLogSection,
    reportListSection,
    reportDetail,
    reportListSchema,
    mergeSchemaWithFormItemsAnswers,
  }
}

/**
 * Creates new schema based on static export schema and form items answers.
 *
 * We generate files based on schema. If we want to new columns to exported file they need to be in the schema. Since form items
 * answers are dynamic we can't add them to the static schema but we need to derive the schema from them.
 */
const mergeSchemaWithFormItemsAnswers = (
  exportSchema: ExportReportSchema,
  answers: Answer[]
): ExportReportSchema => {
  const formItemsAnswersColumns = [
    ...new Set(answers.filter(answerWithValue).map(answer => answer.label)),
  ].map<ExportColumnSchema<Detail>>(label => ({
    name: label,
    type: 'string',
    getValue: report => report.formItems[label] ?? '',
  }))

  const [detailSection, ...otherSections] = exportSchema.sections
  return {
    ...exportSchema,
    sections: [
      {
        ...detailSection,
        columns: [...detailSection.columns, ...formItemsAnswersColumns],
      },
      ...otherSections,
    ],
  }
}

const answerWithValue = (answer: Answer): boolean => answer.value.length > 0
