import { UntitledIcon } from '@faceup/icons'
import { ulSearchSm } from '@faceup/icons/ulSearchSm'
import { useThemeColors } from '@faceup/ui'
import type { FieldValues } from 'react-hook-form'
import { TextInput, type TextInputProps } from './TextInput'

export const SearchInput = <T extends FieldValues>(props: TextInputProps<T>) => {
  const { getColorFromTheme } = useThemeColors()
  return (
    <TextInput
      {...props}
      icon={<UntitledIcon icon={ulSearchSm} color={getColorFromTheme('subtleText')} />}
    />
  )
}
